<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR INFORMASI SERIAL AKTIVASI
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama serial aktivasi"
          class="font-weight-normal mr-3"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <div class="d-flex justify-space-between">
          <v-btn
            @click="actions(null, 'add')"
            elevation="0"
            class="text-capitalize white--text"
            color="#D31145">
            <v-icon left>mdi-plus</v-icon>
            Tambah Serial
          </v-btn>
          

          <div class="d-flex">
            <Export
              class="mr-2"
              :disabled="process.run"
              :file_type="'xls'"
              :btnText="'Export Excel'"
              :btnIcon="'mdi-microsoft-excel'"
              :btnColor="'teal'"
              :url="'internal/dashboard/serial-activation/export-excel?show_price_bool=true&show_status_bool=true'"
              :title="'Data Serial Aktivasi'"
              ref="base-export-excel"
            />
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-3"
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="dialog.filtered = true">
                  <v-icon size="25">mdi-filter-outline</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip> -->
          </div>
        </div>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.name`]="{item}">
              <v-list-item class="pl-0">

                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.type`]="{item}">

              <v-chip 
                v-if="item.type == 'Lite'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="green">
                SODAPOS LITE
              </v-chip>
              <v-chip
                v-else-if="item.type == 'Pro'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="red">
                SODAPOS PRO
              </v-chip>
              <v-chip
                v-else-if="item.type == 'Personal'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="teal">
                SODAKU
              </v-chip>
              <v-chip 
                v-else-if="item.type == 'Semua'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="teal darken-2">
                SODAPOS PRO, LITE & SODAKU
              </v-chip>
              <v-chip 
                v-else
                outlined
                label
                class="font-weight-bold mt-2"
                color="teal darken-2">
                {{ item.type }}
              </v-chip>
            </template>

            <template v-slot:[`item.subscribe_value`]="{item}">
              {{ item.subscribe_value }} {{ item.subscribe_type }}
            </template>
            
            <template v-slot:[`item.price`]="{item}">
              {{ item.price | price }}
            </template>

            <template v-slot:[`item.total_code`]="{item}">
              <span class="subtitle-2 font-weight-normal black--text mt-3">{{ item.total_code | num_format }} Kode</span>
              <v-btn 
                :to="`/serial-activation/detail-serial/${item.id}`" 
                text
                small
                color="#0D47A1"
                class="subtitle-2 font-weight-normal text-capitalize">
                Lihat
              </v-btn>
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                    @click="actions(item, 'delete')"
                    icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>
                  Hapus Promo
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="teal"

                    :loading="$store.state.loading"
                    @click="actions(item, 'export')"
                    icon>
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>
                  Export Excel by {{ item.name }}
                </span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ?  "Serial Aktivasi Baru" : "Ubah Serial Aktivasi" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-serial'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan Serial Aktivasi" : "Ubah Serial Aktivasi" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-serial">
            <v-form @:keyup.enter="save(dialog.form_type)">

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-name"
                    name="Nama Serial"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Nama Serial
                      <small style="color: #e74c3c"><i>*wajib</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan nama serial"
                      v-model="form.name"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-sales_id"
                    name="Pilih Sales"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Pilih Sales
                      <small style="color: #e74c3c"><i>*wajib</i></small>
                    </div>
                    <v-select
                      solo
                      flat
                      dense
                      color="#d31145"
                      v-model="form.sales_id"
                      background-color="#EEEEEE"
                      placeholder="Pilih sales"
                      :items="sales"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-subscribe_value"
                    name="Lama Langganan Berlangsung"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Lama Langganan Berlangsung
                      <small style="color: #e74c3c"><i>*wajib</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan Lama Langganan Berlangsung, Ex: 3,5,7"
                      v-model="form.subscribe_value"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-subscribe_type"
                    name="Pilih Tipe Lama Langganan"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Pilih Tipe Lama Langganan
                      <small style="color: #e74c3c"><i>*wajib</i></small>
                    </div>
                    <v-select
                      solo
                      flat
                      dense
                      color="#d31145"
                      v-model="form.subscribe_type"
                      background-color="#EEEEEE"
                      placeholder="Pilih Tipe Lama Langganan"
                      :items="[{label: 'Tahunan', key: 'Year'}, {label: 'Bulanan', key: 'Month'}, {label: 'Hari', key: 'Day'}]"
                      item-text="label"
                      item-value="key"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <ValidationProvider
                ref="form-type"
                name="Pilih Paket Langganan"
                rules="required|max:50"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Pilih Paket Langganan
                  <small style="color: #e74c3c"><i>*wajib</i></small>
                </div>
                <v-select
                  solo
                  flat
                  dense
                  color="#d31145"
                  v-model="form.type"
                  background-color="#EEEEEE"
                  placeholder="Pilih paket langganan"
                  :items="[{label: 'SODAPOS LITE', key:'Lite'}, {label: 'SODAPOS PRO', key: 'Pro'}, {label: 'SODAKU', key: 'Personal'}]"
                  item-text="label"
                  item-value="key"
                  :error-messages="errors"
                  required>
                </v-select>
              </ValidationProvider>

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-price"
                    name="price"
                    rules="min_0:0"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Harga Diskon Serial
                    </div>
                    <v-currency-field
                      id="price"
                      solo
                      flat
                      dense
                      background-color="#EEEEEE"
                      placeholder="Tuliskan harga Diskon Serial"
                      type="number"
                      color="#d31145"
                      :error-messages="errors"
                      v-model="form.price"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-qty"
                    name="qty"
                    rules="min_0:0"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Jumlah Nomor Serial
                    </div>
                    <v-currency-field
                      id="price"
                      solo
                      flat
                      dense
                      background-color="#EEEEEE"
                      placeholder="Tuliskan Jumlah Nomor Serial"
                      type="number"
                      color="#d31145"
                      :error-messages="errors"
                      v-model="form.qty"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="550px">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #d31145;"
        >
          <span class="white--text">
            Filter
          </span>
        </v-card-title>
        <v-card-text class="py-3" style="height: 550px;">
           <v-select
            dense
            outlined
            v-model="filter.package_type"
            color="#d31145"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan tipe paket"
            :items="[{text: 'SODAPOS PRO & LITE', value: 'Semua'}, {text: 'SODAPOS PRO', value: 'Pro'}, {text: 'SODAPOS LITE', value: 'Lite'}]">
          </v-select>
           <v-select
            dense
            outlined
            v-model="filter.status"
            color="#d31145"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan status promo"
            :items="[{text: 'Promo Belum Mulai', value: 'Belum Mulai'}, {text: 'Promo Sedang Berlangsung', value: 'Mulai'}, {text: 'Promo Sudah Berakhir', value: 'Selesai'}]">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="filterData('filter')"
          >
            Filter
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            color="orange"
            class="white--text text-capitalize"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="dialog.filtered = false;"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data promo
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data serial aktivasi ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deletePromo()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post, exportFile } from '@/service/Axios'
import moment from 'moment'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Export,
    Empty,
  },
  data () {
    return {
      start_date: false,
      end_date: false,
      searching: "",
      feedback: "",
      details: {},
      filter: {
        package_type: "",
        status: "",
      },
      process: {
        run: false
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      dialog: {
        information: false,
        confirmation: false,
        confirmation_type: "",
        success: false,
        form: false,
        form_type: "",
        filtered: false,
      },
      form: {
        name: "",
        type: "",
        subscribe_type: "",
        subscribe_value: "",
        price: "",
        qty: "",
        show_price_bool: true,
        show_status_bool: true,
        sales_id: "",
        partner_id: "",
        franchise_id: "",
      },
      sales: [],
      discount_array: [
        {
          value_percent: 0,
          qty: 0,
          commision_percent: 0,
          commision_calculate_bool: false,
          commision_after_disct_bool: false
        }
      ],
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 130
        },
        {
          text: 'Nama Serial Aktivasi',
          value: 'name',
          align: 'start',
          width: 250,
        },
        {
          text: 'Total Kode Serial',
          value: 'total_code',
          align: 'center',
          width: 200
        },
        {
          text: 'Tipe Paket',
          value: 'type',
          align: 'center',
          width: 150
        },
        {
          text: 'Lama Paket',
          value: 'subscribe_value',
          align: 'center',
          width: 150
        },
        {
          text: 'Harga',
          value: 'price',
          align: 'center',
          width: 150
        },
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
  },
  computed: {
  },
  mounted() {
    this.fetch()
  },
  methods: {
    resetFilter() {
      this.filter = {
        package_type: "",
        status: "",
      };
      this.filterData('reset')
      return
    },

    filterData(type) {
      if (type == 'reset') {
        this.fetch()
      }else {
        this.fetch()
        this.dialog.filtered = false
      }
    },

    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/serial-activation', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          package_type: this.filter.package_type,
          status: this.filter.status
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },

    async fetchSales() {
      this.process.run = true

      await get('internal/dashboard/sales', {
        params: {
          pagination_bool: false,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.sales = res.results.data
          this.process.run = false
        }
      })
    },

    async fetchPackageActivated() {
      this.process.run = true

      await get('internal/dashboard/sales/package-type', {
        params: {
          pagination_bool: false,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.form.package_type_array = res.results.data
          this.process.run = false
        }
      })
    },

    actions(item, method) {
      switch(method) {
        case "add":
          this.dialog.form = true
          this.dialog.form_type = "add"
          this.form = {
            name: "",
            type: "",
            subscribe_type: "",
            subscribe_value: "",
            price: "",
            qty: "",
            show_price_bool: true,
            show_status_bool: true,
            sales_id: "",
            partner_id: "",
            franchise_id: "",
          }
          this.fetchSales()
          this.fetchPackageActivated()
          // this.$refs['validation-new-serial'].reset();
          break;
        case 'delete':
          this.details = item
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "delete"
          break;
        case 'export':
          let url = 'internal/dashboard/serial-activation/export-excel'
          let params = {
            id: item.id,
            pagination_bool: false,
            show_price_bool: true,
            show_status_bool: true
          }
          this.exportExcel(url, params,'Data Nomor Serial Aktivasi','xls' )
          break;
      }
    },

    async deletePromo() {
      await post(`internal/dashboard/serial-activation/delete`,{
        data: {
          id: this.details.id,
        }
      }).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.dialog.confirmation = false
          this.dialog.success = true
          this.fetch()
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: res.message ? res.message : "Gagal menghapus data promo"
          }
        }
      }).catch((error) => {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Terjadi kendala pada server."
        }
      })
    },

    async exportExcel(url, params, title, file_type) {
      this.$store.state.loading = true

      await exportFile("GET", `${url}`, {
        params: params,
      }).then((response) => {
        let res = response;
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${title}-${moment().format("DMYYYYHHmmss")}.${file_type}`
          );
          document.body.appendChild(link);
          link.click();
          this.$store.state.loading = false;
        } else {
          this.$store.state.loading = false;
          this.error.message = "Gagal mengeksport excel.";
        }
        this.$store.state.loading = false;
      }).catch((error) => {
        this.$store.state.loading = false;
      });
      this.$store.state.loading = false;
    },

    async save(type) {
      let _self = this;

      this.feedback = "";
      

      const isValid = await this.$refs["validation-new-serial"].validate();

      if (isValid) {
        this.process.run = true;

        let url = ""
        let data = {}
        if (type == "add") {
          url = `internal/dashboard/serial-activation`
          data = this.form
        }else {
          url = `internal/dashboard/promo/update`
          data = this.form
        }

        await post(url, {
          data
        })
          .then((response) => {
            let res = response
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-serial"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";
              this.fetch();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.run = false;
      }
    },
  }
}
</script>