<template>
  <div
    class="my-5"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'"
  >
    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title
        class="body-1 px-5 text-first pt-0 font-weight-bold flex flex justify-space-between align-start"
      >
        <div>
          RINGKASAN PEMASUKAN DANA
          <!-- <div class="mt-1">
            <Export
              class="mr-2"
              :disabled="process.run"
              :file_type="'xls'"
              :btnText="'Export Excel'"
              :btnIcon="'mdi-microsoft-excel'"
              :btnColor="'teal'"
              :url="'internal/income/self-order/fee-payment/dikasir/export_excel'"
              :title="'Data Pemasukan Dana Dari Pesan Ditempat'"
              ref="base-export-excel"
            />
          </div> -->
        </div>
        <div style="width: 500px">
          <VueCtkDateTimePicker
            color="#d31145"
            onlyDate
            :range="true"
            right
            v-model="filter_date"
            label="Pilih Tanggal"
            :noClearButton="true"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="new Date().toISOString().substr(0, 10)"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' },
            ]"
          />
        </div>
      </v-card-title>
      <v-card-text>
        <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0">
          <v-card-title
            class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer"
          >
            SUMMARY
            <v-spacer></v-spacer>
            <!-- <v-btn
              text
              elevation="0"
              class="text-capitalize pink--text"
              small>
              {{ is_show_summary ? "Sembunyikan Summary" : "Lihat Summary" }}
              <v-icon right>{{ is_show_summary ? "mdi-arrow-up" : "mdi-arrow-down"}}</v-icon>
            </v-btn> -->
          </v-card-title>
          <v-expand-transition>
            <v-row class="mb-2" v-show="is_show_summary">
              <v-col
                cols="12"
                sm="4"
                lg="4"
                md="4"
                xs="12"
                v-for="(item, index) in summary"
                :key="index"
              >
                <v-skeleton-loader
                  class="ma-auto"
                  height="100"
                  :loading="process.run"
                  transition="scale-transition"
                  :tile="false"
                  type="image"
                >
                  <v-card
                    :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                    class="box-shadow border-radius py-0"
                  >
                    <v-card-title class="subtitle-1 font-weight-bold">
                      {{ item.label }}
                      <v-spacer></v-spacer>
                      <v-tooltip left v-if="item.help">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="#D31145"
                            v-bind="attrs"
                            v-on="on"
                            class="cursor-pointer"
                            size="20"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <div v-html="item.help"></div>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text class="subtitle-2">
                      <p
                        class="text-second d-flex align-center"
                        style="font-size: 25px"
                      >
                        <span class="mr-3 mt-3">
                          {{ item.value }}
                        </span>
                        <v-spacer />
                        <span>
                          <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                        </span>
                      </p>
                    </v-card-text>

                    <!-- START DETAIL ARRAY -->
                    <v-row class="mb-2" v-show="item?.detail_array?.length > 0">
                      <v-col
                        cols="12"
                        sm="6"
                        lg="6"
                        md="6"
                        xs="12"
                        v-for="(itemDetail, indexDetail) in item?.detail_array"
                        :key="indexDetail"
                        cla
                      >
                        <div class="subtitle-1 font-weight-bold px-4">
                          {{ `${itemDetail.label} : ` }}
                        </div>
                        <div class="subtitle-1 px-4 text-right">
                          {{ `${itemDetail.value}` }}
                        </div>
                      </v-col>
                    </v-row>
                    <!-- END DETAIL ARRAY -->
                  </v-card>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card>

        <v-btn-toggle class="mt-2" v-model="tab.active" mandatory>
          <v-btn
            height="30"
            v-for="(item, i) in [
              {
                text: 'SELF ORDER - PESAN DIKASIR - PEMBAYARAN DARING',
                value: 'selfOrderCashierPembayaranDaring',
              },
              {
                text: 'SELF ORDER - PESAN ANTAR',
                value: 'selfOrderPesanAntar',
              },
              {
                text: 'OUTLET - PENARIKAN DANA - POTONGAN ADMIN ',
                value: 'storeWithdrawFeeAdmin',
              },
              { text: 'OUTLET - AKTIFASI', value: 'storeActivation' },
            ]"
            :key="i"
            :value="item.value"
            :color="item.value == tab.active ? '#d31145' : ''"
            small
            :class="
              item.value == tab.active
                ? 'white--text text-uppercase font-weight-bold'
                : 'text-uppercase'
            "
          >
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>

        <div class="d-flex justify-center mt-5">
          <v-text-field
            outlined
            dense
            color="#D31145"
            style="width: 300px"
            :placeholder="search.placeholder"
            class="font-weight-normal"
            v-model="search.value"
            @input="handleSearch"
          >
          </v-text-field>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1 ml-3"
                v-on="on"
                v-bind="attrs"
                icon
                @click="dialog.filter = true"
              >
                <v-icon size="25">mdi-filter-outline</v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip> -->
        </div>

        <!-- START SELF ORDER PEMBAYARAN DARING -->
        <div v-show="tab.active == 'selfOrderCashierPembayaranDaring'">
          <div v-if="selfOrderCashierPembayaranDaring.items.length > 0">
            <v-data-table
              :headers="selfOrderCashierPembayaranDaring.headers"
              :items="selfOrderCashierPembayaranDaring.items"
              hide-default-footer
              disable-pagination
              :options.sync="selfOrderCashierPembayaranDaring.options"
              :loading="process.run"
              loading-text="Sedang memuat"
              no-data-text="Data tidak tersedia"
              no-results-text="Data tidak tersedia"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              fixed-header
              flat
              class="elevation-0 rounded-lg"
            >
              <template v-slot:[`item.datetime`]="{ item }">
                {{ item.datetime | datetime }}
              </template>
              <template v-slot:[`item.store_name`]="{ item }">
                <div>
                  {{ item.store_name }}
                </div>
                <div class="text-capitalize">
                  {{ `${item.merchant_type ? item.merchant_type : "-"}` }}
                </div>
              </template>
            </v-data-table>

            <Pagination
              @changeLimit="
                limit = $event;
                fetchIncomeSelfOrderCashierPembayaranDaring();
              "
              @changePage="
                page = $event;
                fetchIncomeSelfOrderCashierPembayaranDaring($event);
              "
              :model="selfOrderCashierPembayaranDaring.pagination"
              v-show="
                selfOrderCashierPembayaranDaring.pagination.data?.total_page > 0
              "
            />
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty
              value="Data belum tersedia atau data yang Anda cari tidak tersedia"
            />
          </div>
        </div>
        <!-- END SELF ORDER PEMBAYARAN DARING -->

        <!-- START SELF ORDER PESAN ANTAR -->
        <div v-show="tab.active == 'selfOrderPesanAntar'">
          <div v-if="selfOrderPesanAntar.items.length > 0">
            <v-data-table
              :headers="selfOrderPesanAntar.headers"
              :items="selfOrderPesanAntar.items"
              hide-default-footer
              disable-pagination
              :options.sync="selfOrderPesanAntar.options"
              :loading="process.run"
              loading-text="Sedang memuat"
              no-data-text="Data tidak tersedia"
              no-results-text="Data tidak tersedia"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              fixed-header
              flat
              class="elevation-0 rounded-lg"
            >
              <template v-slot:[`item.datetime`]="{ item }">
                {{ item.datetime | datetime }}
              </template>
              <template v-slot:[`item.store_name`]="{ item }">
                <div>
                  {{ item.store_name }}
                </div>
                <div class="text-capitalize">
                  {{
                    `${
                      item.merchant_type
                        ? item.merchant_type == "personal"
                          ? "Sodaku"
                          : item.merchant_type
                        : "-"
                    }`
                  }}
                </div>
              </template>
            </v-data-table>

            <Pagination
              @changeLimit="
                limit = $event;
                fetchIncomeSelfOrderPesanAntar();
              "
              @changePage="
                page = $event;
                fetchIncomeSelfOrderPesanAntar($event);
              "
              :model="selfOrderPesanAntar.pagination"
              v-show="selfOrderPesanAntar.pagination.data?.total_page > 0"
            />
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty
              value="Data belum tersedia atau data yang Anda cari tidak tersedia"
            />
          </div>
        </div>
        <!-- END SELF ORDER PESAN ANTAR -->

        <!-- START OUTLET - POTONGAN ADMIN PENARIKAN DANA -->
        <div v-show="tab.active == 'storeWithdrawFeeAdmin'">
          <div v-if="storeWithdrawFeeAdmin.items.length > 0">
            <v-data-table
              :headers="storeWithdrawFeeAdmin.headers"
              :items="storeWithdrawFeeAdmin.items"
              hide-default-footer
              disable-pagination
              :options.sync="storeWithdrawFeeAdmin.options"
              :loading="process.run"
              loading-text="Sedang memuat"
              no-data-text="Data tidak tersedia"
              no-results-text="Data tidak tersedia"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              fixed-header
              flat
              class="elevation-0 rounded-lg"
            >
              <template v-slot:[`item.datetime`]="{ item }">
                {{ item.datetime | datetime }}
              </template>
              <template v-slot:[`item.store_name`]="{ item }">
                <div>
                  {{ item.store_name }}
                </div>
                <div class="text-capitalize">
                  {{
                    `${
                      item.merchant_type
                        ? item.merchant_type == "personal"
                          ? "sodaku"
                          : item.merchant_type
                        : "-"
                    }`
                  }}
                </div>
              </template>
            </v-data-table>

            <Pagination
              @changeLimit="
                limit = $event;
                fetchIncomeStoreWithdrawFeeAdmin();
              "
              @changePage="
                page = $event;
                fetchIncomeStoreWithdrawFeeAdmin($event);
              "
              :model="storeWithdrawFeeAdmin.pagination"
              v-show="storeWithdrawFeeAdmin.pagination.data?.total_page > 0"
            />
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty
              value="Data belum tersedia atau data yang Anda cari tidak tersedia"
            />
          </div>
        </div>
        <!-- END OUTLET - POTONGAN ADMIN PENARIKAN DANA -->

        <!-- START OUTLET - AKTIVASI -->
        <div v-show="tab.active == 'storeActivation'">
          <div v-if="storeActivation.items.length > 0">
            <v-data-table
              :headers="storeActivation.headers"
              :items="storeActivation.items"
              hide-default-footer
              disable-pagination
              :options.sync="storeActivation.options"
              :loading="process.run"
              loading-text="Sedang memuat"
              no-data-text="Data tidak tersedia"
              no-results-text="Data tidak tersedia"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              fixed-header
              flat
              class="elevation-0 rounded-lg"
            >
              <template v-slot:[`item.datetime`]="{ item }">
                {{ item.datetime | datetime }}
              </template>
              <template v-slot:[`item.store_name`]="{ item }">
                <div>
                  {{ item.store_name }}
                </div>
                <div class="text-capitalize">
                  {{
                    `${
                      item.merchant_type
                        ? item.merchant_type == "personal"
                          ? "sodaku"
                          : item.merchant_type
                        : "-"
                    }`
                  }}
                </div>
              </template>
              <template v-slot:[`item.activation_serial_bool`]="{ item }">
                {{ item.activation_serial_bool ? "Serial" : "Paket" }}
              </template>
              <template v-slot:[`item.package_name`]="{ item }">
                <div>
                  {{ item.package_name }}
                </div>
                <div class="text-capitalize">
                  {{
                    `Tipe Paket : ${
                      item.package_type
                        ? item.package_type == "Personal"
                          ? "Sodaku"
                          : item.package_type
                        : "-"
                    }`
                  }}
                </div>
              </template>
              <template v-slot:[`item.discount_value`]="{ item }">
                Rp{{ item.discount_value | num_format }}
              </template>
              <template v-slot:[`item.discount_promo_value`]="{ item }">
                Rp{{ item.discount_promo_value | num_format }}
              </template>
            </v-data-table>

            <Pagination
              @changeLimit="
                limit = $event;
                fetchIncomeStoreActivation();
              "
              @changePage="
                page = $event;
                fetchIncomeStoreActivation($event);
              "
              :model="storeActivation.pagination"
              v-show="storeActivation.pagination.data?.total_page > 0"
            />
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty
              value="Data belum tersedia atau data yang Anda cari tidak tersedia"
            />
          </div>
        </div>
        <!-- END OUTLET - AKTIVASI -->
      </v-card-text>
    </v-card>

    <!-- ==== DIALOG SUCCESS ==== -->
    <!-- <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important"
            >
              Anda telah berhasil
              {{
                dialog.success_type == "delete"
                  ? "menghapus"
                  : "mengubah status"
              }}
              akun
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false"
            >
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- ==== DIALOG SUCCESS ==== -->
    <!-- <v-dialog v-model="dialog.confirmation" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important"
            >
              Apakah Anda ingin
              {{
                dialog.confirmation_type == "delete"
                  ? "menghapus"
                  : "mengubah status"
              }}
              akun intenal ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="
              dialog.confirmation = false;
              dialog.confirmation_type = '';
              form.id = '';
            "
          >
            Batal
          </v-btn>
          <v-btn
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteAccount()"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import { get, post } from "@/service/Axios";
import { debounce, debounceThis } from "@/utils/UtilsCommon";
import Pagination from "@/components/Pagination";
import Export from "@/components/Export";
import Empty from "@/components/Empty";
export default {
  components: {
    Pagination,
    Empty,
    Export,
  },
  data() {
    return {
      searching: "",
      filter_date: {
        start: null,
        end: null,
      },
      is_show_summary: true,
      details: {},
      summaryCommission: [],
      summary: [],
      process: {
        run: false,
      },
      search: {
        value: "",
        fetch: true,
        placeholder: "Cari berdasarkan No. Transaksi",
      },
      dialog: {
        filter: false,
        confirmation: false,
        confirmation_type: "",
        success: false,
        success_type: "",
        commission_sales: false,
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      // DATA TABLES
      selfOrderCashierPembayaranDaring: {
        items: [],
        pagination: {
          data: {},
          page: "",
          totalData: 0,
        },
        headers: [
          {
            text: "Tanggal",
            value: "datetime",
            align: "center",
            width: 150,
          },
          {
            text: "Outlet",
            value: "store_name",
            align: "start",
            width: 150,
          },
          {
            text: "No. Transaksi",
            value: "number",
            align: "center",
            width: 150,
          },
          {
            text: "Pendapatan Outlet",
            value: "paid",
            align: "end",
            width: 200,
          },
          {
            text: "Biaya Midtrans",
            value: "midtrans_fee",
            align: "end",
            width: 200,
          },
          {
            text: "Biaya Platform",
            value: "platform_fee",
            align: "end",
            width: 200,
          },
          {
            text: "Pendapatan SODA",
            value: "income",
            align: "end",
            width: 200,
          },
        ],
        options: {},
      },
      selfOrderPesanAntar: {
        items: [],
        pagination: {
          data: {},
          page: "",
          totalData: 0,
        },
        headers: [
          {
            text: "Tanggal",
            value: "datetime",
            align: "center",
            width: 150,
          },
          {
            text: "Outlet",
            value: "store_name",
            align: "start",
            width: 150,
          },
          {
            text: "No. Transaksi",
            value: "number",
            align: "center",
            width: 150,
          },
          {
            text: "Pendapatan Outlet",
            value: "paid",
            align: "end",
            width: 200,
          },
          {
            text: "Biaya Midtrans",
            value: "midtrans_fee",
            align: "end",
            width: 200,
          },
          {
            text: "Biaya Platform",
            value: "platform_fee",
            align: "end",
            width: 200,
          },
          {
            text: "Pendapatan SODA",
            value: "income",
            align: "end",
            width: 200,
          },
        ],
        options: {},
      },
      storeWithdrawFeeAdmin: {
        items: [],
        pagination: {
          data: {},
          page: "",
          totalData: 0,
        },
        headers: [
          {
            text: "Tanggal",
            value: "datetime",
            align: "center",
            width: 150,
          },
          {
            text: "Outlet",
            value: "store_name",
            align: "start",
            width: 150,
          },
          {
            text: "Kode",
            value: "code",
            align: "center",
            width: 200,
          },
          {
            text: "Nominal Pengajuan",
            value: "nominal",
            align: "end",
            width: 200,
          },
          {
            text: "Pendapatan SODA (Biaya Potongan Admin)",
            value: "income",
            align: "end",
            width: 200,
          },
        ],
        options: {},
      },
      storeActivation: {
        items: [],
        pagination: {
          data: {},
          page: "",
          totalData: 0,
        },
        headers: [
          {
            text: "Tanggal",
            value: "datetime",
            align: "center",
            width: 150,
          },
          {
            text: "Outlet",
            value: "store_name",
            align: "start",
            width: 150,
          },
          {
            text: "Kode Pembayaran",
            value: "code",
            align: "center",
            width: 200,
          },
          {
            text: "Tipe Aktifasi",
            value: "activation_serial_bool",
            align: "center",
            width: 150,
          },
          {
            text: "Paket Aktifasi",
            value: "package_name",
            align: "start",
            width: 200,
          },
          {
            text: "Harga Paket",
            value: "product_price",
            align: "end",
            width: 200,
          },
          {
            text: "Diskon Paket",
            value: "discount_value",
            align: "end",
            width: 200,
          },
          {
            text: "Diskon Promo",
            value: "discount_promo_value",
            align: "end",
            width: 200,
          },
          {
            text: "Pendapatan SODA",
            value: "income",
            align: "end",
            width: 200,
          },
        ],
        options: {},
      },
      sales_id: "",
      limit: 10,
      page: 1,
      options: {},
      tab: {
        active: "selfOrderCashierPembayaranDaring",
      },
      selected: {
        commission: {
          pagination: 0,
        },
        menu: 0,
        pagination: 0,
      },
    };
  },
  watch: {
    // "search.value": debounceThis(function(val) {
    //   this.search.value = val;
    //   this.fetchByTab(this.tab.active);
    // }, 500),
    filter_date: function (val) {
      this.fetchByTab(this.tab.active);
      this.fetchSummary();
    },
    "tab.active": {
      handler(val) {
        this.search.value = "";
        this.searchPlaceholderByTab(val);
        this.fetchByTab(val);
      },
      deep: true,
    },
    // "selected.pagination": function (val) {
    //   this.fetchIncomeSelfOrderCashierPembayaranDaring(val + 1);
    // },
    "selfOrderCashierPembayaranDaring.options": {
      handler() {
        this.fetchIncomeSelfOrderCashierPembayaranDaring();
      },
      deep: true,
    },
    "selfOrderPesanAntar.options": {
      handler() {
        this.fetchIncomeSelfOrderPesanAntar();
      },
      deep: true,
    },
    "storeWithdrawFeeAdmin.options": {
      handler() {
        this.fetchIncomeStoreWithdrawFeeAdmin();
      },
      deep: true,
    },
    "storeActivation.options": {
      handler() {
        this.fetchIncomeStoreActivation();
      },
      deep: true,
    },
  },
  mounted() {
    this.filter_date.start = moment().startOf("month").format("YYYY-MM-DD");
    // this.filter_date.end = moment().endOf("month").format("YYYY-MM-DD");
    this.filter_date.end = moment().format("YYYY-MM-DD");
    this.fetchIncomeSelfOrderCashierPembayaranDaring();
    this.fetchSummary();
  },
  methods: {
    searchPlaceholderByTab(tab) {
      switch (tab) {
        case "selfOrderCashierPembayaranDaring":
          this.search.placeholder = "Cari berdasarkan No. Transaksi";
          break;
        case "selfOrderPesanAntar":
          this.search.placeholder =
            "Cari berdasarkan No. Transaksi atau Outlet";
          break;
        case "storeWithdrawFeeAdmin":
          this.search.placeholder = "Cari berdasarkan Kode atau Outlet";
          break;
        case "storeActivation":
          this.search.placeholder = "Cari berdasarkan Kode";
          break;
        default:
          this.search.placeholder = "Cari berdasarkan...";
          break;
      }
    },
    fetchByTab(tab) {
      switch (tab) {
        case "selfOrderCashierPembayaranDaring":
          this.fetchIncomeSelfOrderCashierPembayaranDaring();
          break;
        case "selfOrderPesanAntar":
          this.fetchIncomeSelfOrderPesanAntar();
          break;
        case "storeWithdrawFeeAdmin":
          this.fetchIncomeStoreWithdrawFeeAdmin();
          break;
        case "storeActivation":
          this.fetchIncomeStoreActivation();
          break;
      }
    },

    actions(type, item) {
      switch (type) {
        case "sales_commission":
          this.sales_id = item.id;
          this.dialog.commission_sales = true;
          // this.fetchIncome();
          break;
      }
    },

    handleSearch: debounceThis(function () {
      this.fetchByTab(this.tab.active);
    }, 500),

    async fetchIncomeSelfOrderCashierPembayaranDaring(paging) {
      this.process.run = true;

      let sort = "-datetime";

      if (
        this.selfOrderCashierPembayaranDaring.options.sortBy &&
        this.selfOrderCashierPembayaranDaring.options.sortBy.length > 0
      ) {
        if (
          this.selfOrderCashierPembayaranDaring.options?.sortDesc &&
          this.selfOrderCashierPembayaranDaring.options.sortDesc[0] !=
            undefined &&
          this.selfOrderCashierPembayaranDaring.options.sortDesc[0]
        ) {
          sort = `-${this.selfOrderCashierPembayaranDaring.options.sortBy[0]}`;
        } else {
          sort = `${this.selfOrderCashierPembayaranDaring.options.sortBy[0]}`;
        }
      }

      await get("internal/income/self-order", {
        params: {
          search: this.search.value,
          sort,
          page: paging,
          limit: this.limit,
          start_date: this.filter_date.start,
          end_date: this.filter_date.end
            ? this.filter_date.end
            : this.filter_date.start,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.selfOrderCashierPembayaranDaring.items = res.results.data;
          this.selfOrderCashierPembayaranDaring.pagination.totalData =
            res.results.pagination.total_data;
          this.selfOrderCashierPembayaranDaring.pagination.data =
            res.results.pagination;
          this.selfOrderCashierPembayaranDaring.pagination.page =
            res.results.pagination.total_page < this.page ? 1 : "";
          this.process.run = false;
        }
      });

      this.process.run = false;
    },

    async fetchIncomeSelfOrderPesanAntar(paging) {
      this.process.run = true;

      let sort = "-datetime";

      if (
        this.selfOrderPesanAntar.options?.sortBy &&
        this.selfOrderPesanAntar.options?.sortBy?.length > 0
      ) {
        if (
          this.selfOrderPesanAntar.options?.sortDesc &&
          this.selfOrderPesanAntar.options.sortDesc[0] != undefined &&
          this.selfOrderPesanAntar.options.sortDesc[0]
        ) {
          sort = `-${this.selfOrderPesanAntar.options.sortBy[0]}`;
        } else {
          sort = `${this.selfOrderPesanAntar.options.sortBy[0]}`;
        }
      }

      await get("internal/income/self-order/shipment", {
        params: {
          search: this.search.value,
          sort,
          page: paging,
          limit: this.limit,
          start_date: this.filter_date.start,
          end_date: this.filter_date.end
            ? this.filter_date.end
            : this.filter_date.start,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.selfOrderPesanAntar.items = res.results.data;
          this.selfOrderPesanAntar.pagination.totalData =
            res.results.pagination.total_data;
          this.selfOrderPesanAntar.pagination.data = res.results.pagination;
          this.selfOrderPesanAntar.pagination.page =
            res.results.pagination.total_page < this.page ? 1 : "";
          this.process.run = false;
        }
      });

      this.process.run = false;
    },

    async fetchIncomeStoreWithdrawFeeAdmin(paging) {
      this.process.run = true;

      let sort = "-datetime";

      if (
        this.storeWithdrawFeeAdmin.options?.sortBy &&
        this.storeWithdrawFeeAdmin.options?.sortBy?.length > 0
      ) {
        if (
          this.storeWithdrawFeeAdmin.options?.sortDesc &&
          this.storeWithdrawFeeAdmin.options.sortDesc[0] != undefined &&
          this.storeWithdrawFeeAdmin.options.sortDesc[0]
        ) {
          sort = `-${this.storeWithdrawFeeAdmin.options.sortBy[0]}`;
        } else {
          sort = `${this.storeWithdrawFeeAdmin.options.sortBy[0]}`;
        }
      }

      await get("internal/income/outlet-withdraw/admin-fee", {
        params: {
          search: this.search.value,
          sort,
          page: paging,
          limit: this.limit,
          start_date: this.filter_date.start,
          end_date: this.filter_date.end
            ? this.filter_date.end
            : this.filter_date.start,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.storeWithdrawFeeAdmin.items = res.results.data;
          this.storeWithdrawFeeAdmin.pagination.totalData =
            res.results.pagination.total_data;
          this.storeWithdrawFeeAdmin.pagination.data = res.results.pagination;
          this.storeWithdrawFeeAdmin.pagination.page =
            res.results.pagination.total_page < this.page ? 1 : "";
          this.process.run = false;
        }
      });

      this.process.run = false;
    },

    async fetchIncomeStoreActivation(paging) {
      this.process.run = true;

      let sort = "-datetime";

      if (
        this.storeActivation.options?.sortBy &&
        this.storeActivation.options?.sortBy?.length > 0
      ) {
        if (
          this.storeActivation.options?.sortDesc &&
          this.storeActivation.options.sortDesc[0] != undefined &&
          this.storeActivation.options.sortDesc[0]
        ) {
          sort = `-${this.storeActivation.options.sortBy[0]}`;
        } else {
          sort = `${this.storeActivation.options.sortBy[0]}`;
        }
      }

      await get("internal/income/activation", {
        params: {
          search: this.search.value,
          sort,
          page: paging,
          limit: this.limit,
          start_date: this.filter_date.start,
          end_date: this.filter_date.end
            ? this.filter_date.end
            : this.filter_date.start,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.storeActivation.items = res.results.data;
          this.storeActivation.pagination.totalData =
            res.results.pagination.total_data;
          this.storeActivation.pagination.data = res.results.pagination;
          this.storeActivation.pagination.page =
            res.results.pagination.total_page < this.page ? 1 : "";
          this.process.run = false;
        }
      });

      this.process.run = false;
    },

    async fetchSummary() {
      await get("internal/income/summary", {
        params: {
          start_date: this.filter_date.start,
          end_date: this.filter_date.end
            ? this.filter_date.end
            : this.filter_date.start,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.summary = res.results.data;
        }
      });
    },
  },
};
</script>