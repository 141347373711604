<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-subheader class="pb-0 pt-5 pl-5" style="height: 10px;">
        <v-btn
          to="/serial-activation"
          text
          small
          color="#1B59F8"
          class="text-capitalize">
          <v-icon left>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
      </v-subheader>
      <v-card-title class="body-1 px-5 text-first pt-5 font-weight-bold">
        DAFTAR INFORMASI NOMOR SERIAL AKTIVASI
        <v-spacer></v-spacer>
        <!-- <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan kode promo"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field> -->
      </v-card-title>
      <!-- {{ detail_promo }} -->
      <v-card-subtitle class="py-0 px-5 d-flex justify-space-between">
        <div class="subtitle-1 black--text my-2 d-flex " style="font-weight: 500">
          <div class="mr-5">
            Nama Serial Aktivasi : {{ detail_promo.name }} <br>
          </div>
        </div>
      </v-card-subtitle>
      <v-card-text>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.number`]="{item}">
              <v-list-item class="pl-0">

                <v-list-item-content>
                  <v-list-item-title class="d-flex subtitle-2 mb-5"  @click="onCopySingle(item.number, 'Nomor Serial')">
                    Nomor Serial : 
                    <div 
                      class="text-decoration-underline cursor-pointer ml-1">{{ item.number }}</div>
                      <v-icon small>
                        mdi-content-copy
                      </v-icon>
                  </v-list-item-title>
                  <v-list-item-title class="d-flex subtitle-2" @click="onCopySingle(item.pin, 'PIN Serial')">
                    Pin Serial : 
                    <div 
                      class="text-decoration-underline cursor-pointer ml-1">{{ item.pin }}</div>
                    <v-icon small>
                      mdi-content-copy
                    </v-icon>
                  </v-list-item-title>
                </v-list-item-content>
                <!-- <v-list-item-content class="cursor-pointer" @click="onCopyToClipboard(item)">
                  <v-icon>
                    mdi-content-copy
                  </v-icon>
                </v-list-item-content> -->
              </v-list-item>
            </template>
            
            <template v-slot:[`item.status`]="{item}">
              <v-chip v-if="item.status == 'Belum Digunakan'" color="error" class="font-weight-bold">
                <v-icon small left>mdi-calendar-lock-outline</v-icon>
                Belum Digunakan
              </v-chip>
              <v-chip v-if="item.status == 'Sudah Digunakan'" color="success" class="font-weight-bold">
                <v-icon small left>mdi-calendar-check-outline</v-icon>
                Sudah Digunakan
              </v-chip>
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="blue"
                    @click="onCopyToClipboard(item)"
                    icon>
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>
                  Salin Nomor Serial dan PIN Serial
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                    @click="actions(item, 'delete_code')"
                    icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>
                  Hapus Serial
                </span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.confirmation_type == 'delete_code' ? 'menghapus' : 'mengubah status' }} kode promo
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete_code' ? 'menghapus' : 'mengubah status' }} nomor serial ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteCodePromo()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- === DIALOG INFORMATION === -->
    <v-dialog v-model="dialog.information" width="500">
      <v-card class="border-radius box-shadow">
        <v-card-title></v-card-title>
        <v-card-text>
          <div>
            <b>Setelah Diskon</b>
            <p>Perhitungan komisi sales akan dihitung setelah diskon paket, diskon promo</p>
          </div>
          <div>
            <b>Sebelum Diskon</b>
            <p>Perhitungan komisi sales akan dihitung dari harga awal paket</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import moment from 'moment'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Export,
    Empty,
  },
  data () {
    return {
      searching: "",
      process: {
        run: false
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      dialog: {
        information: false,
        confirmation: false,
        confirmation_type: "",
        success: false,
        form: false,
        form_type: "",
      },
      // DATA TABLES
      detail_promo: {},
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Serial Aktivasi',
          value: 'number',
          align: 'start',
          width: 250
        },
        {
          text: 'Status',
          value: 'status',
          align: 'start',
          width: 200
        },
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        }
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
  },
  computed: {
    FormatStartDate() {
      return this.form.start_date ? moment(this.form.start_date).format("DD MMMM YYYY") : ''
    },
    FormatEndDate() {
      return this.form.end_date ? moment(this.form.end_date).format("DD MMMM YYYY") : ''
    },
  },
  mounted() {
    this.fetch()
    this.fetchSerial()
  },
  methods: {
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/serial-activation/number', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          id: this.$route.params.id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },

    async fetchSerial() {
      this.process.run = true

      await get('internal/dashboard/serial-activation', {
        params: {
          id: this.$route.params.id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
           this.detail_promo = res.results.data[0]
          this.process.run = false
        }
      })
    },

    onCopyToClipboard (item) {
      navigator.clipboard.writeText(`Serial: ${item.number} - PIN: ${item.pin}`)
      this.snackbar = {
        show: true,
        text: "Serial Aktivasi berhasil dicopy",
        color:"success"
      }
    },

    onCopySingle (item, text) {
      navigator.clipboard.writeText(`${item}`)
      this.snackbar = {
        show: true,
        text: `${text} berhasil dicopy`,
        color:"success"
      }
    },

    actions(item, method) {
      switch(method) {
        case 'delete_code':
          this.details = item
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "delete_code"
          break;
      }
    },

    async deleteCodePromo() {
      await post(`internal/dashboard/serial-activation/number/delete`,{
        data: {
          id: this.details.id,
        }
      }).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.dialog.confirmation = false
          this.dialog.success = true
          this.fetch()
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Gagal menghapus data nomor serial"
          }
        }
      }).catch((error) => {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Terjadi kendala pada server."
        }
      })
    },
  }
}
</script>