<template>
  <div
    class="my-5"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'"
  >
    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR INFORMASI PROMO
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama promo, nama sales"
          class="font-weight-normal mr-3"
          v-model="searching"
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-btn
            @click="actions(null, 'add')"
            elevation="0"
            class="text-capitalize white--text"
            color="#D31145"
          >
            <v-icon left>mdi-plus</v-icon>
            Tambah Promo
          </v-btn>

          <div class="d-flex">
            <Export
              class="mr-2"
              :disabled="process.run"
              :file_type="'xls'"
              :btnText="'Export Excel'"
              :btnIcon="'mdi-microsoft-excel'"
              :btnColor="'teal'"
              :url="'internal/dashboard/sales/promo/export_excel'"
              :title="'Data Kode Promo'"
              ref="base-export-excel"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-3"
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="dialog.filtered = true"
                >
                  <v-icon size="25">mdi-filter-outline</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </div>
        </div>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption" :title="item.desc">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          {{ item.desc | address }}
                        </div>
                      </template>
                      <span>
                        {{ item.desc }}
                      </span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.sales_name`]="{ item }">
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.sales_name }} - <span>{{ item.sales_code }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.sales_email"
                    class="caption"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.package_type`]="{ item }">
              <v-chip
                v-if="item.package_type == 'Lite'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="green"
              >
                SODAPOS LITE
              </v-chip>
              <v-chip
                v-else-if="item.package_type == 'Pro'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="red"
              >
                SODAPOS PRO
              </v-chip>
              <v-chip
                v-else-if="item.package_type == 'Personal'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="teal"
              >
                SODAKU
              </v-chip>
              <v-chip
                v-else-if="item.package_type == 'Semua'"
                outlined
                label
                class="font-weight-bold mt-2"
                color="teal darken-2"
              >
                SODAPOS PRO, LITE & SODAKU
              </v-chip>
              <v-chip
                v-else
                outlined
                label
                class="font-weight-bold mt-2"
                color="darken-2"
              >
                {{ item.package_type }}
              </v-chip>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="item.status == 'Belum Mulai'"
                color="error"
                class="font-weight-bold"
              >
                <v-icon small left>mdi-calendar-lock-outline</v-icon>
                Belum Berlangsung
              </v-chip>
              <v-chip
                v-if="item.status == 'Mulai'"
                color="warning"
                class="font-weight-bold"
              >
                <v-icon small left>mdi-calendar-clock-outline</v-icon>
                Sedang Berlangsung
              </v-chip>
              <v-chip
                v-if="item.status == 'Selesai'"
                color="success"
                class="font-weight-bold"
              >
                <v-icon small left>mdi-calendar-check-outline</v-icon>
                Sudah Selesai
              </v-chip>
            </template>

            <template v-slot:[`item.total_code`]="{ item }">
              <span class="subtitle-2 font-weight-normal black--text mt-3"
                >{{ item.total_code | num_format }} Kode</span
              >
              <v-btn
                :to="`/promo-sales/detail-code/${item.id}`"
                text
                small
                color="#0D47A1"
                class="subtitle-2 font-weight-normal text-capitalize"
              >
                Lihat
              </v-btn>
            </template>

            <template v-slot:[`item.use_label`]="{ item }">
              <div
                class="font-weight-bold mb-2 text-decoration-underline c-p"
                :class="item.repeat_bool ? 'teal--text' : 'red--text'"
                @click="dialog.information = true"
              >
                {{ item.repeat_bool ? "Berulang" : "Tidak Berulang" }}
              </div>
              <div v-html="item.use_label" class="subtitle-2"></div>
            </template>

            <template v-slot:[`item.discount_datetime`]="{ item }">
              <div v-if="item.start_date !== '' && item.end_date !== ''">
                {{ item.start_date | dateFull }} -
                {{ item.end_date | dateFull }}
              </div>
              <div v-else class="font-weight-bold pink--text text-uppercase">
                Berlaku Selamanya
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                    @click="actions(item, 'delete')"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span> Hapus Promo </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="teal"
                    :loading="$store.state.loading"
                    @click="actions(item, 'export')"
                    icon
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span> Export Excel by Sales {{ item.sales_name }} </span>
              </v-tooltip>
            </template>
          </v-data-table>

          <Pagination
            @changeLimit="
              limit = $event;
              fetch();
            "
            @changePage="
              page = $event;
              fetch($event);
            "
            :model="pagination"
            v-show="pagination.data.total_page > 0"
          />
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty
            value="Data belum tersedia atau data yang Anda cari tidak tersedia"
          />
        </div>
      </v-card-text>
    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog v-model="dialog.form" persistent scrollable width="1500">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Promo Baru" : "Ubah Promo" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-promo'].reset();
            "
          >
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan promo" : "Ubah promo" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-promo">
            <v-form @:keyup.enter="save(dialog.form_type)">
              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-name"
                    name="Nama Promo"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Nama Promo
                      <small style="color: #e74c3c"><i>*wajib</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan nama promo"
                      v-model="form.name"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-sales_id"
                    name="Pilih Sales"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Pilih Sales
                      <small style="color: #e74c3c"><i>*wajib</i></small>
                    </div>
                    <v-select
                      solo
                      flat
                      dense
                      color="#d31145"
                      v-model="form.sales_id"
                      background-color="#EEEEEE"
                      placeholder="Pilih sales"
                      :items="sales"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                      required
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <ValidationProvider
                ref="form-desc"
                name="Deskripsi promo"
                rules="required|max:500"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Deskripsi Promo
                  <small style="color: #e74c3c"><i>*wajib</i></small>
                </div>
                <v-textarea
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="250"
                  rows="3"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan deskripsi promo"
                  v-model="form.desc"
                  :error-messages="errors"
                  required
                  autocomplete="off"
                >
                </v-textarea>
              </ValidationProvider>

              <div class="subtitle-2 font-weight-bold mt-3">
                Berlaku untuk paket aktivasi ?
                <small class="required"><i>*wajib</i></small>
                <!-- <v-subheader class="pt-0 pl-0 teal--text">
                  Apabila tidak diisi akan dianggap berlaku untuk keduanya
                </v-subheader> -->
              </div>
              <v-row>
                <v-col>
                  <!-- :rules="form.package_id ? 'required' : ''" -->
                  <ValidationProvider
                    ref="form-package_id"
                    name="Pilih Paket Langganan"
                    v-slot="{ errors }"
                  >
                    <!-- :disabled="!form.package_bool" -->
                    <v-autocomplete
                      :items="refPackage"
                      v-model="form.package_object"
                      item-text="name"
                      item-value="id"
                      label="Pilih Paket Langganan"
                      placeholder="Pilih Paket Langganan"
                      solo
                      flat
                      dense
                      background-color="#EEEEEE"
                      color="#d31145"
                      :error-messages="errors"
                      required
                      return-object
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            textTitlePaketLangganan(data.item)
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >{{ textSubPaketLangganan(data.item) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <span
                              v-if="
                                data?.item.discount_value > 0 ||
                                data?.item.discount_percent > 0
                              "
                              ><s>Rp{{ data.item.price | pricing }}</s> Rp{{
                                textSubHargaDiskon(data.item) | pricing
                              }}
                              <span v-if="data.item.discount_percent > 0"
                                >( Diskon
                                {{ data.item.discount_percent | pricing }}%
                                )</span
                              ></span
                            >
                            <span v-else
                              >Rp{{ data.item.price | pricing }}</span
                            >
                            <span
                              >&nbsp;( HPP Rp{{
                                data.item.hpp | pricing
                              }}
                              )</span
                            >
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="
                              data?.item.discount_value > 0 ||
                              data?.item.discount_percent > 0
                            "
                            >Diskon dari
                            {{ data.item?.discount_start_datetime | datetime }}
                            s.d.
                            {{
                              data.item?.discount_end_datetime | datetime
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <!-- <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar>
                            <img :src="data.item.avatar">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template> -->
                      </template>

                      <template v-slot:selection="data">
                        <div style="padding: 1em 0em">
                          <v-list-item-title>{{
                            textTitlePaketLangganan(data.item)
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >{{ textSubPaketLangganan(data.item) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <span
                              v-if="
                                data?.item.discount_value > 0 ||
                                data?.item.discount_percent > 0
                              "
                              ><s>Rp{{ data.item.price | pricing }}</s> Rp{{
                                textSubHargaDiskon(data.item) | pricing
                              }}
                              <span v-if="data.item.discount_percent > 0"
                                >( Diskon
                                {{ data.item.discount_percent | pricing }}%
                                )</span
                              ></span
                            >
                            <span v-else
                              >Rp{{ data.item.price | pricing }}</span
                            >
                            <span
                              >&nbsp;( HPP Rp{{
                                data.item.hpp | pricing
                              }}
                              )</span
                            >
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="
                              data?.item.discount_value > 0 ||
                              data?.item.discount_percent > 0
                            "
                            >Diskon dari
                            {{ data.item?.discount_start_datetime | datetime }}
                            s.d.
                            {{
                              data.item?.discount_end_datetime | datetime
                            }}</v-list-item-subtitle
                          >
                        </div>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <div class="subtitle-1 font-weight-bold">
                Generate Kode Promo
                <small class="required"><i>wajib</i></small>
              </div>
              <v-row class="ml-3">
                <v-col
                  cols="12"
                  class="my-3 py-0"
                  v-for="(discount, key) in form.discount_array"
                  :key="key"
                >
                  <v-row>
                    <v-col cols="12" md="4">
                      <div class="subtitle-2 font-weight-bold">Diskon (%)</div>
                      <ValidationProvider
                        :name="key"
                        rules="min_0:0|max_100:100"
                        v-slot="{ errors }"
                      >
                        <v-currency-field
                          id="price"
                          solo
                          flat
                          dense
                          background-color="#EEEEEE"
                          placeholder="Tuliskan diskon promo"
                          append-icon="mdi-percent-outline"
                          type="number"
                          color="#d31145"
                          v-model="discount.value_percent"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="subtitle-2 font-weight-bold">Jumlah Kode</div>
                      <v-currency-field
                        id="price"
                        solo
                        flat
                        dense
                        background-color="#EEEEEE"
                        placeholder="Tuliskan harga promo"
                        type="number"
                        color="#d31145"
                        v-model="discount.qty"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <div class="subtitle-2 font-weight-bold">
                        Komisi Sales (%)
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on="on"
                              small
                              class="mb-1 ml-3 c-p"
                              color="deep-orange"
                              v-bind="attrs"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <p>
                            Komisi lebih dari 0, Perhitungan komisi akan
                            dihitung otomatis oleh sistem
                          </p>
                          <p>
                            Komisi sama dengan 0, Perhitungan komisi tidak akan
                            dihitung otomatis oleh sistem
                          </p>
                        </v-tooltip>
                      </div>
                      <ValidationProvider
                        :name="key"
                        rules="min_0:0|max_100:100"
                        v-slot="{ errors }"
                      >
                        <v-currency-field
                          id="price"
                          solo
                          flat
                          dense
                          background-color="#EEEEEE"
                          placeholder="Tuliskan harga promo"
                          append-icon="mdi-percent-outline"
                          type="number"
                          color="#d31145"
                          :error-messages="errors"
                          v-model="discount.commision_percent"
                          @change="
                            () => {
                              discount.commision_percent > 0
                                ? (discount.commision_calculate_bool = true)
                                : (discount.commision_calculate_bool = false);
                            }
                          "
                        />
                      </ValidationProvider>
                    </v-col>
                    <!-- <v-col cols="12" md="3">
                      <div class="subtitle-2 font-weight-bold">
                        Perhitungan Setelah Diskon
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on="on"
                              small
                              class="mb-1 ml-3 cursor-pointer"
                              color="deep-orange"
                              v-bind="attrs"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <p>
                            Apabila Ya, perhitungan komisi berdasarkan harga
                            paket sesudah diskon paket & diskon dari kode promo
                          </p>
                          <p>
                            Apabila Tidak, perhitungan komisi berdasarkan harga
                            awal paket
                          </p>
                        </v-tooltip>
                      </div>
                      <v-select
                        solo
                        flat
                        dense
                        color="#d31145"
                        v-model="discount.commision_after_disct_bool"
                        background-color="#EEEEEE"
                        placeholder="Pilih apakah promo dihitung setelah harga diskon paket"
                        :items="[
                          { text: 'Ya', value: true },
                          { text: 'Tidak', value: false },
                        ]"
                      >
                      </v-select>
                    </v-col> -->
                    <v-col
                      cols="12"
                      md="1"
                      v-if="form.discount_array.length > 1"
                    >
                      <v-btn
                        color="red"
                        class="mt-5"
                        icon
                        @click="removeDiscountArray(key)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0" cols="3">
                      <div class="subtitle-2 font-weight-bold">
                        Harga Nett
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on="on"
                              small
                              class="mb-1 ml-3 c-p"
                              color="deep-orange"
                              v-bind="attrs"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <span> Harga setelah di diskon.</span>
                        </v-tooltip>
                      </div>
                      <div class="subtitle-3 text-right input__container">
                        Rp{{ textNettPrice(discount) | pricing }}
                      </div>
                    </v-col>
                    <v-col class="py-0" cols="3">
                      <div class="subtitle-2 font-weight-bold">
                        Margin
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on="on"
                              small
                              class="mb-1 ml-3 c-p"
                              color="deep-orange"
                              v-bind="attrs"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <span> Harga setelah di diskon - HPP.</span>
                        </v-tooltip>
                      </div>
                      <div class="subtitle-3 text-right input__container">
                        Rp{{ textMargin(discount) | pricing }}
                      </div>
                    </v-col>
                    <v-col class="py-0" cols="3">
                      <div class="subtitle-2 font-weight-bold">
                        Komisi Sales
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on="on"
                              small
                              class="mb-1 ml-3 c-p"
                              color="deep-orange"
                              v-bind="attrs"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <span> Nominal dari persentasi margin. </span>
                        </v-tooltip>
                      </div>
                      <div class="subtitle-3 text-right input__container">
                        Rp{{ textSelesCommision(discount) | pricing }}
                      </div>
                    </v-col>
                    <v-col class="py-0" cols="3">
                      <div class="subtitle-2 font-weight-bold">Laba Bersih</div>
                      <div class="subtitle-3 text-right input__container">
                        Rp{{ textNettProfit(discount) | pricing }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mb-2"
                    v-if="key + 1 < form.discount_array.length"
                  >
                    <v-col class="py-0" cols="12 mt-2">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-btn
                    text
                    small
                    class="text-capitalize mb-3 float-right"
                    color="#d31145"
                    @click="addDiscountArray"
                  >
                    <v-icon small>mdi-plus</v-icon>
                    Tambah Diskon
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row class="mt-3">
                <v-col>
                  <ValidationProvider
                    ref="form-subscribe_type"
                    name="Digunakan berulang"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Apakah dapat Digunakan berulang
                      <small style="color: #e74c3c"><i>*wajib</i></small>
                    </div>
                    <v-select
                      solo
                      flat
                      dense
                      color="#d31145"
                      v-model="form.repeat_bool"
                      background-color="#EEEEEE"
                      placeholder="Pilih apakah promo dapat digunakan berulang"
                      :items="[
                        { text: 'Ya', value: true },
                        { text: 'Tidak', value: false },
                      ]"
                      :error-messages="errors"
                      required
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-subscribe_value"
                    name="Jumlah pemakaian berulang"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Jumlah pemakaian berulang
                      <small style="color: #e74c3c"><i>*wajib</i></small>
                    </div>
                    <v-currency-field
                      id="price"
                      solo
                      flat
                      background-color="#EEEEEE"
                      type="number"
                      dense
                      :disabled="form.repeat_bool == false"
                      hide-details
                      color="#d31145"
                      placeholder="Ex: 1"
                      v-model="form.repeat_count"
                      :error-messages="errors"
                      required
                    >
                    </v-currency-field>
                    <div class="teal--text" style="font-size: 12px">
                      Apabila pemakaian berulang YA dan jumlah pemakaian
                      berulang 0, maka akan dianggap pemakaian tidak terbatas
                    </div>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-start_date"
                    name="Tanggal Mulai Promo"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Tanggal Mulai Promo
                      <small style="color: #1abc9c"><i>opsional</i></small>
                    </div>

                    <v-menu
                      v-model="start_date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="FormatStartDate"
                          placeholder="Pilih tanggal mulai promo"
                          solo
                          flat
                          background-color="#EEEEEE"
                          hide-details
                          dense
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        color="#d31145"
                        v-model="form.start_date"
                        @input="start_date = false"
                      ></v-date-picker>
                    </v-menu>
                    <div class="teal--text" style="font-size: 12px">
                      Apabila Tanggal tidak diisi akan dianggap berlaku
                      selamanya
                    </div>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-end_date"
                    name="Tanggal Selesai Promo"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Tanggal Selesai Promo
                      <small style="color: #1abc9c"><i>opsional</i></small>
                    </div>

                    <v-menu
                      v-model="end_date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="FormatEndDate"
                          placeholder="Pilih tanggal selesai promo"
                          solo
                          flat
                          background-color="#EEEEEE"
                          dense
                          hide-details=""
                          readonly
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        color="#d31145"
                        v-model="form.end_date"
                        @input="end_date = false"
                      ></v-date-picker>
                    </v-menu>
                    <div class="teal--text" style="font-size: 12px">
                      Apabila Tanggal tidak diisi akan dianggap berlaku
                      selamanya
                    </div>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="6" class="mt-3 py-0">
                  <v-switch
                    v-for="(pack, i) in form.package_type_array" :key="i"
                    inset
                    class="ml-2"
                    v-model="pack.is_selected_bool"
                    color="#d31145"
                    >
                    <template v-slot:label>
                      <div v-if="pack.value == 'Personal'" class="font-weight-bold black--text">SODAKU</div>
                      <div v-else class="font-weight-bold black--text">SODAPOS {{ pack.value }}</div>
                    </template>
                  </v-switch>
                </v-col>
              </v-row> -->
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="550px">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #d31145"
        >
          <span class="white--text"> Filter </span>
        </v-card-title>
        <v-card-text class="py-3" style="height: 550px">
          <v-select
            dense
            outlined
            v-model="filter.package_type"
            color="#d31145"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan tipe paket"
            :items="[
              { text: 'SODAPOS PRO, LITE & SODAKU', value: 'Semua' },
              { text: 'SODAPOS PRO', value: 'Pro' },
              { text: 'SODAPOS LITE', value: 'Lite' },
              { text: 'SODAKU', value: 'Personal' },
            ]"
          >
          </v-select>
          <v-select
            dense
            outlined
            v-model="filter.status"
            color="#d31145"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan status promo"
            :items="[
              { text: 'Promo Belum Mulai', value: 'Belum Mulai' },
              { text: 'Promo Sedang Berlangsung', value: 'Mulai' },
              { text: 'Promo Sudah Berakhir', value: 'Selesai' },
            ]"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="filterData('filter')"
          >
            Filter
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            color="orange"
            class="white--text text-capitalize"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="dialog.filtered = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important"
            >
              Anda telah berhasil
              {{
                dialog.confirmation_type == "delete"
                  ? "menghapus"
                  : "mengubah status"
              }}
              data promo
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false"
            >
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important"
            >
              Apakah Anda ingin
              {{
                dialog.confirmation_type == "delete"
                  ? "menghapus"
                  : "mengubah status"
              }}
              data promo ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="
              dialog.confirmation = false;
              dialog.confirmation_type = '';
              form.id = '';
            "
          >
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deletePromo()"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG INFORMATION ==== -->
    <v-dialog v-model="dialog.information" width="500">
      <v-card class="border-radius box-shadow">
        <v-card-title></v-card-title>
        <v-card-text>
          <div>
            <b>Berulang</b>
            <p>
              Kode promo dapat digunakan berulang sampai batas yang telah
              ditentukan
            </p>
          </div>
          <div>
            <b>Tidak Berulang</b>
            <p>Kode promo tidak dapat digunakan secara berulang</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>
  </div>
</template>

<script>
import { get, post, exportFile } from "@/service/Axios";
import moment from "moment";
import Pagination from "@/components/Pagination";
import Export from "@/components/Export";
import Empty from "@/components/Empty";

import "@/assets/css/input.css";

export default {
  components: {
    Pagination,
    Export,
    Empty,
  },
  data() {
    return {
      start_date: false,
      end_date: false,
      searching: "",
      feedback: "",
      details: {},
      filter: {
        package_type: "",
        status: "",
      },
      process: {
        run: false,
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      dialog: {
        information: false,
        confirmation: false,
        confirmation_type: "",
        success: false,
        form: false,
        form_type: "",
        filtered: false,
      },
      form: {
        sales_id: "",
        name: "",
        package_object: null,
        discount_array: [],
        desc: "",
        repeat_bool: true,
        repeat_count: 0,
        prefix: "",
        start_date: "",
        end_date: "",
        package_type_array: [],
      },
      refPackage: [],
      packageSelected: {},
      sales: [],
      discount_array: [
        {
          value_percent: 0,
          qty: 0,
          commision_percent: 0,
          commision_calculate_bool: false,
          commision_after_disct_bool: true,
        },
      ],
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options: {},
      pagination: {
        data: {},
        page: "",
      },
      selected: {
        menu: 0,
        pagination: 0,
      },
      headers: [
        {
          text: "Aksi",
          value: "action",
          align: "center",
          width: 130,
        },
        {
          text: "Nama Promo",
          value: "name",
          align: "start",
          width: 250,
        },
        {
          text: "Diskon",
          value: "discount_label",
          align: "center",
          width: 150,
        },
        {
          text: "Sales",
          value: "sales_name",
          align: "start",
          width: 250,
        },
        {
          text: "Tipe Paket",
          value: "package_type",
          align: "center",
          width: 150,
        },
        {
          text: "Total Kode Promo",
          value: "total_code",
          align: "center",
          width: 200,
        },
        {
          text: "Penggunaan Kode",
          value: "use_label",
          align: "center",
          width: 200,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          width: 200,
        },
        {
          text: "Tanggal Promo Berlangsung",
          value: "discount_datetime",
          align: "center",
          width: 350,
        },
      ],
    };
  },
  watch: {
    searching: function (val) {
      this.searching = val;
      this.fetch();
    },
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  computed: {
    FormatStartDate() {
      return this.form.start_date
        ? moment(this.form.start_date).format("DD MMMM YYYY")
        : "";
    },
    FormatEndDate() {
      return this.form.end_date
        ? moment(this.form.end_date).format("DD MMMM YYYY")
        : "";
    },
    computTest() {
      console.log("xxx");
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    textTitlePaketLangganan(data) {
      let titleArr = [];
      titleArr.push(data?.name);
      if (data?.is_active_bool) {
        titleArr.push("[ AKTIF ]");
      } else {
        titleArr.push("[ TIDAK AKTIF ]");
      }
      if (data?.is_trial_bool) {
        titleArr.push("[ TRIAL ]");
      }
      return titleArr.join(" ");
    },
    textSubPaketLangganan(data) {
      const refSubType = {
        Day: "Hari",
        Month: "Bulan",
        Year: "Tahun",
      };
      let text = `${data?.type} ${data?.subscribe_value} ${
        refSubType[data?.subscribe_type]
      }`;
      return text;
    },
    textSubHargaDiskon(data) {
      let price = data?.price;
      if (data?.discount_value > 0) {
        price -= data?.discount_value;
      } else if (data?.discount_percent > 0) {
        price -= (data?.price * data?.discount_percent) / 100;
      }
      return price;
    },
    textNettPrice(discount) {
      if (this.form.package_object?.id) {
        const nettPackagePrice = this.textSubHargaDiskon(
          this.form.package_object
        );
        return (
          nettPackagePrice - (discount.value_percent * nettPackagePrice) / 100
        );
      }
      return 0;
    },
    textMargin(discount) {
      if (this.form.package_object?.id) {
        return (
          this.textNettPrice(discount) - Number(this.form.package_object?.hpp)
        );
      }
      return 0;
    },
    textSelesCommision(discount) {
      if (this.form.package_object?.id) {
        return (this.textMargin(discount) * discount.commision_percent) / 100;
      }
      return 0;
    },
    textNettProfit(discount) {
      if (this.form.package_object?.id) {
        return (
          (this.textMargin(discount) * (100 - discount.commision_percent)) / 100
        );
      }
      return 0;
    },

    resetFilter() {
      this.filter = {
        package_type: "",
        status: "",
      };
      this.filterData("reset");
      return;
    },

    filterData(type) {
      if (type == "reset") {
        this.fetch();
      } else {
        this.fetch();
        this.dialog.filtered = false;
      }
    },

    async fetch(paging) {
      this.process.run = true;

      // console.log(this.options);

      // let sortby = "";
      // let sort = "";

      // if (
      //   this.options.sortBy &&
      //   this.options.sortBy.length > 0
      // ) {
      //   sortby = this.options.sortBy[0];
      // } else {
      //   sortby = "";
      // }

      // if (
      //   this.options.sortDesc &&
      //   this.options.sortDesc.length > 0
      // ) {
      //   if (this.options.sortDesc[0]) {
      //     sort = `-${sortby}`;
      //   } else {
      //     sort = `${sortby}`;
      //   }
      // } else {
      //   sort = "";
      // }

      await get("internal/dashboard/sales/promo", {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          package_type: this.filter.package_type,
          status: this.filter.status,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.items = res.results.data;
          this.totalData = res.results.pagination.total_data;
          this.pagination.data = res.results.pagination;
          this.pagination.page =
            res.results.pagination.total_page < this.page ? 1 : "";
          this.process.run = false;
        }
      });
    },

    async fetchSales() {
      this.process.run = true;

      await get("internal/dashboard/sales", {
        params: {
          pagination_bool: false,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.sales = res.results.data;
          this.process.run = false;
        }
      });
    },

    async fetchPackage() {
      this.process.run = true;

      await get("internal/dashboard/promo", {
        params: {
          pagination_bool: false,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.refPackage = res.results.data;
          this.process.run = false;
        }
      });
    },

    async fetchPackageActivated() {
      this.process.run = true;

      await get("internal/dashboard/sales/package-type", {
        params: {
          pagination_bool: false,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.form.package_type_array = res.results.data;
          this.process.run = false;
        }
      });
    },

    addDiscountArray() {
      this.form.discount_array.push({
        value_percent: 0,
        qty: 0,
        commision_percent: 0,
        commision_calculate_bool: false,
        commision_after_disct_bool: true,
      });
    },

    removeDiscountArray(index) {
      this.form.discount_array.splice(index, 1);
    },

    actions(item, method) {
      switch (method) {
        case "add":
          this.dialog.form = true;
          this.dialog.form_type = "add";
          this.form = {
            sales_id: "",
            name: "",
            package_object: null,
            discount_array: [
              {
                value_percent: 0,
                qty: 0,
                commision_percent: 0,
                commision_calculate_bool: false,
                commision_after_disct_bool: true,
              },
            ],
            desc: "",
            repeat_bool: true,
            repeat_count: 0,
            prefix: "",
            start_date: "",
            end_date: "",
          };
          this.fetchSales();
          this.fetchPackage();
          this.fetchPackageActivated();
          // this.$refs['validation-new-promo'].reset();
          break;
        case "delete":
          this.details = item;
          this.dialog.confirmation = true;
          this.dialog.confirmation_type = "delete";
          break;
        case "export":
          let url = "internal/dashboard/sales/promo/export_excel";
          let params = {
            sales_id: item.sales_id,
          };
          this.exportExcel(url, params, "Data Kode Promo by Sales", "xls");
          break;
      }
    },

    async deletePromo() {
      await post(`internal/dashboard/sales/promo/delete`, {
        data: {
          id: this.details.id,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.dialog.confirmation = false;
            this.dialog.success = true;
            this.fetch();
          } else {
            this.snackbar = {
              show: true,
              color: "red",
              text: res.message ? res.message : "Gagal menghapus data promo",
            };
          }
        })
        .catch((error) => {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Terjadi kendala pada server.",
          };
        });
    },

    async exportExcel(url, params, title, file_type) {
      this.$store.state.loading = true;

      await exportFile("GET", `${url}`, {
        params: params,
      })
        .then((response) => {
          let res = response;
          if (res.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${title}-${moment().format("DMYYYYHHmmss")}.${file_type}`
            );
            document.body.appendChild(link);
            link.click();
            this.$store.state.loading = false;
          } else {
            this.$store.state.loading = false;
            this.error.message = "Gagal mengeksport excel.";
          }
          this.$store.state.loading = false;
        })
        .catch((error) => {
          this.$store.state.loading = false;
        });
      this.$store.state.loading = false;
    },

    async save(type) {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-promo"].validate();

      if (isValid) {
        this.process.run = true;

        let url = "";
        let data = {};
        if (type == "add") {
          url = `internal/dashboard/sales/promo`;
          data = this.form;
          data.package_id = data.package_object?.id;
          delete data.package_object;
        } else {
          url = `internal/dashboard/promo/update`;
          data = this.form;
          data.package_id = data.package_object?.id;
          delete data.package_object;
        }

        await post(url, {
          data,
        })
          .then((response) => {
            let res = response.data;
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-promo"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";

              this.fetch();
            } else {
              if (res.error_code && res.error_code == "error_validation") {
                this.feedback = "Cek kembali form Anda!";

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.run = false;
      }
    },
  },
};
</script>