<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-subheader class="pb-0 pt-5 pl-5" style="height: 10px;">
        <v-btn
          href="javascript:history.back()"
          text
          small
          color="#1B59F8"
          class="text-capitalize">
          <v-icon left>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
      </v-subheader>
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
        DAFTAR OUTLET YANG DIMILIKI ({{ detail_merchant.owner_name }})
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama, email, nomor handphone, kategori outlet"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.owner_name`]="{item}">
              <v-list-item>
                <v-list-item-avatar tile class="rounded-lg">
                  <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="item.owner_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.owner_email" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.store_type`]="{item}">
              <v-chip 
                v-if="item.store_type == 'Lite'"
                outlined
                label
                class="font-weight-bold"
                color="green">
                SODAPOS LITE
              </v-chip>
              <v-chip 
                v-else-if="item.store_type == 'Pro'"
                outlined
                label
                class="font-weight-bold"
                color="red">
                SODAPOS PRO
              </v-chip>
              <v-chip 
                v-else-if="item.store_type == 'Personal'"
                outlined
                label
                class="font-weight-bold"
                color="teal">
                SODAKU
              </v-chip>
              <v-chip 
                v-else-if="item.store_type"
                outlined
                label
                class="font-weight-bold">
                {{ item.store_type }}
              </v-chip>
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.sales_order_total`]="{item}">
              {{ item.sales_order_total | price }}
            </template>

            <template v-slot:[`item.name`]="{item}">
              <b>{{ item.name }}</b>
            </template>

            <template v-slot:[`item.category_name`]="{item}">
              <b>{{ item.category_name }}</b>
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="teal"
                    @click="actions(item,'followup')"
                    icon>
                    <!-- :href="item.wa_link"
                    target="_blank" -->
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                </template>
                <span>Kirim Pesan WA Follow Up</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item,'history')"
                    target="_blank"
                    color="orange"
                    icon>
                    <v-icon>mdi-clipboard-text-clock</v-icon>
                  </v-btn>
                </template>
                <span>Lihat History Aktivasi</span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <v-dialog v-model="dialog.history_activation" persistent scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          HISTORY BERLANGGANAN PADA OUTLET {{ store_name }}
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.history_activation =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 800px;">

          <v-row class="my-3 mb-6">
            <v-col
              cols="12"
              sm="4"
              lg="4"
              md="4"
              xs="12"
              v-for="(item, index) in summary_activation"
              :key="index"
            >
              <v-skeleton-loader
                class="ma-auto"
                height="150"
                :loading="process.run"
                transition="scale-transition"
                :tile="false"
                type="image"
              >
                <v-card 
                  :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
                  class="box-shadow border-radius py-0">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    {{ item.title }}
                    <v-spacer></v-spacer>
                    <v-tooltip left v-if="item.help">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="#D31145"
                          v-bind="attrs"
                          v-on="on"
                          class="cursor-pointer"
                          size="20"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <div v-html="item.help"></div>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text class="subtitle-2">
                    <p
                      class="text-second d-flex align-center"
                      style="font-size: 25px;"
                    >
                      <span class="mr-3 mt-3">
                        {{ item.value }}
                      </span>
                      <v-spacer />
                      <span>
                        <v-icon size="40" :color="item.color">{{
                          item.icon
                        }}</v-icon>
                      </span>
                    </p>
                  </v-card-text>
                </v-card>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers_activation"
            :items="items_activation"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options_activation"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.code`]="{item}">
              {{ item.code }}
            </template>

            <template v-slot:[`item.datetime`]="{item}">
              {{ item.datetime | datetime }}
            </template>

            <template v-slot:[`item.purchase_detail`]="{item}">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.detail.product_name }} - 
                    <span class="text-uppercase font-weight-bold subtitle-2 red--text" v-if="item.detail.product_type  == 'Pro'">{{ item.detail.product_type }}</span>
                    <span class="text-uppercase font-weight-bold subtitle-2 teal--text" v-else-if="item.detail.product_type  == 'Personal'">SODAKU</span>
                    <span class="text-uppercase font-weight-bold subtitle-2 green--text" v-else>{{ item.detail.product_type }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.detail.product_desc }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.status`]="{item}">
                <v-tooltip bottom v-if="item.status == 'Dibatalkan'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip 
                      v-on="on"
                      v-bind="attrs"
                      outlined
                      label
                      class="font-weight-bold"
                      color="red">
                      Dibatalkan
                    </v-chip>
                  </template>
                  <span>Alasan pembatalan : {{ item.cancel_reason }}</span>
                  <div>Dibatalkan pada :{{ item.cancel_datetime | datetime }}</div>
                </v-tooltip>
                <v-chip 
                  v-else-if="item.status == 'Belum Bayar'"
                  outlined
                  label
                  class="font-weight-bold"
                  color="red">
                  Belum Bayar
                </v-chip>
                <v-chip 
                  v-else
                  outlined
                  label
                  class="font-weight-bold"
                  color="green">
                  Terbayar
                </v-chip>
              </template>

            <template v-slot:[`item.product_price`]="{item}">
              {{ item.product_price | price }}
            </template>

            <template v-slot:[`item.discount_value`]="{item}">
              {{ item.discount_value | price }}
            </template>

            <template v-slot:[`item.grand_total`]="{item}">
              {{ item.grand_total | price }}
            </template>

            <template v-slot:[`item.payment_channel`]="{item}">
              <b class="text-uppercase">{{ item.payment_channel }}</b>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit_activation = $event; fetchHistoryActivation()" 
            @changePage="page_activate = $event; fetchHistoryActivation($event)" 
            :model="pagination.activation"
            v-show="pagination.activation.data.total_page > 0"/>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.follow_up" persistent width="800px" scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          KIRIM PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.follow_up =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3" style="height: 500px">

          <div>No Handphone <i class="font-weight-bold">(readonly)</i></div>
          <v-text-field
            outlined
            color="#d21145"
            :readonly="true"
            dense
            :value="store_mobilephone">
          </v-text-field>

          <div class="subtitle-2 mb-2">
            Masukkan text pesan disini
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  size="20"
                  color="orange"
                  @click="dialog.format_whatsapp = true; dialog.follow_up =false;">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Informasi format pesan whatsapp</span>
            </v-tooltip>
          </div>

          <!-- <div class="mb-3">
            <v-btn-toggle
              v-model="filter.template_message"
              mandatory>
              <v-btn
                height="30"
                v-for="(item, i) in items_template" :key="i" 
                :value="item.slug"
                :color="item.slug == filter.template_message ? '#d31145' : ''"
                small
                :class="item.slug == filter.template_message ? 'white--text text-uppercase font-weight-bold' : 'text-uppercase'">
                {{ item.title }}
              </v-btn>
            </v-btn-toggle>
          </div> -->

          <div class="mb-3">
            <v-slide-group
              v-model="filter.template_message"
              show-arrows>
              <v-slide-item
                v-for="(item, i) in items_template" :key="i" 
                v-slot="{ active, toggle }"
                :value="item.slug">
                <v-btn
                  class="mx-2"
                  small
                  :input-value="active"
                  active-class="red--text"
                  depressed
                  rounded
                  @click="toggle"
                  :class="item.slug == filter.template_message ? 'white--text text-uppercase font-weight-bold' : 'text-uppercase'">
                  {{ item.title }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </div>

          <v-textarea
            outlined
            rows="10"
            dense
            color="#d31145"
            v-model="whatsapp_text">
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            elevation="0"
            @click="dialog.follow_up = false">
            Batal
          </v-btn>
          <v-btn
            class="text-capitalize white--text"
            color="#D31145"
            elevation="0"
            @click="sendWhatsappMessage">
            Kirim Pesan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.format_whatsapp" persistent width="1000px">
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          INFORMASI FORMAT PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.format_whatsapp =false; dialog.follow_up = true;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3">
          
          <ol>
            <li v-for="(format, i) in whatsapp_format" :key="i">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ format.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{format.subtitle}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle v-html="format.hasil"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </li>
          </ol>

          <p>Sesuai pada FAQ Whatsapp di halaman <a href="https://faq.whatsapp.com/539178204879377/?helpref=uf_share" target="_blank">Whatsapp FAQ</a></p>

        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { get } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import Editor from '@tinymce/tinymce-vue'
export default {
  components: {
    Empty,
    Pagination,
    'editor': Editor
  },
  data () {
    return {
      searching: "",
      whatsapp_text: "",
      detail_merchant: {},
      detail_outlet: {},
      summary_activation: [],
      filter: {
        template_message: ""
      },
      process: {
        run: false
      },
      dialog: {
        history_activation: false,
        follow_up: false,
        format_whatsapp: false,
      },
      whatsapp_format: [
        {
          title: "Tulisan Miring",
          subtitle: "_text_",
          hasil: "<i>text</i>",
        },
        {
          title: "Tulisan Tebal",
          subtitle: "*text*",
          hasil: "<b>text</b>",
        },
        {
          title: "Tulisan Dicoret",
          subtitle: "~text~",
          hasil: "<s>text</s>",
        },
        {
          title: "Nama Pemilik",
          subtitle: "{owner_name}",
          hasil: "memunculkan nama Pemilik pada pesan Anda",
        },
        {
          title: "Nama Outlet",
          subtitle: "{outlet_name}",
          hasil: "memunculkan nama Outlet pada pesan Anda",
        },
        {
          title: "Alamat Outlet",
          subtitle: "{outlet_address}",
          hasil: "memunculkan alamat outlet pada pesan Anda",
        },
        {
          title: "Tanggal Expired Outlet",
          subtitle: "{expired_date}",
          hasil: "memunculkan text tanggal expired sesuai outlet pada pesan Anda",
        },
      ],
      // DATA TABLES
      store_id: "",
      store_name: "",
      store_mobilephone: "",
      items_template: [],
      items: [],
      // * Pagination activation
      items_activation: [],
      limit_activation: 10,
      page_ctivation: 1,
      options_activation:{},
      // * Pagination activation end

      limit: 10,
      page: 1,
      options:{},
      pagination: {
        activation: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        activation: {
          menu: 0,
          pagination: 0  
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        },
        {
          text: 'Nama pemilik',
          value: 'owner_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Outlet',
          value: 'name',
          align: 'start',
          width: 200
        },
        {
          text: 'Kategori Outlet',
          value: 'category_name',
          align: 'start',
          width: 200
        },
        {
          text: 'No. Handphone',
          value: 'mobilephone',
          align: 'center',
          width: 150
        },
        {
          text: 'Alamat',
          value: 'address',
          align: 'start',
          width: 250
        },
        {
          text: 'Tipe Berlangganan',
          value: 'store_type',
          align: 'center',
          width: 200
        },
        {
          text: 'Omset Outlet',
          value: 'sales_order_total',
          align: 'end',
          width: 200
        },
      ],
      headers_activation: [
        {
          text: 'Tanggal',
          value: 'datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Kode Pembayaran',
          value: 'code',
          align: 'center',
          width: 200
        },
        {
          text: 'Pembelian Langganan',
          value: 'purchase_detail',
          align: 'start',
          width: 200
        },
        {
          text: 'Metode Pembayaran',
          value: 'payment_channel',
          align: 'start',
          width: 250
        },
        {
          text: 'Status Berlangganan',
          value: 'status',
          align: 'center',
          width: 200
        },
        {
          text: 'Harga',
          value: 'product_price',
          align: 'end',
          width: 200
        },
        {
          text: 'Diskon',
          value: 'discount_value',
          align: 'end',
          width: 200
        },
        {
          text: 'Total pembayaran',
          value: 'grand_total',
          align: 'end',
          width: 200
        }
      ],
    }
  },
  watch: {
    'filter.template_message': function(val) {
      this.setMessageWhatsApp(val)
    },

    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
    

    // * Activation Option
    'selected.activation.pagination': function(val) {
      this.fetchHistoryActivation(val+1)
    },
    options_activation:{
      handler(){
        this.fetchHistoryActivation()
      },
      deep:true
    },
  },
  mounted() {
    this.fetch()
    this.fetchDetailMerchant()
    this.fetchTemplate()
    // this.format_text()
  },
  methods: {
    actions(item, type) {
      switch (type) {
        case 'history':
          this.store_id = item.id
          this.store_name = item.name
          this.dialog.history_activation = true
          this.fetchHistoryActivation()
          this.fetchSummaryActivation()
          break;
        case 'followup':
          this.store_mobilephone = this.$options.filters.wa_number(item.mobilephone)
          this.detail_outlet = item
          this.dialog.follow_up = true
          this.fetchTemplate()
          break;
      }
    },
    setMessageWhatsApp(slug) {
      this.items_template.forEach((template) => {
        if (slug == template.slug) {
          return this.whatsapp_text = template.messages
        }
      })
    },
    format_text(text){
      return text.replace(`{expired_date}`,`${this.$options.filters.datetimeFull(this.detail_outlet.expired_datetime)}`)
      .replace(`{owner_name}`,`${this.detail_outlet.owner_name}`)
      .replace(`{outlet_name}`,`${this.detail_outlet.name}`)
      .replace(`{outlet_address}`,`${this.detail_outlet.address}`)
      
      // .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
      // .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
      // .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
      // .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>');
    },
    sendWhatsappMessage() {
      let message = this.format_text(this.whatsapp_text)
      let link = encodeURI(`https://api.whatsapp.com/send?phone=${this.store_mobilephone}&text=${message}`)
      // let link = encodeURI(`https://api.whatsapp.com/send?phone=62895392685163&text=${message}`)
      window.open(link)
    },
    async fetchDetailMerchant() {
      this.process.run = true

      await get('internal/dashboard', {
        params: {
          id: this.$route.params.id,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.detail_merchant = res.results.data[0]
        }
      })
    },
    async fetchTemplate() {
      // this.process.run = true

      await get('internal/dashboard/wa_template', {})
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_template = res.results.data
          this.filter.template_message = res.results.data[0].slug
          // this.setMessageWhatsApp(res.results.data[0].slug)
        }
      })
      this.process.run = false
    },
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/store', {
        params: {
          page: paging,
          limit: this.limit,
          merchant_id: this.$route.params.id,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },
    async fetchHistoryActivation(paging) {
      await get('internal/dashboard/store/activation_history', {
        params: {
          page: paging,
          limit: this.limit_activation,
          store_id: this.store_id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_activation = res.results.data
          this.pagination.activation.data = res.results.pagination
          this.pagination.activation.page = res.results.pagination.total_page < this.page ? 1 : ""
        }
      })
    },
    async fetchSummaryActivation() {

      await get('internal/dashboard/store/activation_history/summary', {
        params: {
          store_id: this.store_id,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          let detail = res.results.data
          this.summary_activation = [
            {
              title: "Total Pembelian Berhasil",
              value: this.$options.filters.num_format(detail.total_transaction),
              help: "",
              color: "green",
              icon: "mdi-cash-check"
            },
            {
              title: "Total Harga Pembelian",
              value: this.$options.filters.price(detail.total_product_price),
              help: "",
              color: "primary",
              icon: "mdi-cash"
            },
            {
              title: "Total Diskon Pembelian",
              value: this.$options.filters.price(detail.total_discount_value),
              help: "",
              color: "red",
              icon: "mdi-tag-text"
            },
            {
              title: "Total Nett Pembelian",
              value: this.$options.filters.price(detail.total_nett_price),
              help: "",
              color: "purple",
              icon: "mdi-cash-plus"
            },
            {
              title: "Total Admin Fee",
              value: this.$options.filters.price(detail.total_admin_fee),
              help: "",
              color: "deep-orange",
              icon: "mdi-cash-minus"
            },
            {
              title: "Grand Total Pembayaran",
              value: this.$options.filters.price(detail.grand_total),
              help: "",
              color: "teal",
              icon: "mdi-cash-multiple"
            },
          ]
        }
      })
    },
  }
}
</script>