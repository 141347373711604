<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        <div>
          DAFTAR KOMISI SALES
          <div class="mt-1">
            <Export
              class="mr-2"
              :disabled="process.run"
              :file_type="'xls'"
              :btnText="'Export Excel'"
              :btnIcon="'mdi-microsoft-excel'"
              :btnColor="'teal'"
              :url="'internal/dashboard/sales/commission/group/export_excel'"
              :title="'Data Komisi Sales'"
              ref="base-export-excel"
            />
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama sales, kode sales"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0" v-if="items.length > 0">
          <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer" >
            SUMMARY
            <v-spacer></v-spacer>
            <!-- <v-btn
              text
              elevation="0"
              class="text-capitalize pink--text"
              small>
              {{ is_show_summary ? "Sembunyikan Summary" : "Lihat Summary" }}
              <v-icon right>{{ is_show_summary ? "mdi-arrow-up" : "mdi-arrow-down"}}</v-icon>
            </v-btn> -->
          </v-card-title>
          <v-expand-transition>
            <v-row class="mb-2" v-show="is_show_summary">
              <v-col
                cols="12"
                sm="4"
                lg="4"
                md="4"
                xs="12"
                v-for="(item, index) in summaryCommissionSales"
                :key="index"
              >
                <v-skeleton-loader
                  class="ma-auto"
                  height="100"
                  :loading="process.run"
                  transition="scale-transition"
                  :tile="false"
                  type="image"
                >
                  <v-card 
                    :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                    class="box-shadow border-radius py-0">
                    <v-card-title class="subtitle-1 font-weight-bold">
                      {{ item.label }}
                      <v-spacer></v-spacer>
                      <v-tooltip left v-if="item.help">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="#D31145"
                            v-bind="attrs"
                            v-on="on"
                            class="cursor-pointer"
                            size="20"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <div v-html="item.help"></div>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text class="subtitle-2">
                      <p
                        class="text-second d-flex align-center"
                        style="font-size: 25px;">
                        <span class="mr-3 mt-3">
                          {{ item.value }}
                        </span>
                        <v-spacer />
                        <span>
                          <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                        </span>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.commission`]="{item}">
              {{ item.commission }}
              <v-btn 
                @click="actions('sales_commission', item)"
                text
                small
                color="#0D47A1"
                class="subtitle-2 font-weight-normal text-capitalize">
                Lihat
              </v-btn>
            </template>

            <template v-slot:[`item.paid_commission`]="{item}">
              {{ item.paid_commission }}
            </template>

            <template v-slot:[`item.balance`]="{item}">
              {{ item.balance }}
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetchCommissionGroupBySales()" 
            @changePage="page = $event; fetchCommissionGroupBySales($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.success_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun intenal ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteAccount()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

    <v-dialog v-model="dialog.commission_sales" persistent scrollable fullscreen>
      <v-card class="border-radius box-shadow">
        <v-card-title class="subtitle-1">
          Data Komisi Sales {{ details ? details.sales_name : '-' }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.commission_sales = false; sales_id = ''">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="caption">
          Data Komisi yang didapatkan
          <Export
            class="mr-2"
            :disabled="process.run"
            :file_type="'xls'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="`internal/dashboard/sales/commission/export_excel?sales_id=${sales_id}`"
            :title="'Data Komisi Sales'"
            ref="base-export-excel"
          />
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pt-2">
          
          <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0" v-if="itemsCommission.length > 0">
            <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer" >
              SUMMARY
              <v-spacer></v-spacer>
              <!-- <v-btn
                text
                elevation="0"
                class="text-capitalize pink--text"
                small>
                {{ is_show_summary ? "Sembunyikan Summary" : "Lihat Summary" }}
                <v-icon right>{{ is_show_summary ? "mdi-arrow-up" : "mdi-arrow-down"}}</v-icon>
              </v-btn> -->
            </v-card-title>
            <v-expand-transition>
              <v-row class="mb-2" v-show="is_show_summary">
                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                  xs="12"
                  v-for="(item, index) in summaryCommission"
                  :key="index"
                >
                  <v-skeleton-loader
                    class="ma-auto"
                    height="100"
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    type="image"
                  >
                    <v-card 
                      :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                      class="box-shadow border-radius py-0">
                      <v-card-title class="subtitle-1 font-weight-bold">
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip left v-if="item.help">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#D31145"
                              v-bind="attrs"
                              v-on="on"
                              class="cursor-pointer"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div v-html="item.help"></div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text class="subtitle-2">
                        <p
                          class="text-second d-flex align-center"
                          style="font-size: 25px;">
                          <span class="mr-3 mt-3">
                            {{ item.value }}
                          </span>
                          <v-spacer />
                          <span>
                            <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                          </span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-card>

          <div v-if="itemsCommission.length > 0" class="mt-5">

            <v-data-table
            :headers="headersCommission"
            :items="itemsCommission"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="optionsCommission"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.promo_name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.promo_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="`Nama Paket : ${item.package_name}`" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.store_name`]="{item}">
              <v-list-item class="px-0" three-line>
                <v-list-item-content>
                  <v-list-item-title v-html="item.store_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="`Owner : ${item.owner_name}`" class="caption"></v-list-item-subtitle>
                  <v-list-item-subtitle class="caption">
                    <div class="font-weight-bold" :class="item.package_subscribe_type == 'Year' ? 'primary--text' : item.package_subscribe_type == 'Month' ? 'purple--text' : 'pink--text'">
                      Aktivasi SODAPOS <span class="text-uppercase">{{ item.package_type }} </span> ({{ item.package_subscribe_label }})
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.package_discount_value`]="{item}">
              {{ item.package_discount_value | price }}
            </template>

            <template v-slot:[`item.commission_percent`]="{item}">
              {{ item.commission_percent | percent }}
            </template>
            
            <template v-slot:[`item.commission_nominal`]="{item}">
              <div class="font-weight-bold black--text">{{ item.commission_nominal }}</div>
            </template>

            <template v-slot:[`item.package_sales_promo_disc_value`]="{item}">
              {{ item.package_sales_promo_disc_value | price }}
            </template>

            <template v-slot:[`item.package_type`]="{item}">
              <div class="font-weight-bold" :class="item.package_subscribe_type == 'Year' ? 'primary--text' : item.package_subscribe_type == 'Month' ? 'purple--text' : 'pink--text'">
                Aktivasi SODAPOS <span class="text-uppercase">{{ item.package_type }} </span> <br>
                ({{ item.package_subscribe_label }})
              </div>
            </template>

            <template v-slot:[`item.commission_after_disct_bool`]="{item}">
              <div :class="item.commission_after_disct_bool ? 'teal--text' : 'red--text'" class="font-weight-bold">
                 {{ item.commission_after_disct_bool ? "Ya" : "Tidak" }}
              </div>
            </template>

          </v-data-table>

            <Pagination 
              @changeLimit="limitCommission = $event; fetchSalesCommission()" 
              @changePage="pageCommission = $event; fetchSalesCommission($event)" 
              :model="pagination.commission"
              v-show="pagination.commission.data.total_page > 0"/>
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
          </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Empty,
    Export,
  },
  data () {
    return {
      searching: "",
      is_show_summary: true,
      details: {},
      summaryCommission: [],
      summaryCommissionSales: [],
      process: {
        run: false
      },
      dialog: {
        confirmation: false,
        confirmation_type: "",
        success: false,
        success_type: "",
        commission_sales: false,
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      // DATA TABLES
      sales_id: "",
      items: [],
      limit: 10,
      page: 1,
      options:{},

      itemsCommission: [],
      limitCommission: 10,
      pageCommission: 1,
      optionsCommission:{},

      pagination: {
        commission: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        commission: {
          pagination: 0
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Kode Sales',
          value: 'code',
          align: 'center',
          width: 100
        },
        {
          text: 'Nama Sales',
          value: 'name',
          align: 'start',
          width: 150
        },
        {
          text: 'Total Komisi didapat',
          value: 'commission',
          align: 'end',
          width: 200
        },
        {
          text: 'Total Komisi sudah diambil',
          value: 'paid_commission',
          align: 'end',
          width: 200
        },
        {
          text: 'Total Saldo Komisi',
          value: 'balance',
          align: 'end',
          width: 200
        },
      ],
      headersCommission: [
        {
          text: 'Tanggal Transaksi',
          value: 'transaction_datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Kode Promo',
          value: 'promo_code',
          align: 'center',
          width: 150
        },
        {
          text: 'Nama Promo',
          value: 'promo_name',
          align: 'start',
          width: 150
        },
        {
          text: 'Nama Toko',
          value: 'store_name',
          align: 'start',
          width: 250
        },
        {
          text: 'Harga Paket',
          value: 'package_price',
          align: 'end',
          width: 150
        },
        {
          text: 'Diskon Paket',
          value: 'package_discount_value',
          align: 'end',
          width: 150
        },
        {
          text: 'Diskon Promo Sales',
          value: 'package_sales_promo_disc_value',
          align: 'end',
          width: 150
        },
        {
          text: 'Total Bayar Merchant',
          value: 'grand_total',
          align: 'end',
          width: 200
        },
        {
          text: 'Komisi Persen Sales',
          value: 'commission_percent',
          align: 'end',
          width: 150
        },
        {
          text: 'Dihitung Setelah Diskon',
          value: 'commission_after_disct_bool',
          align: 'center',
          width: 150
        },
        {
          text: 'Total Komisi Sales',
          value: 'commission_nominal',
          align: 'end',
          width: 150
        },
      ]
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetchCommissionGroupBySales()
    },
    'selected.pagination': function(val) {
      this.fetchCommissionGroupBySales(val+1)
    },
    options:{
      handler(){
        this.fetchCommissionGroupBySales()
      },
      deep:true
    },

     // SALES COMMISSION
    'selected.commission.pagination': function(val) {
      this.fetchSalesCommission(val+1)
    },
    optionsCommission:{
      handler(){
        this.fetchSalesCommission()
      },
      deep:true
    },
  },
  mounted() {
    this.fetchCommissionGroupBySales()
    this.fetchSalesCommissionSummary()
  },
  methods: {
    actions(type, item) {
      switch (type) {
        case "sales_commission":
          this.sales_id = item.id
          this.dialog.commission_sales = true
          this.fetchSalesCommission()
          break;
      }  
    },

    async fetchCommissionGroupBySales(paging) {
      this.process.run = true

      await get('internal/dashboard/sales/commission/group', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })

      this.process.run = false
    },

    async fetchSalesCommissionSummary() {
      await get('internal/dashboard/sales/commission/summary')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.summaryCommissionSales = res.results.data
        }
      })
    },

    async fetchSalesCommission(paging) {
      this.process.run = true

      await get('internal/dashboard/sales/commission', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          sales_id: this.sales_id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.itemsCommission = res.results.data
          this.details = res.results.data[0]
          this.totalData = res.results.pagination.total_data
          this.pagination.commission.data = res.results.pagination
          this.pagination.commission.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
          this.fetchSalesCommissionSummaryBySales()
        }
      })

      this.process.run = false
    },

    async fetchSalesCommissionSummaryBySales() {
      await get('internal/dashboard/sales/commission/summary', {
        params: {
           sales_id: this.sales_id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.summaryCommission = res.results.data
        }
      })
    },
  }
}
</script>