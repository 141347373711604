<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        <div>
          DAFTAR OUTLET
          <v-btn
            small
            elevation="0"
            color="#d31145"
            class="white--text ml-3"
            v-if="user.id == '1'"
            @click="openManualActivationDialog">
            Buat Perpanjangan Manual
          </v-btn>
          <div class="mt-3">
            <v-btn-toggle
              v-model="filter.storeType"
              mandatory>
              <v-btn
                height="30"
                v-for="(item, i) in [{text: 'SEMUA OUTLET', value: ''}, {text: 'SODAPOS PRO', value: 'Pro'}, {text: 'SODAPOS LITE', value: 'Lite'}, {text: 'SODAKU', value: 'Personal'}]" :key="i" 
                :value="item.value"
                :color="item.value == filter.storeType ? '#d31145' : ''"
                small
                :class="item.value == filter.storeType ? 'white--text text-uppercase font-weight-bold' : 'text-uppercase'">
                {{ item.text }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex justify-center mt-5">
          <v-text-field
            outlined
            dense
            color="#D31145"
            style="width: 300px;"
            placeholder="Cari berdasarkan nama, email, nomor handphone, kategori outlet"
            class="font-weight-normal"
            v-model="searching">
          </v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1 ml-3"
                v-on="on"
                v-bind="attrs"
                icon
                @click="dialog.filtered = true">
                <v-icon size="25">mdi-filter-outline</v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text>

        <div>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-avatar tile class="rounded-lg">
                  <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.name }} <strong class="red--text">{{ item.is_trial_bool == true ? '(Trial)' : '' }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle v-html="item.category_name" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.owner_name`]="{item}">
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.owner_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.owner_email" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.store_type`]="{item}">
              <v-chip 
                v-if="item.store_type == 'Lite'"
                outlined
                label
                class="font-weight-bold"
                color="green">
                SODAPOS LITE
              </v-chip>
              <v-chip 
                v-else-if="item.store_type == 'Pro'"
                outlined
                label
                class="font-weight-bold"
                color="red">
                SODAPOS PRO
              </v-chip>
              <v-chip 
                v-else-if="item.store_type == 'Personal'"
                outlined
                label
                class="font-weight-bold"
                color="teal">
                SODAKU
              </v-chip>
              <v-chip 
                v-else-if="(item.store_type == '' && item.is_trial_bool)"
                outlined
                label
                class="font-weight-bold"
                color="green">
                SODAPOS LITE
              </v-chip>
              <v-chip 
                v-else
                outlined
                label
                class="font-weight-bold"
                color="">
                {{ item.store_type ? item.store_type : "-" }}
              </v-chip>
            </template>

            <template v-slot:[`item.sales_order_total`]="{item}">
              {{ item.sales_order_total | price }}
            </template>

            <template v-slot:[`item.expired_datetime`]="{item}">
              {{ item.expired_datetime | datetime }}
            </template>

            <template v-slot:[`item.category_name`]="{item}">
              <b>{{ item.category_name }}</b>
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="teal"
                    @click="actions(item,'followup')"
                    icon>
                    <!-- :href="item.wa_link"
                    target="_blank" -->
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                </template>
                <span>Kirim Pesan WA Follow Up</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item,'history')"
                    target="_blank"
                    color="orange"
                    icon>
                    <v-icon>mdi-clipboard-text-clock</v-icon>
                  </v-btn>
                </template>
                <span>Lihat History Aktivasi</span>
              </v-tooltip>

            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="550px">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #d31145;"
        >
          <span class="white--text">
            Filter
          </span>
        </v-card-title>
        <v-card-text class="py-3" style="height: 550px;">
           <v-select
            dense
            outlined
            v-model="filter.status"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan status outlet"
            color="#d31145"
            :items="status_filters">
          </v-select>
           <!-- <v-select
            dense
            outlined
            v-model="filter.is_trial_bool"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan outlet masa trial"
            :items="[{text: 'Outlet dalam masa trial', value: true}, {text: 'Outlet dalam masa berlangganan', value: false}]">
          </v-select>
          <v-select
            dense
            outlined
            v-model="filter.is_expired_bool"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan outlet expired"
            :items="[{text: 'Outlet sudah expired', value: true}, {text: 'Outlet belum expired', value: false}]">
          </v-select> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="fetch()"
          >
            Filter
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            color="orange"
            class="white--text text-capitalize"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="dialog.filtered = false;"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG AKTIVASI -->
    <v-dialog v-model="dialog.history_activation" persistent scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          HISTORY BERLANGGANAN PADA OUTLET {{ store_name }}
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.history_activation =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 800px;">

          <v-row class="my-3 mb-6">
            <v-col
              cols="12"
              sm="4"
              lg="4"
              md="4"
              xs="12"
              v-for="(item, index) in summary_activation"
              :key="index"
            >
              <v-skeleton-loader
                class="ma-auto"
                height="150"
                :loading="process.run"
                transition="scale-transition"
                :tile="false"
                type="image"
              >
                <v-card 
                  :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
                  class="box-shadow border-radius py-0">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    {{ item.title }}
                    <v-spacer></v-spacer>
                    <v-tooltip left v-if="item.help">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="#D31145"
                          v-bind="attrs"
                          v-on="on"
                          class="cursor-pointer"
                          size="20"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <div v-html="item.help"></div>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text class="subtitle-2">
                    <p
                      class="text-second d-flex align-center"
                      style="font-size: 25px;"
                    >
                      <span class="mr-3 mt-3">
                        {{ item.value }}
                      </span>
                      <v-spacer />
                      <span>
                        <v-icon size="40" :color="item.color">{{
                          item.icon
                        }}</v-icon>
                      </span>
                    </p>
                  </v-card-text>
                </v-card>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="items.length > 0">
            <v-data-table
              :headers="headers_activation"
              :items="items_activation"
              disable-sort
              hide-default-footer
              disable-pagination 
              :options.sync="options_activation"
              :loading="process.run"
              loading-text="Sedang memuat"
              no-data-text="Data tidak tersedia"
              no-results-text="Data tidak tersedia"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              fixed-header
              flat
              class="elevation-0 rounded-lg">

              <template v-slot:[`item.code`]="{item}">
                {{ item.code }}
              </template>

              <template v-slot:[`item.datetime`]="{item}">
                {{ item.datetime | datetime }}
              </template>

              <template v-slot:[`item.purchase_detail`]="{item}">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.detail.product_name }} - 
                      <span class="text-uppercase font-weight-bold subtitle-2 red--text" v-if="item.detail.product_type  == 'Pro'">{{ item.detail.product_type }}</span>
                      <span class="text-uppercase font-weight-bold subtitle-2 teal--text" v-else-if="item.detail.product_type  == 'Personal'">SODAKU</span>
                      <span class="text-uppercase font-weight-bold subtitle-2 green--text" v-else>{{ item.detail.product_type }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.detail.product_desc }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.status`]="{item}">
                <v-tooltip bottom v-if="item.status == 'Dibatalkan'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip 
                      v-on="on"
                      v-bind="attrs"
                      outlined
                      label
                      class="font-weight-bold"
                      color="red">
                      Dibatalkan
                    </v-chip>
                  </template>
                  <span>Alasan pembatalan : {{ item.cancel_reason }}</span>
                  <div>Dibatalkan pada :{{ item.cancel_datetime | datetime }}</div>
                </v-tooltip>
                <v-chip 
                  v-else-if="item.status == 'Belum Bayar'"
                  outlined
                  label
                  class="font-weight-bold"
                  color="red">
                  Belum Bayar
                </v-chip>
                <v-chip 
                  v-else
                  outlined
                  label
                  class="font-weight-bold"
                  color="green">
                  Terbayar
                </v-chip>
              </template>

              <template v-slot:[`item.product_price`]="{item}">
                {{ item.product_price | price }}
              </template>

              <template v-slot:[`item.discount_value`]="{item}">
                {{ item.discount_value | price }}
              </template>

              <template v-slot:[`item.grand_total`]="{item}">
                {{ item.grand_total | price }}
              </template>

              <template v-slot:[`item.payment_channel`]="{item}">
                <b class="text-uppercase">{{ item.payment_channel }}</b>
              </template>

            </v-data-table>

            <Pagination 
              @changeLimit="limit_activation = $event; fetchHistoryActivation()" 
              @changePage="page_activate = $event; fetchHistoryActivation($event)" 
              :model="pagination.activation"
              v-show="pagination.activation.data.total_page > 0"/>
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
          </div>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG WHATSAPP -->
    <v-dialog v-model="dialog.follow_up" persistent width="800px" scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          KIRIM PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.follow_up =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3" style="height: 500px">

          <div>No Handphone <i class="font-weight-bold">(readonly)</i></div>
          <v-text-field
            outlined
            color="#d21145"
            :readonly="true"
            dense
            :value="store_mobilephone">
          </v-text-field>

          <div class="subtitle-2 mb-2">
            Masukkan text pesan disini
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  size="20"
                  color="orange"
                  @click="dialog.format_whatsapp = true; dialog.follow_up =false;">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Informasi format pesan whatsapp</span>
            </v-tooltip>
          </div>

          <div class="mb-3">
            <v-slide-group
              v-model="filter.template_message"
              show-arrows>
              <v-slide-item
                v-for="(item, i) in items_template" :key="i" 
                v-slot="{ active, toggle }"
                :value="item.slug">
                <v-btn
                  class="mx-2"
                  small
                  :input-value="active"
                  active-class="red--text"
                  depressed
                  rounded
                  @click="toggle"
                  :class="item.slug == filter.template_message ? 'white--text text-uppercase font-weight-bold' : 'text-uppercase'">
                  {{ item.title }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </div>

          <v-textarea
            outlined
            rows="10"
            dense
            color="#d31145"
            v-model="whatsapp_text">
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            elevation="0"
            @click="dialog.follow_up = false">
            Batal
          </v-btn>
          <v-btn
            class="text-capitalize white--text"
            color="#D31145"
            elevation="0"
            @click="sendWhatsappMessage">
            Kirim Pesan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG WHATSAPP FORMAT MESSAGE -->
    <v-dialog v-model="dialog.format_whatsapp" persistent width="1000px">
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          INFORMASI FORMAT PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.format_whatsapp =false; dialog.follow_up = true;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3">
          
          <ol>
            <li v-for="(format, i) in whatsapp_format" :key="i">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ format.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{format.subtitle}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle v-html="format.hasil"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </li>
          </ol>

          <p>Sesuai pada FAQ Whatsapp di halaman <a href="https://faq.whatsapp.com/539178204879377/?helpref=uf_share" target="_blank">Whatsapp FAQ</a></p>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG PERPANJANGAN MANUAL -->
    <v-dialog v-model="dialog.manual_activation" persistent width="600px" scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          PERPANJANGAN MANUAL
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.manual_activation =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3">
          <ValidationObserver ref="validation-new-activation">

            <ValidationProvider
              ref="form-id"
              name="Pilih Outlet"
              rules="required"
              v-slot="{ errors }">
              <!-- v-model="form.id" -->
              <v-autocomplete
                :items="store"
                :value="form.id"
                item-text="name"
                item-value="id"
                label="Pilih Outlet"
                placeholder="Pilih Outlet"
                outlined
                dense
                color="#d31145"
                class="mt-3"
                :error-messages="errors"
                required
                @change="onChangeStore"
                return-object
                >
                <template v-slot:item="data">
                  <template v-if="Object.keys(data.item).length < 1">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content style="border-bottom: 1px solid #ddd">
                      <v-list-item-title v-html="`${data.item.name} (${data.item.store_type ? (data.item.store_type == 'Personal' ? 'Sodaku' : data.item.store_type) : 'Lite'})`" class="font-weight-bold subtitle-2"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.owner_name"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </ValidationProvider>
  
            
            <div class="font-weight-bold">Ubah Paket Langganan</div>
            <v-row>
              <v-col>
                <v-switch
                  inset
                  color="#d31145"
                  v-model="form.package_bool"
                  :label="form.package_bool ? `Ubah` : 'Tetap'"
                ></v-switch>
              </v-col>
              <v-col>
                <ValidationProvider
                  ref="form-package_id"
                  name="Pilih Paket Langganan"
                  :rules="form.package_bool ? 'required' : ''"
                  v-slot="{ errors }">
                  <v-autocomplete
                    :items="list_store_package"
                    v-model="form.package_id"
                    :disabled="!form.package_bool"
                    item-text="name"
                    item-value="id"
                    label="Pilih Paket Langganan"
                    placeholder="Pilih Paket Langganan"
                    outlined
                    dense
                    color="#d31145"
                    :error-messages="errors"
                    required>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
  
            <div class="font-weight-bold">Ubah Tanggal Berakhir</div>
            <v-row>
              <v-col>
                <v-switch
                  inset
                  color="#d31145"
                  v-model="form.expired_datetime_bool"
                  :label="form.expired_datetime_bool ? `Ubah` : 'Tetap'"
                ></v-switch>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      ref="form-expired_datetime"
                      name="Pilih Tanggal Berakhir"
                      :rules="form.expired_datetime_bool ? 'required' : ''"
                      v-slot="{ errors }">
                      <v-text-field
                        :value="computedDateFormattedMomentjs"
                        clearable
                        outlined
                        dense
                        color="#d31145"
                        label="Pilih Tanggal Berakhir"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="form.expired_datetime = null"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="form.expired_datetime"
                    color="#d31145"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-content-space-between align-center">
              <v-col class="">
                <div class="font-weight-bold">Ubah Harga Langganan 
                  <span class="font-italic green--text font-weight-bold">(opsional)</span> 
                </div>
              </v-col>
              <v-col class="">
                <v-switch
                  inset
                  color="#d31145"
                  v-model="form.change_price_bool"
                  :label="form.change_price_bool ? `Ubah` : 'Tetap'"
                ></v-switch>
              </v-col>
            </v-row>

            <div v-show="form.change_price_bool">
              <div class="subtitle-2 font-weight-bold">
                Harga Langganan Baru
              </div>
              <v-currency-field
                id="price"
                solo
                flat
                background-color="#EEEEEE"
                type="number"
                dense
                color="#d31145"
                placeholder="Ex: 1.500.000"
                v-model="form.price">
              </v-currency-field>


            </div>

            <v-divider></v-divider>

            <h3 class="mt-3">Informasi Bank</h3>
            <p>Digunakan untuk menginformasikan pembayaran langganan melalui bank dibawah .</p>

            <div>
              <div class="subtitle-2 font-weight-bold">
                Nama Bank
              </div>
              <v-text-field
                solo
                flat
                background-color="#EEEEEE"
                counter
                maxlength="50"
                dense
                color="#d31145"
                placeholder="Tuliskan nama bank"
                v-model="form.bank_name"
                >
              </v-text-field>
            </div>
            <div>
              <div class="subtitle-2 font-weight-bold">
                Cabang Bank
              </div>
              <v-text-field
                solo
                flat
                background-color="#EEEEEE"
                counter
                maxlength="50"
                dense
                color="#d31145"
                placeholder="Tuliskan cabang bank"
                v-model="form.bank_branch"
                >
              </v-text-field>
            </div>
            <div>
              <div class="subtitle-2 font-weight-bold">
                Nama Akun Bank
              </div>
              <v-text-field
                solo
                flat
                background-color="#EEEEEE"
                counter
                maxlength="50"
                dense
                color="#d31145"
                placeholder="Tuliskan nama akun bank"
                v-model="form.bank_account_name"
                >
              </v-text-field>
            </div>
            <div>
              <div class="subtitle-2 font-weight-bold">
                Nomor Rekening Bank
              </div>
              <v-text-field
                solo
                flat
                background-color="#EEEEEE"
                counter
                maxlength="50"
                dense
                color="#d31145"
                placeholder="Tuliskan nomor rekening bank"
                v-model="form.bank_account_number"
                >
              </v-text-field>
            </div>
            
          </ValidationObserver>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            elevation="0"
            :disabled="process.manual_activation"
            @click="dialog.manual_activation = false">
            Batal
          </v-btn>
          <v-btn
            class="text-capitalize white--text"
            color="#D31145"
            elevation="0"
            :disabled="!form.id || form.expired_datetime_bool == false && form.package_bool == false"
            :loading="process.manual_activation"
            @click="createActivationManual">
            Buat Perpanjangan Manual
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import moment from 'moment'
export default {
  components: {
    Pagination,
    Empty,
  },
  data () {
    return {
      searching: "",
      searchingOutletDev: "",
      whatsapp_text: "",
      menu1: false,
      store: [],
      list_store_package: [],
      package: [],
      package_personal: [],
      detail_merchant: {},
      detail_outlet: {},
      summary_activation: [],
      filter: {
        template_message: "",
        storeType: "",
        status: "",
        is_expired_bool: "",
        is_active_bool: "",
        is_trial_bool: "",
      },
      form: {
        id: "",
        change_price_bool: false,
        package_bool: false,
        expired_datetime_bool: false,
        package_id: "",
        expired_datetime: null,
        price: "",
        bank_name: "",
        bank_branch: "",
        bank_account_name: "",
        bank_account_number: ""
      },
      process: {
        run: false,
        manual_activation: false
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      dialog: {
        history_activation: false,
        follow_up: false,
        format_whatsapp: false,
        manual_activation: false,
        filtered: false,
      },
      whatsapp_format: [
        {
          title: "Tulisan Miring",
          subtitle: "_text_",
          hasil: "<i>text</i>",
        },
        {
          title: "Tulisan Tebal",
          subtitle: "*text*",
          hasil: "<b>text</b>",
        },
        {
          title: "Tulisan Dicoret",
          subtitle: "~text~",
          hasil: "<s>text</s>",
        },
        {
          title: "Nama Pemilik",
          subtitle: "{owner_name}",
          hasil: "memunculkan nama Pemilik pada pesan Anda",
        },
        {
          title: "Nama Outlet",
          subtitle: "{outlet_name}",
          hasil: "memunculkan nama Outlet pada pesan Anda",
        },
        {
          title: "Alamat Outlet",
          subtitle: "{outlet_address}",
          hasil: "memunculkan alamat outlet pada pesan Anda",
        },
        {
          title: "Tanggal Expired Outlet",
          subtitle: "{expired_date}",
          hasil: "memunculkan text tanggal expired sesuai outlet pada pesan Anda",
        },
      ],
      status_filters: [
        {text: 'Belum Aktivasi / Tidak Aktif', value: 'unsubscribe'}, 
        {text: 'Toko Aktif (Berlangganan dan Trial)', value: 'store_active'},
        {text: 'Masa Trial Berlangsung', value: 'trial_active'},
        {text: 'Masa Trial Sudah Habis', value: 'trial_expired'},
        {text: 'Masa Berlangganan Berlangsung', value: 'subscribe_active'},
        {text: 'Masa Berlangganan Sudah Habis', value: 'subscribe_expired'},
      ],

      // DATA TABLES
      store_id: "",
      store_name: "",
      store_mobilephone: "",
      items_template: [],

      // * Pagination activation
      items_activation: [],
      limit_activation: 10,
      page_ctivation: 1,
      options_activation:{},
      // * Pagination activation end

      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        activation: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        activation: {
          menu: 0,
          pagination: 0  
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        },
        {
          text: 'Nama Outlet',
          value: 'name',
          align: 'start',
          width: 200
        },
         {
          text: 'Nama pemilik',
          value: 'owner_name',
          align: 'start',
          width: 200
        },
        // {
        //   text: 'Kategori Outlet',
        //   value: 'category_name',
        //   align: 'start',
        //   width: 200
        // },
        {
          text: 'No. Handphone',
          value: 'mobilephone',
          align: 'center',
          width: 150
        },
        {
          text: 'Alamat',
          value: 'address',
          align: 'start',
          width: 250
        },
        {
          text: 'Tipe Berlangganan',
          value: 'store_type',
          align: 'center',
          width: 200
        },
        {
          text: 'Omset Outlet',
          value: 'sales_order_total',
          align: 'end',
          width: 200
        },
        {
          text: 'Tanggal Kadaluarsa',
          value: 'expired_datetime',
          align: 'center',
          width: 200
        }
      ],
      headers_activation: [
        {
          text: 'Tanggal',
          value: 'datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Kode Pembayaran',
          value: 'code',
          align: 'center',
          width: 200
        },
        {
          text: 'Pembelian Langganan',
          value: 'purchase_detail',
          align: 'start',
          width: 200
        },
        {
          text: 'Metode Pembayaran',
          value: 'payment_channel',
          align: 'start',
          width: 250
        },
        {
          text: 'Status Berlangganan',
          value: 'status',
          align: 'center',
          width: 200
        },
        {
          text: 'Harga',
          value: 'product_price',
          align: 'end',
          width: 200
        },
        {
          text: 'Diskon',
          value: 'discount_value',
          align: 'end',
          width: 200
        },
        {
          text: 'Total pembayaran',
          value: 'grand_total',
          align: 'end',
          width: 200
        }
      ],
    }
  },
  watch: {
    'filter.template_message': function(val) {
      this.setMessageWhatsApp(val)
    },
    'filter.storeType': function(val) {
      this.filter.storeType = val
      this.fetch()
    },

    searching: function(val) {
      this.searching = val
      this.fetch()
    },

    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },

    // * Activation Option
    'selected.activation.pagination': function(val) {
      this.fetchHistoryActivation(val+1)
    },
    options_activation:{
      handler(){
        this.fetchHistoryActivation()
      },
      deep:true
    },
  },
  computed: {
    computedDateFormattedMomentjs () {
      return this.form.expired_datetime ? moment(this.form.expired_datetime).format('D MMMM YYYY') : ''
    },  
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.fetch()
    this.fetchOutletDevTool()
    this.fetchPackageDevTool()
    this.fetchPackagePersonalDevTool()
    if (this.$route.query.store_type) {
      this.filter.storeType = this.$route.query.store_type
    }
  },
  methods: {
    // EVENTS
    onChangeStore(value) {
      this.form.id = value?.id;
      if (value?.store_type == 'Personal') {
        this.list_store_package = this.package_personal;
      } else {
        this.list_store_package = this.package;
      }
    },
    openManualActivationDialog() {
      
      this.form.id = "";
      this.form.change_price_bool = false;
      this.form.package_bool = false;
      this.form.expired_datetime_bool = false;
      this.form.package_id = "";
      this.form.expired_datetime = null;
      this.form.price = "";
      this.form.bank_name = "";
      this.form.bank_branch = "";
      this.form.bank_account_name = "";
      this.form.bank_account_number = "";
      this.dialog.manual_activation = true;
    },
    // EVENTS
    actions(item, type) {
      switch (type) {
        case 'history':
          this.store_id = item.id
          this.store_name = item.name
          this.dialog.history_activation = true
          this.fetchHistoryActivation()
          this.fetchSummaryActivation()
          break;
        case 'followup':
          this.store_mobilephone = this.$options.filters.wa_number(item.mobilephone)
          this.detail_outlet = item
          this.dialog.follow_up = true
          this.fetchTemplate()
          break;
      }
    },
    setMessageWhatsApp(slug) {
      this.items_template.forEach((template) => {
        if (slug == template.slug) {
          return this.whatsapp_text = template.messages
        }
      })
    },
    format_text(text){
      return text.replace(`{expired_date}`,`${this.$options.filters.datetimeFull(this.detail_outlet.expired_datetime)}`)
      .replace(`{owner_name}`,`${this.detail_outlet.owner_name}`)
      .replace(`{outlet_name}`,`${this.detail_outlet.name}`)
      .replace(`{outlet_address}`,`${this.detail_outlet.address}`)
      
      // .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
      // .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
      // .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
      // .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>');
    },
    sendWhatsappMessage() {
      let message = this.format_text(this.whatsapp_text)
      let link = encodeURI(`https://api.whatsapp.com/send?phone=${this.store_mobilephone}&text=${message}`)
      // let link = encodeURI(`https://api.whatsapp.com/send?phone=62895392685163&text=${message}`)
      window.open(link)
    },
    resetFilter() {
      this.filter = {
        template_message: "",
        storeType: "",
        status: "",
        is_expired_bool: "",
        is_active_bool: "",
        is_trial_bool: "",
      };
      return
    },
    async fetchDetailMerchant() {
      this.process.run = true

      await get('internal/dashboard', {
        params: {
          id: this.$route.params.id,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.detail_merchant = res.results.data[0]
        }
      })
    },
    async fetchTemplate() {
      // this.process.run = true

      await get('internal/dashboard/wa_template', {})
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_template = res.results.data
          this.filter.template_message = res.results.data[0].slug
          // this.setMessageWhatsApp(res.results.data[0].slug)
        }
      })
      this.process.run = false
    },
    async fetch(paging) {
      this.dialog.filtered = false
      this.process.run = true

      let sort = "";

      if (this.options.sortBy && this.options.sortBy.length > 0) {
        if (this.options.sortDesc && this.options.sortDesc.length > 0) {
          sort = `-${this.options.sortBy[0]}`;
        } else {
          sort = `${this.options.sortBy[0]}`;
        }
      }

      let params = {}
      switch (this.filter.status) {
        case 'unsubscribe':
          params = {
            page: paging,
            limit: this.limit,
            search: this.searching,
            store_type: this.filter.storeType,
            is_active_bool: false,
            sort,
          }
          break;
        case 'store_active':
          params = {
            page: paging,
            limit: this.limit,
            search: this.searching,
            store_type: this.filter.storeType,
            is_active_bool: true,
            is_expired_bool: false,
            sort,
          }
          break;
        case 'trial_active' :
          params = {
            page: paging,
            limit: this.limit,
            search: this.searching,
            store_type: this.filter.storeType,
            is_trial_bool: true,
            is_active_bool: true,
            is_expired_bool: false,
            sort,
          }
          break
        case 'trial_expired' :
          params = {
            page: paging,
            limit: this.limit,
            search: this.searching,
            store_type: this.filter.storeType,
            is_expired_bool: true,
            is_active_bool: true,
            is_trial_bool: true,
            sort,
          }
          break
        case 'subscribe_active':
          params = {
            page: paging,
            limit: this.limit,
            search: this.searching,
            store_type: this.filter.storeType,
            is_active_bool: true,
            is_expired_bool: false,
            is_trial_bool: false,
            sort,
          }
          break;
        case 'subscribe_expired' :
          params = {
            page: paging,
            limit: this.limit,
            search: this.searching,
            store_type: this.filter.storeType,
            is_expired_bool: true,
            is_active_bool: true,
            is_trial_bool: false,
            sort,
          }
          break
        default:
          params = {
            page: paging,
            limit: this.limit,
            search: this.searching,
            store_type: this.filter.storeType,
            sort,
          }
          break;
      }
      // if (filter.status == 'subscribe') {
      //   params = {
      //     page: paging,
      //     limit: this.limit,
      //     search: this.searching,
      //     store_type: this.filter.storeType,
      //     is_active_bool: true,
      //     is_expired_bool: false,
      //     sort,
      //   }
      // }
      

      await get('internal/dashboard/store', {
        params
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },
    async fetchHistoryActivation(paging) {
      await get('internal/dashboard/store/activation_history', {
        params: {
          page: paging,
          limit: this.limit_activation,
          store_id: this.store_id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_activation = res.results.data
          this.pagination.activation.data = res.results.pagination
          this.pagination.activation.page = res.results.pagination.total_page < this.page ? 1 : ""
        }
      })
    },
    async fetchSummaryActivation() {

      await get('internal/dashboard/store/activation_history/summary', {
        params: {
          store_id: this.store_id,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          let detail = res.results.data
          this.summary_activation = [
            {
              title: "Total Pembelian Berhasil",
              value: this.$options.filters.num_format(detail.total_transaction),
              help: "",
              color: "green",
              icon: "mdi-cash-check"
            },
            {
              title: "Total Harga Pembelian",
              value: this.$options.filters.price(detail.total_product_price),
              help: "",
              color: "primary",
              icon: "mdi-cash"
            },
            {
              title: "Total Diskon Pembelian",
              value: this.$options.filters.price(detail.total_discount_value),
              help: "",
              color: "red",
              icon: "mdi-tag-text"
            },
            {
              title: "Total Nett Pembelian",
              value: this.$options.filters.price(detail.total_nett_price),
              help: "",
              color: "purple",
              icon: "mdi-cash-plus"
            },
            {
              title: "Total Admin Fee",
              value: this.$options.filters.price(detail.total_admin_fee),
              help: "",
              color: "deep-orange",
              icon: "mdi-cash-minus"
            },
            {
              title: "Grand Total Pembayaran",
              value: this.$options.filters.price(detail.grand_total),
              help: "",
              color: "teal",
              icon: "mdi-cash-multiple"
            },
          ]
        }
      })
    },

    // DEV TOOL
    async fetchOutletDevTool() {
      await get('internal/dev-tool/store', {
        params: {
          pagination_bool: false
          // search: this.searchingOutletDev
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.store = res.results.data
        }
      })
    },
    async fetchPackageDevTool() {
      await get('internal/dashboard/promo', {
        params: {
          pagination_bool: false
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.package = res.results.data
        }
      })
    },
    async fetchPackagePersonalDevTool() {
      await get('internal/dashboard/promo', {
        params: {
          type: "Personal",
          pagination_bool: false
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.package_personal = res.results.data
        }
      })
    },
    async createActivationManual() {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-activation"].validate();

      if (isValid) {
        this.process.manual_activation = true;

        await post('internal/dev-tool/store', {
          data: {
            id: this.form.id,
            package_bool: this.form.package_bool,
            expired_datetime_bool: this.form.expired_datetime_bool,
            package_id: this.form.package_id,
            expired_datetime: `${this.form.expired_datetime}`,
            price: this.form.price,
            bank_name: this.form.bank_name,
            bank_branch: this.form.bank_branch,
            bank_account_name: this.form.bank_account_name,
            bank_account_number: this.form.bank_account_number
          }
        })
        .then((response) => {
          let res = response.data
          if(res.status == 200) {
            this.$refs["validation-new-activation"].reset();
            
            Object.assign(this.form, {
              id: "",
              package_bool: false,
              expired_datetime_bool: false,
              package_id: "",
              expired_datetime: null,
            });
            
            this.feedback = "";
            
            this.snackbar.show = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Berhasil menyimpan data";
            
            this.fetch();
            this.process.manual_activation = false;
            this.dialog.manual_activation = false
          } else {
            if (
              res.error_code &&
              res.error_code == "error_validation"
            ) {
              this.feedback = "Cek kembali form Anda!";

              if (Object.keys(res.results.data).length > 0) {
                Object.keys(res.results.data).forEach(function (item) {
                  _self.$refs[`form-${item}`].applyResult({
                    errors: [res.results.data[item]],
                    valid: false,
                    failedRules: {},
                  });
                });
              }
              this.process.run = false;
            } else {
              this.snackbar.show = true;
              this.snackbar.color = "red";
              this.snackbar.text = "Permintaan gagal!";
            }
            this.process.run = false;
          }
        }).catch(function (error) {
            console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.manual_activation = false;
      }
    }
  }
}
</script>