<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">
    
    <!-- ==== DATA SUMMARY PENJUALAN ==== -->
    <v-card outlined class="box-shadow border-radius pa-2 pb-1">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        SUMMARY OUTLET
        <v-spacer></v-spacer>
        <v-btn
          text
          elevation="0"
          class="text-capitalize pink--text"
          small
          to="/store">
          Selengkapnya
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="my-3 mb-6">
        <v-col
          cols="12"
          sm="6"
          lg="6"
          md="6"
          xs="12"
          v-for="(item, index) in process.run ? 3 : store_count.summary"
          :key="index" 
        >
          <v-skeleton-loader
            class="ma-auto"
            height="200"
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            type="image"
          >
            <v-card 
              :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
              class="box-shadow border-radius py-2">
              <v-card-title class="subtitle-1 font-weight-bold">
                <span v-html="item.title"></span>
                <v-spacer></v-spacer>
                <v-tooltip top v-if="item.help">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#D31145"
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer"
                      size="20"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <div v-html="item.help"></div>
                </v-tooltip>
                <v-tooltip left v-if="item.slug">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      class="text-capitalize pink--text"
                      small
                      :to="`/store?store_type=${item.slug}`">
                      <v-icon size="20">mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Selengkapnya</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="subtitle-2">
                <p
                  class="text-second d-flex align-center"
                  style="font-size: 45px;"
                >
                  <span class="mr-3 mt-3" v-html="item.value"></span>
                  <v-spacer />
                  <span>
                    <v-icon size="40" :color="item.color">{{item.icon}}</v-icon>
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>

        <v-col
          cols="12"
          sm="4"
          lg="4"
          md="4"
          xs="12"
          v-for="(item, index) in process.run ? 3 : store_count.active"
          :key="index" 
        >
          <v-skeleton-loader
            class="ma-auto"
            height="200"
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            type="image"
          >
            <v-card 
              :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
              class="box-shadow border-radius py-2">
              <v-card-title class="subtitle-1 font-weight-bold">
                <span v-html="item.title"></span>
                <v-spacer></v-spacer>
                <v-tooltip top v-if="item.help">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#D31145"
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer"
                      size="20"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <div v-html="item.help"></div>
                </v-tooltip>
                <v-tooltip left v-if="item.slug">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      class="text-capitalize pink--text"
                      small
                      :to="`/store?store_type=${item.slug}`">
                      <v-icon size="20">mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Selengkapnya</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="subtitle-2">
                <p
                  class="text-second d-flex align-center"
                  style="font-size: 45px;"
                >
                  <span class="mr-3 mt-3" v-html="item.value"></span>
                  <v-spacer />
                  <span>
                    <v-icon size="40" :color="item.color">{{item.icon}}</v-icon>
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        
        <v-col
          cols="12"
          sm="4"
          lg="4"
          md="4"
          xs="12"
          v-for="(item, index) in process.run ? 3 : store_count.subscribe"
          :key="index" 
        >
          <v-skeleton-loader
            class="ma-auto"
            height="200"
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            type="image"
          >
            <v-card 
              :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
              class="box-shadow border-radius py-2">
              <v-card-title class="subtitle-1 font-weight-bold">
                <span v-html="item.title"></span>
                <v-spacer></v-spacer>
                <v-tooltip top v-if="item.help">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#D31145"
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer"
                      size="20"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <div v-html="item.help"></div>
                </v-tooltip>
                <v-tooltip left v-if="item.slug">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      class="text-capitalize pink--text"
                      small
                      :to="`/store?store_type=${item.slug}`">
                      <v-icon size="20">mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Selengkapnya</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="subtitle-2">
                <p
                  class="text-second d-flex align-center"
                  style="font-size: 45px;"
                >
                  <span class="mr-3 mt-3" v-html="item.value"></span>
                  <v-spacer />
                  <span>
                    <v-icon size="40" :color="item.color">{{item.icon}}</v-icon>
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR OUTLET EXPIRED
      </v-card-title>
      <v-card-text>
        <v-btn-toggle
          v-model="filter.expired_store"
          mandatory>
          <v-btn 
            width="100"
            height="30"
            v-for="(item, i) in filter.expired" :key="i" 
            :value="item.slug"
            :color="item.slug == filter.expired_store ? '#d31145' : ''"
            small
            :class="item.slug == filter.expired_store ? 'white--text text-uppercase font-weight-bold' : 'text-uppercase'">
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.table"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.owner_name`]="{item}">
              <v-list-item>
                <v-list-item-avatar tile class="rounded-lg">
                  <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                     {{ item.name }} <strong class="red--text">{{ item.is_trial_bool == true ? '(Trial)' : '' }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle v-html="item.category_name" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.name`]="{item}">
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.owner_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.owner_email" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <template v-slot:[`item.store_type`]="{item}">
              <v-chip 
                v-if="item.store_type == 'Lite'"
                outlined
                label
                class="font-weight-bold"
                color="green">
                SODAPOS LITE
              </v-chip>
              <v-chip 
                v-else-if="item.store_type == 'Personal'"
                outlined
                label
                class="font-weight-bold"
                color="teal">
                SODAKU
              </v-chip>
              <v-chip 
                v-else-if="item.store_type == 'Pro'"
                outlined
                label
                class="font-weight-bold"
                color="red">
                SODAPOS PRO
              </v-chip>
              <v-chip 
                v-else
                outlined
                label
                class="font-weight-bold"
                color="red">
                {{item.store_type}}
              </v-chip>
            </template>

            <template v-slot:[`item.sales_order_total`]="{item}">
              {{ item.sales_order_total | price }}
            </template>

            <template v-slot:[`item.expired_datetime`]="{item}">
              {{ item.expired_datetime | datetime }}
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="teal"
                    @click="actions(item,'followup')"
                    icon>
                    <!-- :href="item.wa_link"
                    target="_blank" -->
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                </template>
                <span>Kirim Pesan WA Follow Up</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item,'history')"
                    target="_blank"
                    color="orange"
                    icon>
                    <v-icon>mdi-clipboard-text-clock</v-icon>
                  </v-btn>
                </template>
                <span>Lihat History Aktivasi</span>
              </v-tooltip>

            </template>
          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetchStoreExpired()" 
            @changePage="page = $event; fetchStoreExpired($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>

      </v-card-text>

    </v-card>

    <!-- ==== DATA REGISTRASI ==== -->
    <v-row>
      <v-col cols="12" md="12" class="mt-6">
        <v-skeleton-loader
          class="ma-auto"
          transition="scale-transition"
          :tile="true"
          type="card"
        >
          <v-card 
            outlined 
            class="box-shadow border-radius py-5">
            <v-card-title class="body-1 px-5 text-second pt-0 flex justify-space-between">
              <div>
                TOTAL REGISTRASI
              </div>
              <div style="width: 400px">
                <v-spacer></v-spacer>
                <VueCtkDateTimePicker
                  color="#d31145"
                  onlyDate
                  :range="true"
                  right
                  v-model="filter.filter_date"
                  label="Pilih Tanggal"
                  :noClearButton="true"
                  format="YYYY-MM-DD"
                  formatted="LL"
                  locale="id"
                  :max-date="new Date().toISOString().substr(0, 10)"
                  :custom-shortcuts="[
                    { key: 'today', label: 'Hari ini', value: 'day' },
                    { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
                    { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
                    { key: 'last7Days', label: '7 hari terakhir', value: 7 },
                    { key: 'last30Days', label: '30 hari terakhir', value: 30 },
                    { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
                    { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
                    { key: 'thisYear', label: 'Tahun ini', value: 'year' },
                    { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
                  ]"
                />
              </div>
            </v-card-title>
            <v-card-subtitle class="caption px-5 text-third">
              <!-- <b>{{ filtered }}</b> -->
            </v-card-subtitle>
            <div class="mt-16 pa-3">
              <div v-if="Object.keys(register_bar).length > 0">
                <apexchart
                  height="300"
                  :options="register_bar.chartOptions"
                  :series="register_bar.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="register_bar.series == []"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </div>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- ==== DATA RANK SALES ==== -->
    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-second pt-0 flex justify-space-between">
        <div>
          DATA RANKING SALES
        </div>
        <div style="width: 400px">
          <v-spacer></v-spacer>
          <!-- <VueCtkDateTimePicker
            color="#d31145"
            onlyDate
            :range="true"
            right
            v-model="filter.filter_date_rank_sales"
            label="Pilih Tanggal"
            :noClearButton="true"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="new Date().toISOString().substr(0, 10)"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
            ]"
          /> -->
        </div>
      </v-card-title>
      <v-card-subtitle class="caption px-5 text-third">
        <!-- <b>{{ filtered }}</b> -->
      </v-card-subtitle>
      <v-row class="mb-6 mt-6">
        <v-col cols="12" md="6">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.run"
            transition="scale-transition"
            :tile="true"
            type="card"
          >
            <v-card outlined class="border-radius pa-5 pb-5">
              <v-card-title class="body-2 text-second">
                5 SALES BERDASARKAN CODE DIGUNAKAN TERBANYAK
              </v-card-title>
              <v-card-subtitle class="caption text-third">
                <!-- <b>{{ filtered }}</b> -->
              </v-card-subtitle>
              <div v-if="Object.keys(rankSalesCode).length > 0">
                <apexchart
                  :height="
                    $vuetify.breakpoint.name === 'lg' ||
                    $vuetify.breakpoint.name === 'md'
                      ? 200
                      : 300
                  "
                  :width="$vuetify.breakpoint.name === 'lg' ? 500 : 600"
                  :options="rankSalesCode.chartOptions"
                  :series="rankSalesCode.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="rankSalesCode.series.length < 1"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.run"
            transition="scale-transition"
            :tile="true"
            type="card"
          >
            <v-card outlined class="border-radius pa-5 pb-5">
              <v-card-title class="body-2 text-second">
                5 SALES BERDASARKAN KOMISI TERBANYAK
              </v-card-title>
              <v-card-subtitle class="caption text-third">
              </v-card-subtitle>
              <div v-if="Object.keys(rankSalesCommission).length > 0">
                <apexchart
                  :height="
                    $vuetify.breakpoint.name === 'lg' ||
                    $vuetify.breakpoint.name === 'md'
                      ? 200
                      : 300
                  "
                  :width="$vuetify.breakpoint.name === 'lg' ? 500 : 600"
                  :options="rankSalesCommission.chartOptions"
                  :series="rankSalesCommission.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="rankSalesCommission.series.length < 1"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </v-card>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>

    <!-- ==== DATA AKTIVASI ==== -->
    <v-row>
      <v-col cols="12" md="12" class="mt-6">
        <v-skeleton-loader
          class="ma-auto"
          transition="scale-transition"
          :tile="true"
          type="card"
        >
          <v-card 
            outlined 
            class="box-shadow border-radius py-5">
            <v-card-title class="body-1 px-5 text-second pt-0 flex justify-space-between">
              <div>
                TOTAL AKTIVASI
              </div>
              <div style="width: 400px">
                <v-spacer></v-spacer>
                <VueCtkDateTimePicker
                  color="#d31145"
                  onlyDate
                  :range="true"
                  right
                  v-model="filter.filter_date_aktivation"
                  label="Pilih Tanggal"
                  :noClearButton="true"
                  format="YYYY-MM-DD"
                  formatted="LL"
                  locale="id"
                  :max-date="new Date().toISOString().substr(0, 10)"
                  :custom-shortcuts="[
                    { key: 'today', label: 'Hari ini', value: 'day' },
                    { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
                    { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
                    { key: 'last7Days', label: '7 hari terakhir', value: 7 },
                    { key: 'last30Days', label: '30 hari terakhir', value: 30 },
                    { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
                    { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
                    { key: 'thisYear', label: 'Tahun ini', value: 'year' },
                    { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
                  ]"
                />
              </div>
            </v-card-title>
            <v-card-subtitle class="caption px-5 text-third">
              <!-- <b>{{ filtered }}</b> -->
            </v-card-subtitle>

            <div class="mt-16 pa-3" v-if="process.run_activation == false">
              <div class="body-2 ml-3">
                JUMLAH AKTIVASI SODAPOS
              </div>
              <div v-if="Object.keys(aktivasi_bar.summary).length > 0">
                <apexchart
                  height="300"
                  :options="aktivasi_bar.summary.chartOptions"
                  :series="aktivasi_bar.summary.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="aktivasi_bar.summary.series == []"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </div>
            <v-divider></v-divider>

            <div class="mt-16 pa-3" v-if="process.run_activation == false">
              <div class="body-2 ml-3">
                TOTAL PENDAPATAN LANGGANAN SODAPOS LITE
              </div>
              <div v-if="Object.keys(aktivasi_bar.nominal_lite).length > 0">
                <apexchart
                  height="300"
                  :options="aktivasi_bar.nominal_lite.chartOptions"
                  :series="aktivasi_bar.nominal_lite.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="aktivasi_bar.nominal_lite.series == []"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </div>
            <v-divider></v-divider>

            <div class="mt-16 pa-3" v-if="process.run_activation == false">
              <div class="body-2 ml-3">
                TOTAL PENDAPATAN LANGGANAN SODAPOS PRO
              </div>
              <div v-if="Object.keys(aktivasi_bar.nominal_pro).length > 0">
                <apexchart
                  height="300"
                  :options="aktivasi_bar.nominal_pro.chartOptions"
                  :series="aktivasi_bar.nominal_pro.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="aktivasi_bar.nominal_pro.series == []"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </div>
            
            <!-- NOMINAL SODAKU -->
            <div class="mt-16 pa-3" v-if="process.run_activation == false">
              <div class="body-2 ml-3">
                TOTAL PENDAPATAN LANGGANAN SODAKU
              </div>
              <div v-if="Object.keys(aktivasi_bar.nominal_personal).length > 0">
                <apexchart
                  height="300"
                  :options="aktivasi_bar.nominal_personal.chartOptions"
                  :series="aktivasi_bar.nominal_personal.series"
                >
                </apexchart>
              </div>
              <div
                class="title text-second d-flex align-center justify-center"
                v-if="aktivasi_bar.nominal_personal.series == []"
                style="height: 200px;"
              >
                Data Belum Tersedia
              </div>
            </div>
            <!-- NOMINAL SODAKU -->

          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog.history_activation" persistent scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          HISTORY BERLANGGANAN PADA OUTLET {{ store_name }}
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.history_activation =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 800px;">

          <v-row class="my-3 mb-6">
            <v-col
              cols="12"
              sm="4"
              lg="4"
              md="4"
              xs="12"
              v-for="(item, index) in summary_activation"
              :key="index"
            >
              <v-skeleton-loader
                class="ma-auto"
                height="150"
                :loading="process.run"
                transition="scale-transition"
                :tile="false"
                type="image"
              >
                <v-card 
                  :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
                  class="box-shadow border-radius py-0">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    {{ item.title }}
                    <v-spacer></v-spacer>
                    <v-tooltip left v-if="item.help">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="#D31145"
                          v-bind="attrs"
                          v-on="on"
                          class="cursor-pointer"
                          size="20"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <div v-html="item.help"></div>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text class="subtitle-2">
                    <p
                      class="text-second d-flex align-center"
                      style="font-size: 25px;"
                    >
                      <span class="mr-3 mt-3">
                        {{ item.value }}
                      </span>
                      <v-spacer />
                      <span>
                        <v-icon size="40" :color="item.color">{{
                          item.icon
                        }}</v-icon>
                      </span>
                    </p>
                  </v-card-text>
                </v-card>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers_activation"
            :items="items_activation"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options_activation"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.code`]="{item}">
              {{ item.code }}
            </template>

            <template v-slot:[`item.datetime`]="{item}">
              {{ item.datetime | datetime }}
            </template>

            <template v-slot:[`item.purchase_detail`]="{item}">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.detail.product_name }} - 
                    <span class="text-uppercase font-weight-bold subtitle-2 red--text" v-if="item.detail.product_type  == 'Pro'">{{ item.detail.product_type }}</span>
                    <span class="text-uppercase font-weight-bold subtitle-2 green--text" v-else>{{ item.detail.product_type }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.detail.product_desc }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.status`]="{item}">
              <v-tooltip bottom v-if="item.status == 'Dibatalkan'">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip 
                    v-on="on"
                    v-bind="attrs"
                    outlined
                    label
                    class="font-weight-bold"
                    color="red">
                    Dibatalkan
                  </v-chip>
                </template>
                <span>Alasan pembatalan : {{ item.cancel_reason }}</span>
                <div>Dibatalkan pada :{{ item.cancel_datetime | datetime }}</div>
              </v-tooltip>
              <v-chip 
                v-else
                outlined
                label
                class="font-weight-bold"
                color="green">
                Terbayar
              </v-chip>
            </template>

            <template v-slot:[`item.discount_value`]="{item}">
              {{ item.discount_value | price }}
            </template>

            <template v-slot:[`item.grand_total`]="{item}">
              {{ item.grand_total | price }}
            </template>

            <template v-slot:[`item.payment_channel`]="{item}">
              <b class="text-uppercase">{{ item.payment_channel }}</b>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit_activation = $event; fetchHistoryActivation()" 
            @changePage="page_activate = $event; fetchHistoryActivation($event)" 
            :model="pagination.activation"
            v-show="pagination.activation.data.total_page > 0"/>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.follow_up" persistent width="800px" scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          KIRIM PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.follow_up =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3" style="height: 500px">

          <div>No Handphone <i class="font-weight-bold">(readonly)</i></div>
          <v-text-field
            outlined
            color="#d21145"
            :readonly="store_mobilephone == '' ? false : true"
            dense
            :value="store_mobilephone">
          </v-text-field>

          <div class="subtitle-2 mb-2">
            Masukkan text pesan disini
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  size="20"
                  color="orange"
                  @click="dialog.format_whatsapp = true; dialog.follow_up =false;">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Informasi format pesan whatsapp</span>
            </v-tooltip>
          </div>

          <div class="mb-3">
            <v-slide-group
              v-model="filter.template_message"
              show-arrows>
              <v-slide-item
                v-for="(item, i) in items_template" :key="i" 
                v-slot="{ active, toggle }"
                :value="item.slug">
                <v-btn
                  class="mx-2"
                  small
                  :input-value="active"
                  active-class="red--text"
                  depressed
                  rounded
                  @click="toggle"
                  :class="item.slug == filter.template_message ? 'white--text text-uppercase font-weight-bold' : 'text-uppercase'">
                  {{ item.title }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </div>

          <v-textarea
            outlined
            rows="10"
            dense
            color="#d31145"
            v-model="whatsapp_text">
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            elevation="0"
            @click="dialog.follow_up = false">
            Batal
          </v-btn>
          <v-btn
            class="text-capitalize white--text"
            color="#D31145"
            elevation="0"
            @click="sendWhatsappMessage">
            Kirim Pesan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.format_whatsapp" persistent width="1000px">
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          INFORMASI FORMAT PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.format_whatsapp =false; dialog.follow_up = true;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3">
          
          <ol>
            <li v-for="(format, i) in whatsapp_format" :key="i">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ format.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{format.subtitle}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle v-html="format.hasil"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </li>
          </ol>

          <p>Sesuai pada FAQ Whatsapp di halaman <a href="https://faq.whatsapp.com/539178204879377/?helpref=uf_share" target="_blank">Whatsapp FAQ</a></p>

        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { get } from '@/service/Axios'
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Editor from '@tinymce/tinymce-vue'
import moment from 'moment'
export default {
  components: {
    Pagination,
    Empty,
    'editor': Editor
  },
  data () {
    return {
      store_count: [],
      list_store_expired: [],
      whatsapp_text: "",
      detail_merchant: {},
      detail_outlet: {},
      register_bar: {},
      rankSalesCode: {},
      rankSalesCommission: {},
      aktivasi_bar: {
        summary: {},
        trial_active: {},
        trial_inactive: {},
        user_lite: {},
        user_pro: {},
        user_personal: {},
        nominal_lite: {},
        nominal_pro: {},
        nominal_personal: {},
      },
      summary_activation: [],
      process: {
        run: false,
        run_activation: false,
        run_rank_sales: false,
        table: false,
      },
      filter: {
        filter_date: {},
        filter_date_aktivation: {},
        filter_date_rank_sales: {},
        template_message: "",
        expired_store: "expired",
        expired: [
          {
            text: 'Expired',
            slug: 'expired'
          },
          {
            text: 'Hari ini',
            slug: 'day'
          },
          {
            text: 'Minggu ini',
            slug: 'week'
          },
          {
            text: 'Bulan ini',
            slug: 'month'
          }
        ]
      },
      dialog: {
        history_activation: false,
        follow_up: false,
        format_whatsapp: false,
      },
      whatsapp_format: [
        {
          title: "Tulisan Miring",
          subtitle: "_text_",
          hasil: "<i>text</i>",
        },
        {
          title: "Tulisan Tebal",
          subtitle: "*text*",
          hasil: "<b>text</b>",
        },
        {
          title: "Tulisan Dicoret",
          subtitle: "~text~",
          hasil: "<s>text</s>",
        },
        {
          title: "Nama Pemilik",
          subtitle: "{owner_name}",
          hasil: "memunculkan nama Pemilik pada pesan Anda",
        },
        {
          title: "Nama Outlet",
          subtitle: "{outlet_name}",
          hasil: "memunculkan nama Outlet pada pesan Anda",
        },
        {
          title: "Alamat Outlet",
          subtitle: "{outlet_address}",
          hasil: "memunculkan alamat outlet pada pesan Anda",
        },
        {
          title: "Tanggal Expired Outlet",
          subtitle: "{expired_date}",
          hasil: "memunculkan text tanggal expired sesuai outlet pada pesan Anda",
        },
      ],

      // DATA TABLES
      store_id: "",
      store_name: "",
      store_mobilephone: "",
      items_template: [],
      
      // * Pagination activation
      items_activation: [],
      limit_activation: 10,
      page_ctivation: 1,
      options_activation:{},
      // * Pagination activation end

      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        activation: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        activation: {
          menu: 0,
          pagination: 0  
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        },
        {
          text: 'Nama Outlet',
          value: 'owner_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Pemilik',
          value: 'name',
          align: 'start',
          width: 200
        },
        {
          text: 'No. Handphone',
          value: 'mobilephone',
          align: 'center',
          width: 150
        },
        {
          text: 'Alamat',
          value: 'address',
          align: 'start',
          width: 250
        },
        {
          text: 'Tipe Berlangganan',
          value: 'store_type',
          align: 'center',
          width: 200
        },
        {
          text: 'Omset Outlet',
          value: 'sales_order_total',
          align: 'end',
          width: 200
        },
        {
          text: 'Tanggal Kadaluarsa',
          value: 'expired_datetime',
          align: 'center',
          width: 200
        }
      ],
      headers_activation: [
        {
          text: 'Tanggal',
          value: 'datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Kode Pembayaran',
          value: 'code',
          align: 'center',
          width: 200
        },
        {
          text: 'Pembelian Langganan',
          value: 'purchase_detail',
          align: 'start',
          width: 200
        },
        {
          text: 'Metode Pembayaran',
          value: 'payment_channel',
          align: 'start',
          width: 250
        },
        {
          text: 'Status Berlangganan',
          value: 'status',
          align: 'center',
          width: 200
        },
        {
          text: 'Diskon',
          value: 'discount_value',
          align: 'end',
          width: 200
        },
        {
          text: 'Total pembayaran',
          value: 'grand_total',
          align: 'end',
          width: 200
        }
      ],
    }
  },
  watch: {
    'filter.template_message': function(val) {
      this.setMessageWhatsApp(val)
    },

    'filter.filter_date': function(val) {
      this.fetchSummaryRegistrations();
    },

    'filter.filter_date_aktivation': function(val) {
      this.fetchSummaryAktivations();
    },

    'filter.filter_date_rank_sales': function (type) {
      this.fetchRankSalesByCode()
      this.fetchRankSalesByCommission()
    },

    'filter.expired_store': function (type) {
      this.fetchStoreExpired()
    },


    'selected.pagination': function(val) {
      this.fetchStoreExpired(val+1)
    },
    options:{
      handler(){
        this.fetchStoreExpired()
      },
      deep:true
    },

    // * Activation Option
    'selected.activation.pagination': function(val) {
      this.fetchHistoryActivation(val+1)
    },
    options_activation:{
      handler(){
        this.fetchHistoryActivation()
      },
      deep:true
    },
  },
  computed: {
    // filtered() {
    //   return Object.keys(this.filter.filter_date_rank_sales).length > 0
    //     ? moment(this.filter.filter_date_rank_sale.start).format("DD MMMM YYYY") +
    //         " - " +
    //         moment(this.filter.filter_date_rank_sale.end).format("DD MMMM YYYY")
    //     : "";
    // },
  },
  mounted() {
    this.fetchStoreCount()
    this.fetchStoreExpired()
    this.fetchSummaryRegistrations()
    this.fetchSummaryAktivations()
    this.fetchRankSalesByCode()
    this.fetchRankSalesByCommission()
  },
  methods: {
    actions(item, type) {
      switch (type) {
        case 'history':
          this.store_id = item.id
          this.store_name = item.name
          this.dialog.history_activation = true
          this.fetchHistoryActivation()
          this.fetchSummaryStoreActivation()
          break;
        case 'followup':
          this.store_mobilephone = this.$options.filters.wa_number(item.mobilephone)
          this.detail_outlet = item
          this.dialog.follow_up = true
          this.fetchTemplate()
          break;
      }
    },

    setMessageWhatsApp(slug) {
      this.items_template.forEach((template) => {
        if (slug == template.slug) {
          return this.whatsapp_text = template.messages
        }
      })
    },

    format_text(text){
      return text.replace(`{expired_date}`,`${this.$options.filters.datetimeFull(this.detail_outlet.expired_datetime)}`)
      .replace(`{owner_name}`,`${this.detail_outlet.owner_name}`)
      .replace(`{outlet_name}`,`${this.detail_outlet.name}`)
      .replace(`{outlet_address}`,`${this.detail_outlet.address}`)
      
      // .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
      // .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
      // .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
      // .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>');
    },

    sendWhatsappMessage() {
      if (this.store_mobilephone == "") {
        return;
      }
      let message = this.format_text(this.whatsapp_text)
      let link = encodeURI(`https://api.whatsapp.com/send?phone=${this.store_mobilephone}&text=${message}`)
      // let link = encodeURI(`https://api.whatsapp.com/send?phone=62895392685163&text=${message}`)
      window.open(link)
    },

    async fetchDetailMerchant() {
      this.process.run = true

      await get('internal/dashboard', {
        params: {
          id: this.$route.params.id,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.detail_merchant = res.results.data[0]
        }
      })
    },

    async fetchTemplate() {
      // this.process.run = true

      await get('internal/dashboard/wa_template', {})
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_template = res.results.data
          this.filter.template_message = res.results.data[0].slug
          // this.setMessageWhatsApp(res.results.data[0].slug)
        }
      })
      this.process.run = false
    },

    async fetchStoreCount() {
      this.process.run = true
      await get(`internal/dashboard/dashboard/store_count`, {})
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          let detail = res.results.data
          this.store_count.summary = [
            {
              title: "Total Outlet Terdaftar",
              value: detail.total_store,
              help: "",
              slug: "",
              color: "primary",
              icon: "mdi-storefront"
            },
            {
              title: "Total Outlet Expired",
              value: detail.total_store_expired,
              help: "",
              slug: "",
              color: "red",
              icon: "mdi-storefront-remove"
            },
          ]

          this.store_count.active = [
            {
              title: "Total Outlet Sudah Aktivasi",
              value: detail.total_store_activated,
              help: "Total Outlet yang sudah melakukan aktivasi dan termasuk masa trial",
              slug: "",
              color: "orange",
              icon: "mdi-storefront"
            },
            {
              title: "Total Outlet berlangganan",
              value: detail.total_store_subscribe,
              help: "Total Outlet yang berlangganan saat ini",
              slug: "",
              color: "orange",
              icon: "mdi-storefront"
            },
            {
              title: "Total Outlet Masa Trial",
              value: detail.total_store_trial,
              help: "Total Outlet yang berada pada masa trial",
              slug: "",
              color: "orange",
              icon: "mdi-storefront"
            },
          ]
          this.store_count.subscribe = [
            {
              title: `Total Outlet Aktif <span class='red--text'>(PRO)</span>, <span class='green--text'>(LITE)</span> & <span class='teal--text'>(SODAKU)</span>`,
              value: `${detail.total_store_active}<span class="body-1">/${detail.total_store}</span>`,
              help: "",
              slug: "",
              color: "teal",
              icon: "mdi-storefront-check"
            },
            {
              title: "Total Outlet Aktif <span class='red--text'>(PRO)</span>",
              value: `${detail.total_store_pro_active}<span class="body-1">/${detail.total_store_pro}</span>`,
              help: "",
              slug: "Pro",
              color: "teal",
              icon: "mdi-storefront-check"
            },
            {
              title: "Total Outlet Aktif <span class='green--text'>(LITE)</span>",
              value: `${detail.total_store_lite_active}<span class="body-1">/${detail.total_store_lite}</span>`,
              help: "",
              slug: "Lite",
              color: "teal",
              icon: "mdi-storefront-check"
            },
            {
              title: "Total Outlet Aktif <span class='teal--text'>(SODAKU)</span>",
              value: `${detail.total_store_personal_active}<span class="body-1">/${detail.total_store_personal}</span>`,
              help: "",
              slug: "Personal",
              color: "teal",
              icon: "mdi-storefront-check"
            },
          ]
        }
      })
      this.process.run =false
    },

    async fetchStoreExpired (paging) {
      this.process.table = true

      let url = ``
      if(this.filter.expired_store == 'expired') {
        url = `internal/dashboard/store?is_expired_bool=true&is_active_bool=true`
      }

      if(this.filter.expired_store == 'day') {
        url = `internal/dashboard/dashboard/store_expired_day`
      }

      if(this.filter.expired_store == 'week') {
        url = `internal/dashboard/dashboard/store_expired_week`
      }

      if(this.filter.expired_store == 'month') {
        url = `internal/dashboard/dashboard/store_expired_month`
      }

      await get(url, {
        params: {
          page: paging,
          limit: this.limit,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.table = false
        }
      })
    },

    async fetchHistoryActivation(paging) {
      await get('internal/dashboard/store/activation_history', {
        params: {
          page: paging,
          limit: this.limit_activation,
          store_id: this.store_id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_activation = res.results.data
          this.pagination.activation.data = res.results.pagination
          this.pagination.activation.page = res.results.pagination.total_page < this.page ? 1 : ""
        }
      })
    },

    async fetchSummaryStoreActivation() {

      await get('internal/dashboard/store/activation_history/summary', {
        params: {
          store_id: this.store_id,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          let detail = res.results.data
          this.summary_activation = [
            {
              title: "Total Pembelian Berhasil",
              value: this.$options.filters.num_format(detail.total_transaction),
              help: "",
              color: "green",
              icon: "mdi-cash-check"
            },
            {
              title: "Total Harga Pembelian",
              value: this.$options.filters.price(detail.total_product_price),
              help: "",
              color: "primary",
              icon: "mdi-cash"
            },
            {
              title: "Total Diskon Pembelian",
              value: this.$options.filters.price(detail.total_discount_value),
              help: "",
              color: "red",
              icon: "mdi-tag-text"
            },
            {
              title: "Total Nett Pembelian",
              value: this.$options.filters.price(detail.total_nett_price),
              help: "",
              color: "purple",
              icon: "mdi-cash-plus"
            },
            {
              title: "Total Admin Fee",
              value: this.$options.filters.price(detail.total_admin_fee),
              help: "",
              color: "deep-orange",
              icon: "mdi-cash-minus"
            },
            {
              title: "Grand Total Pembayaran",
              value: this.$options.filters.price(detail.grand_total),
              help: "",
              color: "teal",
              icon: "mdi-cash-multiple"
            },
          ]
        }
      })
    },

    async fetchSummaryRegistrations() {

      await get('internal/dashboard/report/registrasi', {
        params : {
          start_date: this.filter.filter_date.start,
          end_date: this.filter.filter_date.end
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.register_bar = {
            series: [
              {
                name: "REGISTRASI HARIAN",
                type: "area",
                data: [],
              },
            ],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: "linear",
                  dynamicAnimation: {
                    speed: 1000,
                  },
                },
                toolbar: {
                  show: true,
                  export: {
                    csv: {
                      filename: "REGISTRASI HARIAN",
                    },
                    svg: {
                      filename: "REGISTRASI HARIAN",
                    },
                    png: {
                      filename: "REGISTRASI HARIAN",
                    }
                  }
                },
                zoom: {
                  enabled: true,
                },
              },
              stroke: {
                curve: "smooth",
                width: [3, 3],
              },
              colors: ["#649fe4", "#41af55"],
              labels: [],
              tooltip: {
                  enabled: true,
                  shared: true,
                  custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return `
                      <div class="arrow_box" style="position: relative;display: inline-block;background-color: #fff;width: 200px;height: 100px;font-size: 14px;font-weight: 600;">
                        <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                        <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                          <span style="color: #000"> Total Registrasi : ${res.results.data[dataPointIndex].jumlah_registrasi} </span> <br/>
                          <span style="color: #000"> Total Outlet : ${res.results.data[dataPointIndex].jumlah_store}</span>
                        </div>
                      </div>
                    `
                  }
                },
              xaxis: {
                tickAmount: 15,
                title: {
                  // text: "Tanggal",
                  offsetX: 2,
                  offsetY: 2,
                  style: {
                    color: undefined,
                    fontSize: "8px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 200,
                    cssClass: "apexcharts-xaxis-title",
                  },
                },
                labels: {
                  style: {
                    color: undefined,
                    fontSize: "8px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 200,
                    cssClass: "apexcharts-xaxis-title",
                  },
                }
              },
              yaxis: {
                labels: {
                  formatter: function(val, index) {
                    let price = "";
                    const reverseNumber = val
                      .toString()
                      .split("")
                      .reverse()
                      .join("");
                    const arrReverseNumber = [
                      ...Array(reverseNumber.length).keys(),
                    ];
                    arrReverseNumber.map((index) => {
                      if (index % 3 === 0)
                        price += reverseNumber.substr(index, 3) + ".";
                    });
                    return `${price
                      .split("", price.length - 1)
                      .reverse()
                      .join("")}`;
                  },
                  style: {
                    color: undefined,
                    fontSize: "8px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 200,
                    cssClass: "apexcharts-xaxis-title",
                  },
                },
                title: {
                  offsetX: 0,
                },
              },
            },
          }

          let label = []
          let register = []
          let store = []

          res.results.data.map((item, key) => {
            label.push(item.tanggal)
            register.push(item.jumlah_registrasi)
            store.push(item.jumlah_store)
          })


          this.register_bar.chartOptions.labels = label
          this.register_bar.series[0].data = register
        }
      })
    },

    async fetchSummaryAktivations() {
      this.process.run_activation = true
      await get('internal/dashboard/report/aktivasi', {
        params : {
          start_date: this.filter.filter_date_aktivation.start,
          end_date: this.filter.filter_date_aktivation.end
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.aktivasi_bar = {
            trial_active: {
              series: [
                {
                  name: "TRIAL AKTIF",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL TRIAL AKTIF",
                      },
                      svg: {
                        filename: "TOTAL TRIAL AKTIF",
                      },
                      png: {
                        filename: "TOTAL TRIAL AKTIF",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: ["#649fe4"],
                labels: [],
                tooltip: {
                    enabled: true,
                    shared: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                      return `
                        <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 100px;font-size: 14px;font-weight: 600;">
                            <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                          <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                            <span style="color: #000"> Total Trial Aktif : ${res.results.data[dataPointIndex].jumlah_trial_aktif} </span>
                          </div>
                        </div>
                      `
                    }
                  },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
            trial_inactive: {
              series: [
                {
                  name: "TRIAL TIDAK AKTIF",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL TRIAL TIDAK AKTIF",
                      },
                      svg: {
                        filename: "TOTAL TRIAL TIDAK AKTIF",
                      },
                      png: {
                        filename: "TOTAL TRIAL TIDAK AKTIF",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: ["#41af55"],
                labels: [],
                tooltip: {
                    enabled: true,
                    shared: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                      return `
                        <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 100px;font-size: 14px;font-weight: 600;">
                            <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                          <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                            <span style="color: #000"> Total Trial Non-aktif : ${res.results.data[dataPointIndex].jumlah_trial_tidak_aktif}</span>
                          </div>
                        </div>
                      `
                    }
                  },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
            user_lite: {
              series: [
                {
                  name: "JUMLAH BERLANGGANAN LITE",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL BERLANGGANAN LITE",
                      },
                      svg: {
                        filename: "TOTAL BERLANGGANAN LITE",
                      },
                      png: {
                        filename: "TOTAL BERLANGGANAN LITE",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: ["#0fb9b1"],
                labels: [],
                tooltip: {
                    enabled: true,
                    shared: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                      return `
                        <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 100px;font-size: 14px;font-weight: 600;">
                            <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                          <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                            <span style="color: #000"> Total Berlanggan Lite : ${res.results.data[dataPointIndex].jumlah_lite} </span>
                          </div>
                        </div>
                      `
                    }
                  },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
            user_pro: {
              series: [
                {
                  name: "JUMLAH BERLANGGANAN PRO",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL BERLANGGANAN PRO",
                      },
                      svg: {
                        filename: "TOTAL BERLANGGANAN PRO",
                      },
                      png: {
                        filename: "TOTAL BERLANGGANAN PRO",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: ["#c0392b"],
                labels: [],
                tooltip: {
                    enabled: true,
                    shared: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                      return `
                        <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 100px;font-size: 14px;font-weight: 600;">
                            <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                          <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                            <span style="color: #000"> Total Berlanggan Pro : ${res.results.data[dataPointIndex].jumlah_pro} </span>
                          </div>
                        </div>
                      `
                    }
                  },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
            user_personal: {
              series: [
                {
                  name: "JUMLAH BERLANGGANAN SODAKU",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL BERLANGGANAN SODAKU",
                      },
                      svg: {
                        filename: "TOTAL BERLANGGANAN SODAKU",
                      },
                      png: {
                        filename: "TOTAL BERLANGGANAN SODAKU",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                colors: ["#c0392b"],
                labels: [],
                tooltip: {
                    enabled: true,
                    shared: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                      return `
                        <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 100px;font-size: 14px;font-weight: 600;">
                            <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                          <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                            <span style="color: #000"> Total Berlanggan Sodaku : ${res.results.data[dataPointIndex].jumlah_personal} </span>
                          </div>
                        </div>
                      `
                    }
                  },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
            summary: {
              series: [
                {
                  name: "TRIAL AKTIF",
                  type: "area",
                  data: [],
                },
                {
                  name: "TRIAL TIDAK AKTIF",
                  type: "area",
                  data: [],
                },
                {
                  name: "BERLANGGANAN LITE",
                  type: "area",
                  data: [],
                },
                {
                  name: "BERLANGGANAN PRO",
                  type: "area",
                  data: [],
                },
                {
                  name: "BERLANGGANAN SODAKU",
                  type: "area",
                  data: [],
                },
              ],
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 90, 100]
                }
              },
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL TRIAL AKTIF",
                      },
                      svg: {
                        filename: "TOTAL TRIAL AKTIF",
                      },
                      png: {
                        filename: "TOTAL TRIAL AKTIF",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                // colors: ["#649fe4", '#F44336', '#E91E63', '#9C27B0'],
                labels: [],
                tooltip: {
                    enabled: true,
                    shared: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                      return `
                        <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 165px;font-size: 14px;font-weight: 600;">
                            <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                          <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                            <span style="color: #000"> Total Trial Aktif : ${res.results.data[dataPointIndex].jumlah_trial_aktif} </span> </br>
                            <span style="color: #000"> Total Trial Tidak Aktif : ${res.results.data[dataPointIndex].jumlah_trial_tidak_aktif} </span> </br>
                            <span style="color: #000"> Total Berlangganan Lite : ${res.results.data[dataPointIndex].jumlah_lite} </span> </br>
                            <span style="color: #000"> Total Berlangganan Pro : ${res.results.data[dataPointIndex].jumlah_pro} </span> </br>
                            <span style="color: #000"> Total Berlangganan Sodaku : ${res.results.data[dataPointIndex].jumlah_personal} </span>
                          </div>
                        </div>
                      `
                    }
                  },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
            nominal_lite: {
              series: [
                {
                  name: "NOMINAL LITE",
                  type: "area",
                  data: [],
                }
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL PENDAPATAN LANGGANAN LITE",
                      },
                      svg: {
                        filename: "TOTAL PENDAPATAN LANGGANAN LITE",
                      },
                      png: {
                        filename: "TOTAL PENDAPATAN LANGGANAN LITE",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                // colors: ["#f39c12"],
                labels: [],
                tooltip: {
                    enabled: true,
                    shared: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                      return `
                        <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 100px;font-size: 14px;font-weight: 600;">
                            <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                          <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                            <span style="color: #000"> Total Nominal Lite : ${res.results.data[dataPointIndex].nominal_lite_label}</span>
                          </div>
                        </div>
                      `
                    }
                  },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
            nominal_pro: {
              series: [
                {
                  name: "NOMINAL PRO",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL NOMINAL LANGGANAN PRO",
                      },
                      svg: {
                        filename: "TOTAL NOMINAL LANGGANAN PRO",
                      },
                      png: {
                        filename: "TOTAL NOMINAL LANGGANAN PRO",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                // colors: ["#be2edd"],
                labels: [],
                tooltip: {
                  enabled: true,
                  shared: true,
                  custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return `
                      <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 100px;font-size: 14px;font-weight: 600;">
                          <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                        <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                          <span style="color: #000"> Total Nominal Pro : ${res.results.data[dataPointIndex].nominal_pro_label}</span>
                        </div>
                      </div>
                    `
                  }
                },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
            nominal_personal: {
              series: [
                {
                  name: "NOMINAL SODAKU",
                  type: "area",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "TOTAL NOMINAL LANGGANAN SODAKU",
                      },
                      svg: {
                        filename: "TOTAL NOMINAL LANGGANAN SODAKU",
                      },
                      png: {
                        filename: "TOTAL NOMINAL LANGGANAN SODAKU",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [3, 3],
                },
                // colors: ["#be2edd"],
                labels: [],
                tooltip: {
                  enabled: true,
                  shared: true,
                  custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return `
                      <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #fff;width: 250px;height: 100px;font-size: 14px;font-weight: 600;">
                          <div style="background: #d31145;color: #fff" class="pa-2"> ${w.globals.categoryLabels[dataPointIndex]}</div>
                        <div style="background: #fff;border-top: 1px solid #d31145" class="px-2 pt-3">
                          <span style="color: #000"> Total Nominal Sodaku : ${res.results.data[dataPointIndex].nominal_personal_label}</span>
                        </div>
                      </div>
                    `
                  }
                },
                xaxis: {
                  tickAmount: 15,
                  title: {
                    // text: "Tanggal",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            },
          }

          let label = [],
              jumlah_trial_aktif = [],
              jumlah_trial_tidak_aktif = [],
              jumlah_lite =  [],
              nominal_lite =  [],
              jumlah_pro =  [],
              nominal_pro =  [],
              jumlah_personal =  [],
              nominal_personal =  [];

          res.results.data.map((item, key) => {
            label.push(item.tanggal)
            jumlah_trial_aktif.push(item.jumlah_trial_aktif)
            jumlah_trial_tidak_aktif.push(item.jumlah_trial_tidak_aktif)
            jumlah_lite.push(item.jumlah_lite)
            jumlah_pro.push(item.jumlah_pro)
            jumlah_personal.push(item.jumlah_personal)
            nominal_lite.push(item.nominal_lite)
            nominal_pro.push(item.nominal_pro)
            nominal_personal.push(item.nominal_personal)
          })


          this.aktivasi_bar.summary.chartOptions.labels = label
          this.aktivasi_bar.nominal_lite.chartOptions.labels = label
          this.aktivasi_bar.nominal_pro.chartOptions.labels = label
          this.aktivasi_bar.nominal_personal.chartOptions.labels = label


          this.aktivasi_bar.summary.series[0].data = jumlah_trial_aktif
          this.aktivasi_bar.summary.series[1].data = jumlah_trial_tidak_aktif
          this.aktivasi_bar.summary.series[2].data = jumlah_lite
          this.aktivasi_bar.summary.series[3].data = jumlah_pro
          this.aktivasi_bar.summary.series[4].data = jumlah_personal
          this.aktivasi_bar.nominal_lite.series[0].data = nominal_lite
          this.aktivasi_bar.nominal_pro.series[0].data = nominal_pro
          this.aktivasi_bar.nominal_personal.series[0].data = nominal_personal

          this.process.run_activation = false
        }
      })
    },

    async fetchRankSalesByCode() {
      this.process.run_rank_sales = true
      await get('internal/dashboard/dashboard/top_rank_sales_code', {
        params : {
          start_date: this.filter.filter_date_rank_sales.start,
          end_date: this.filter.filter_date_rank_sales.end,
          limit: 5,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.rankSalesCode = {
            series: [],
            chartOptions: {
              chart: {
                width: 500,
                type: "pie",
                toolbar: {
                  show: true,
                  export: {
                    csv: {
                      filename: "KATEGORI BERDASARKAN VOLUME",
                    },
                    svg: {
                      filename: "KATEGORI BERDASARKAN VOLUME",
                    },
                    png: {
                      filename: "KATEGORI BERDASARKAN VOLUME",
                    }
                  }
                },
                zoom: {
                  enabled: true,
                  type: 'y',
                },
              },
              labels: [],
              // colors: [
              //   "#2ecc71",
              //   "#f1c40f",
              //   "#9b59b6",
              //   "#3498db",
              //   "#e74c3c",
              // ],
              dataLabels: {
                enabled: true,
                style: {
                  color: undefined,
                  fontSize: "8px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 200,
                  cssClass: "apexcharts-xaxis-title",
                },
              },
              legend: {
                position: "right",
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: false,
                    },
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 450,
                  options: {
                    chart: {
                      width: 300,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            },
          }

          let labels = []
          let total_use_code = []
          res.results.data.forEach(item => {
            labels.push(item.name)
            total_use_code.push(item.total_use_code)
          })

          // TOP CATEGORY VOLUME
          this.rankSalesCode.series = total_use_code;
          this.rankSalesCode.chartOptions.labels = labels;


          this.process.run_rank_sales = false
        }
      })
    },

    async fetchRankSalesByCommission() {
      this.process.run_rank_sales = true
      await get('internal/dashboard/dashboard/top_rank_sales_commission', {
        params : {
          start_date: this.filter.filter_date_rank_sales.start,
          end_date: this.filter.filter_date_rank_sales.end,
          limit: 5,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          
          
          this.rankSalesCommission = {
            series: [],
            chartOptions: {
              chart: {
                width: 500,
                type: "pie",
                toolbar: {
                  show: true,
                  export: {
                    csv: {
                      filename: "KATEGORI BERDASARKAN VOLUME",
                    },
                    svg: {
                      filename: "KATEGORI BERDASARKAN VOLUME",
                    },
                    png: {
                      filename: "KATEGORI BERDASARKAN VOLUME",
                    }
                  }
                },
                zoom: {
                  enabled: true,
                  type: 'y',
                },
              },
              labels: [],
              // colors: [
              //   "#2ecc71",
              //   "#f1c40f",
              //   "#9b59b6",
              //   "#3498db",
              //   "#e74c3c",
              // ],
              dataLabels: {
                enabled: true,
                style: {
                  color: undefined,
                  fontSize: "8px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 200,
                  cssClass: "apexcharts-xaxis-title",
                },
              },
              legend: {
                position: "right",
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: false,
                    },
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 450,
                  options: {
                    chart: {
                      width: 300,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            },
          }

          let labels = []
          let total_commission = []
          res.results.data.forEach(item => {
            labels.push(item.name)
            total_commission.push(item.total_commission)
          })

          // TOP CATEGORY VOLUME
          this.rankSalesCommission.series = total_commission;
          this.rankSalesCommission.chartOptions.labels = labels;
          this.process.run_rank_sales = false
        }
      })
    },
  }
}
</script>
<style scoped>
</style>