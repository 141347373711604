<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
        DAFTAR PENDAPATAN DANA OUTLET
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama pemilik, nama outlet "
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0" v-if="items.length > 0">
          <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer" >
            SUMMARY
            <v-spacer></v-spacer>
          </v-card-title>
          <v-expand-transition>
            <v-row class="mb-2">
              <v-col
                cols="12"
                sm="4"
                lg="4"
                md="4"
                xs="12"
                v-for="(item, index) in summary"
                :key="index"
              >
                <v-skeleton-loader
                  class="ma-auto"
                  height="100"
                  :loading="process.run"
                  transition="scale-transition"
                  :tile="false"
                  type="image"
                >
                  <v-card 
                    :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                    class="box-shadow border-radius py-0">
                    <v-card-title class="subtitle-1 font-weight-bold">
                      {{ item.label }}
                      <v-spacer></v-spacer>
                      <v-tooltip left v-if="item.note.title">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="#D31145"
                            v-bind="attrs"
                            v-on="on"
                            class="cursor-pointer"
                            size="20"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <div v-html="item.note.content"></div>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text class="subtitle-2">
                      <p
                        class="text-second d-flex align-center"
                        style="font-size: 23px;">
                        <span class="mr-3 mt-3">
                          {{ item.value | price }}
                        </span>
                        <v-spacer />
                        <span>
                          <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                        </span>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card>

        <div v-if="items.length > 0" class="mt-4">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item,'history')"
                    target="_blank"
                    color="orange"
                    icon>
                    <v-icon>mdi-clipboard-text-clock</v-icon>
                  </v-btn>
                </template>
                <span>Lihat Riwayat Dana</span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <v-dialog v-model="dialog.history" persistent scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          RIWAYAT DANA MASUK DAN KELUAR PADA OUTLET {{ store_name }}
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.history =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 800px;">

          <!-- <v-row class="my-3 mb-6">
            <v-col
              cols="12"
              sm="4"
              lg="4"
              md="4"
              xs="12"
              v-for="(item, index) in summary"
              :key="index"
            >
              <v-skeleton-loader
                class="ma-auto"
                height="150"
                :loading="process.run"
                transition="scale-transition"
                :tile="false"
                type="image"
              >
                <v-card 
                  :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
                  class="box-shadow border-radius py-0">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    {{ item.title }}
                    <v-spacer></v-spacer>
                    <v-tooltip left v-if="item.help">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="#D31145"
                          v-bind="attrs"
                          v-on="on"
                          class="cursor-pointer"
                          size="20"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <div v-html="item.help"></div>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text class="subtitle-2">
                    <p
                      class="text-second d-flex align-center"
                      style="font-size: 25px;"
                    >
                      <span class="mr-3 mt-3">
                        {{ item.value }}
                      </span>
                      <v-spacer />
                      <span>
                        <v-icon size="40" :color="item.color">{{
                          item.icon
                        }}</v-icon>
                      </span>
                    </p>
                  </v-card-text>
                </v-card>
              </v-skeleton-loader>
            </v-col>
          </v-row> -->

          <v-data-table
            :headers="headers_history"
            :items="items_history"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options_history"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.datetime`]="{item}">
              {{ item.datetime | datetime }}
            </template>

            <template v-slot:[`item.type`]="{item}">
              <div :class="item.type === 'masuk' ? 'green--text font-weight-bold text-capitalize' : 'red--text font-weight-bold text-capitalize'">{{ item.type }}</div>
            </template>

            <template v-slot:[`item.value_label`]="{item}">
              <div :class="item.type === 'masuk' ? 'green--text font-weight-bold' : 'red--text font-weight-bold'">{{ item.value_label }}</div>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit_history = $event; fetchHistoryWallet()" 
            @changePage="page_activate = $event; fetchHistoryWallet($event)" 
            :model="pagination.activation"
            v-show="pagination.activation.data.total_page > 0"/>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { get } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
export default {
  components: {
    Empty,
    Pagination,
  },
  data () {
    return {
      searching: "",
      detail_outlet: {},
      summary: [],
      filter: {
      },
      process: {
        run: false
      },
      dialog: {
        history: false,
      },
      // DATA TABLES
      store_id: "",
      store_name: "",
      store_mobilephone: "",
      items: [],
      // * Pagination history
      items_history: [],
      limit_history: 10,
      page_history: 1,
      options_history:{},
      // * Pagination history end

      limit: 10,
      page: 1,
      options:{},
      pagination: {
        activation: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        activation: {
          menu: 0,
          pagination: 0  
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        },
        {
          text: 'Nama pemilik',
          value: 'owner_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Outlet',
          value: 'store_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Total Dana Masuk',
          value: 'in_label',
          align: 'end',
          width: 200
        },
        {
          text: 'Total Dana Keluar',
          value: 'out_label',
          align: 'end',
          width: 200
        },
        {
          text: 'Total Dana',
          value: 'balance_label',
          align: 'end',
          width: 200
        },
      ],
      headers_history: [
        {
          text: 'Tanggal',
          value: 'datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Tipe Dana',
          value: 'type',
          align: 'center',
          width: 30
        },
        {
          text: 'Total Dana',
          value: 'value_label',
          align: 'end',
          width: 100
        },
        {
          text: 'Catatan',
          value: 'note',
          align: 'center',
          width: 250
        },
        
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
    

    // * Activation Option
    'selected.activation.pagination': function(val) {
      this.fetchHistoryWallet(val+1)
    },
    options_history:{
      handler(){
        this.fetchHistoryWallet()
      },
      deep:true
    },
  },
  mounted() {
    this.fetch()
    this.fetchSummaryWallet()
  },
  methods: {
    actions(item, type) {
      console.log(item);
      switch (type) {
        case 'history':
          this.store_id = item.id
          this.store_name = item.store_name
          this.dialog.history = true
          this.fetchHistoryWallet()
          break;
      }
    },
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/store/wallet', {
        params: {
          page: paging,
          limit: this.limit,
          merchant_id: this.$route.params.id,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },
    async fetchSummaryWallet() {

      await get('internal/dashboard/store/wallet/summary')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.summary = res.results.data.data
        }
      })
    },
    async fetchHistoryWallet(paging) {
      await get('internal/dashboard/store/wallet/history', {
        params: {
          page: paging,
          limit: this.limit_history,
          store_id: this.store_id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_history = res.results.data
          this.pagination.activation.data = res.results.pagination
          this.pagination.activation.page = res.results.pagination.total_page < this.page ? 1 : ""
        }
      })
    },
  }
}
</script>