<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR TEMPLATE PESAN WHATSAPP
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan judul template"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>


        <v-btn
          @click="actions(null, 'add')"
          elevation="0"
          class="text-capitalize white--text"
          color="#D31145">
          <v-icon left>mdi-plus</v-icon>
          Tambah Template
        </v-btn>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.owner_name`]="{item}">
              <v-list-item>
                <v-list-item-avatar tile class="rounded-lg">
                  <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="item.owner_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.owner_email" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <template v-slot:[`item.store_count`]="{item}">
              {{ item.store_count | num_format }} Outlet
            </template>

            <template v-slot:[`item.user_count`]="{item}">
              {{ item.user_count | num_format }} User
            </template>

            <template v-slot:[`item.sales_order_total`]="{item}">
              {{ item.sales_order_total | price }}
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item, 'update')"
                    color="primary"
                    icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Ubah Template</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item, 'delete')"
                    color="red"
                    icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Hapus Template</span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Template Whatsapp Baru" : "Ubah Template Whatsapp" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-template'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan template pesan whatsapp" : "Ubah template pesan whatsapp" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-template">
            <v-form @:keyup.enter="save(dialog.form_type)">
              <ValidationProvider
                ref="form-title"
                name="Judul Template"
                rules="required|max:50|min:4"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Judul Template
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                </div>
                <v-text-field
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="50"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan judul template"
                  v-model="form.title"
                  :error-messages="errors"
                  required
                >
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider
                ref="form-text"
                name="Template Text"
                rules="required|max:500"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Template Text
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-on="on"
                        size="20"
                        class="ml-3"
                        v-bind="attrs"
                        color="orange"
                        @click="dialog.format_whatsapp = true;">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Informasi panduan penulisan template pesan</span>
                  </v-tooltip>
                </div>
                <v-textarea
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="500"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan template text sesuai dengan panduan penulisan,"
                  v-model="form.text"
                  :error-messages="errors"
                  required
                  autocomplete="off"
                >
                </v-textarea>
              </ValidationProvider>
            
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.format_whatsapp" persistent scrollable width="1000px">
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          INFORMASI FORMAT PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.format_whatsapp =false; dialog.follow_up = true;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3" style="height: 600px">

          <ol>
            <li v-for="(format, i) in whatsapp_format" :key="i">
              <v-list-item two-line dense>
                <v-list-item-content>
                  <v-list-item-title>{{ format.title }}</v-list-item-title>
                  <v-list-item-subtitle class="my-2">Contoh Penulisan: {{format.subtitle}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle v-html="format.hasil" class="body-2 font-weight-normal"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </li>
          </ol>

        </v-card-text>
        <v-card-actions class="caption">
          <p>No (1 - 3) sesuai pada FAQ Whatsapp di halaman <a href="https://faq.whatsapp.com/539178204879377/?helpref=uf_share" target="_blank">Whatsapp FAQ</a></p>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data template whatsapp
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data template whatsapp ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteTemplateWhatsapp()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
export default {
  components: {
    Empty,
    Pagination,
  },
  data () {
    return {
      searching: "",
      feedback: "",
      process: {
        run: false
      },
      dialog: {
        form: false,
        form_type: "",
        format_whatsapp: false,
        confirmation: false,
        confirmation_type: "",
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      form: {
        id: "",
        title: "",
        text: "",
      },
      whatsapp_format: [
        {
          title: "Tulisan Miring",
          subtitle: "_text_",
          hasil: "<i>text</i>",
        },
        {
          title: "Tulisan Tebal",
          subtitle: "*text*",
          hasil: "<b>text</b>",
        },
        {
          title: "Tulisan Dicoret",
          subtitle: "~text~",
          hasil: "<s>text</s>",
        },
        {
          title: "Nama Pemilik",
          subtitle: "{owner_name}",
          hasil: "memunculkan nama Pemilik pada pesan Anda",
        },
        {
          title: "Nama Outlet",
          subtitle: "{outlet_name}",
          hasil: "memunculkan nama Outlet pada pesan Anda",
        },
        {
          title: "Alamat Outlet",
          subtitle: "{outlet_address}",
          hasil: "memunculkan alamat outlet pada pesan Anda",
        },
        {
          title: "Tanggal Expired Outlet",
          subtitle: "{expired_date}",
          hasil: "memunculkan text tanggal expired sesuai outlet pada pesan Anda",
        },
      ],
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 10
        },
        {
          text: 'Judul Template',
          value: 'title',
          align: 'start',
          width: 150
        },
        {
          text: 'Template Text',
          value: 'messages',
          align: 'start',
          width: 300
        }
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    actions(item, method) {
      switch (method) {
        case "add":
          this.dialog.form = true
          this.dialog.form_type = "add"
          this.form = {
            id: "",
            title: "",
            text: "",
          }
          // this.$refs['validation-new-template'].reset();
          break;
        case "update":
          this.dialog.form = true
          this.dialog.form_type = "update"
          this.form = {
            id: item.id,
            title: item.title,
            text: item.messages,
          }
          break;
        case "delete":
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "delete"
          this.form = {
            id: item.id,
          }
          break;
      }
    },
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/wa_template', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          // this.totalData = res.results.pagination.total_data
          // this.pagination.data = res.results.pagination
          // this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }else {
          this.process.run = false;
        }
      })

      this.process.run = false
    },
    async deleteTemplateWhatsapp() {
      this.process.run = true

      await post(`internal/dashboard/wa_template/delete`, {
        data: {
          id: this.form.id
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.dialog.confirmation = false
          this.snackbar.show = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Berhasil menghapus data";
          this.fetch()
        }
      }).catch((error) => {
        this.snackbar.show = true;
        this.snackbar.color = "red";
        this.snackbar.text = "Terjadi kendala pada server.";
      })

      this.process.run = false
    },
    async save(type) {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-template"].validate();

      if (isValid) {
        this.process.run = true;

        let url = ""
        let data = {}
        if (type == "add") {
          url = `internal/dashboard/wa_template`
          data = {
            title: this.form.title,
            text: this.form.text,
          }
        }else {
          url = `internal/dashboard/wa_template/update`
          data = {
            id: this.form.id,
            title: this.form.title,
            text: this.form.text,
          }
        }

        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-template"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";

              this.fetch();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.run = false;
      }
    },
  },
}
</script>