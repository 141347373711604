<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR SALES ACCOUNT
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama, email, nomor handphone"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <v-btn
          @click="actions(null, 'add')"
          elevation="0"
          class="text-capitalize white--text"
          color="#D31145">
          <v-icon left>mdi-plus</v-icon>
          Tambah Akun Sales
        </v-btn>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-avatar tile class="rounded-lg">
                  <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/user-1669621149.png'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="item.name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.email" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.mobilephone`]="{item}">
              {{ item.mobilephone ? item.mobilephone : '-' }}
            </template>

            <template v-slot:[`item.province_name`]="{item}">
              {{ item.province_name ? item.province_name + ' - ' + item.city_name : '-' }}
            </template>

            <template v-slot:[`item.is_active_bool`]="{item}">
              <v-chip :color="item.is_active_bool ? 'teal' : 'red'" small class="white--text font-weight-bold">
                {{ item.is_active_bool ? "Aktif" : "Tidak aktif" }}
              </v-chip>
            </template>

            <template v-slot:[`item.komisi`]="{item}">
              <v-btn
                @click="actions(item, 'commission')"
                color="primary"
                class="text-capitalize"
                depressed
                small>
                Lihat Komisi Sales
              </v-btn>
            </template>

            <template v-slot:[`item.action`]="{item}">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="item.username == 'superuser'"
                    @click="actions(item, 'update')"
                    color="primary"
                    icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Ubah Account</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item, 'update_password')"
                    color="orange"
                    icon>
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-btn>
                </template>
                <span>Ubah Password Account</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :color="item.is_active_bool ? 'teal': 'pink'"
                    @click="actions(item, 'activation')"
                    icon>
                    <v-icon v-if="item.is_active_bool">mdi-toggle-switch-outline</v-icon>
                    <v-icon v-if="!item.is_active_bool">mdi-toggle-switch-off-outline</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ item.is_active_bool ? 'Non-aktifkan sales' : 'Aktifkan sales' }}
                </span>
              </v-tooltip>

            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Akun Sales Baru" : dialog.form_type == "update_password" ? "Ubah Password" : "Ubah Akun Sales" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-account'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan akun sales" : dialog.form_type == "update_password" ? "Ubah Password Akun Sales" : "Ubah akun sales" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-account">
            <v-form @:keyup.enter="save(dialog.form_type)">

              <v-row v-if="dialog.form_type !== 'update_password'">
                <v-col>
                  <ValidationProvider
                    ref="form-name"
                    name="Nama Lengkap"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Nama Lengkap
                      <small class="required"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan nama lengkap"
                      v-model="form.name"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-email"
                    name="Email"
                    rules="required"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      Email
                      <small class="required"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      :error-messages="errors"
                      :readonly="dialog.form_type == 'update'"
                      required
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan email"
                      v-model="form.email"
                      autocomplete="off">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row v-if="dialog.form_type == 'add' || dialog.form_type == 'update_password'">
                <v-col>
                  <ValidationProvider
                    ref="form-password"
                    name="Password"
                    vid="confirm"
                    rules="required|min:6|max:50"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      Password
                      <small class="required"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan password"
                      v-model="form.password"
                      :type="show_confirm_pass ? 'text' : 'password'"
                      :append-icon="show_confirm_pass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_confirm_pass = !show_pass"
                      :error-messages="errors"
                      required
                      autocomplete="off">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-password-confirm"
                    name="Konfirmasi Password"
                    rules="required|min:6|max:50|confirmed:confirm"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      Konfirmasi Password
                      <small class="required"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan konfirmasi password"
                      v-model="form.password_confirm"
                      :type="show_confirm_pass ? 'text' : 'password'"
                      :append-icon="show_confirm_pass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_confirm_pass = !show_pass"
                      :error-messages="errors"
                      required
                      autocomplete="off">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row v-if="dialog.form_type !== 'update_password'">
                <v-col>
                  <ValidationProvider
                    ref="form-mobilephone"
                    name="Nomor Handphone"
                    rules="required|min:8|max:15"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      No. Handphone
                      <small class="required"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      :error-messages="errors"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan no handphone"
                      v-model="form.mobilephone"
                      autocomplete="off">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <div class="subtitle-2 font-weight-bold">
                    Status Sales
                    <small class="opsional"><i>opsional</i></small>
                  </div>
                  <v-select
                    solo
                    flat
                    background-color="#EEEEEE"
                    :items="[{text: 'Aktif', value: true}, {text: 'Tidak aktif', value: false}]"
                    counter
                    maxlength="50"
                    dense
                    color="#d31145"
                    placeholder="Status Sales"
                    v-model="form.is_active_bool"
                    autocomplete="off">
                  </v-select>
                </v-col>
              </v-row>

              <v-row v-if="dialog.form_type !== 'update_password'">
                <v-col>
                  <ValidationProvider
                    ref="form-province_id"
                    name="Email"
                    rules="required"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      Pilih Provinsi
                      <small class="required"><i>*wajib diisi</i></small>
                    </div>
                    <v-autocomplete
                      solo
                      flat
                      dense
                      background-color="#EEEEEE"
                      label="Pilih Provinsi"
                      autocomplete="off"
                      color="#d31145"
                      :items="config.province"
                      :error-messages="errors"
                      item-value="id"
                      item-text="name"
                      item-color="red darken-1"
                      v-model="form.province_id"
                      counter
                      maxlength="50">
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-city_id"
                    name="Email"
                    rules="required"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      Pilih Kabupaten
                      <small class="required"><i>*wajib diisi</i></small>
                    </div>
                    <v-autocomplete
                      solo
                      flat
                      dense
                      background-color="#EEEEEE"
                      label="Pilih Kabupaten"
                      autocomplete="off"
                      color="#d31145"
                      :items="config.city"
                      :error-messages="errors"
                      item-value="id"
                      item-text="name"
                      item-color="red darken-1"
                      v-model="form.city_id"
                      counter
                      maxlength="50">
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row v-if="dialog.form_type !== 'update_password'">
                <v-col>
                  <div class="subtitle-2 font-weight-bold">
                    Alamat
                    <small class="opsional"><i>opsional</i></small>
                  </div>
                  <v-textarea
                    solo
                    flat
                    background-color="#EEEEEE"
                    counter
                    maxlength="50"
                    dense
                    color="#d31145"
                    placeholder="Tuliskan alamat"
                    v-model="form.address"
                    autocomplete="off">
                  </v-textarea>
                </v-col>
              </v-row>
            
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.success_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun sales ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteAccount()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

    <v-dialog v-model="dialog.commission_sales" persistent scrollable fullscreen>
      <v-card class="border-radius box-shadow">
        <v-card-title class="subtitle-1">
          Data Komisi Sales
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.commission_sales = false;">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="caption">
          Data Komisi yang didapatkan <b>{{ details.name }}</b>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pt-2">

          <Export
            class="mb-2"
            :disabled="process.run"
            :file_type="'xls'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="`internal/dashboard/sales/commission/export_excel?sales_id=${details.id}`"
            :title="'Data Komisi Sales'"
            ref="base-export-excel"
          />
          
          <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0" v-if="itemsCommission.length > 0">
            <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer" >
              SUMMARY
              <v-spacer></v-spacer>
              <!-- <v-btn
                text
                elevation="0"
                class="text-capitalize pink--text"
                small>
                {{ is_show_summary ? "Sembunyikan Summary" : "Lihat Summary" }}
                <v-icon right>{{ is_show_summary ? "mdi-arrow-up" : "mdi-arrow-down"}}</v-icon>
              </v-btn> -->
            </v-card-title>
            <v-expand-transition>
              <v-row class="mb-2" v-show="is_show_summary">
                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                  xs="12"
                  v-for="(item, index) in summaryCommission"
                  :key="index"
                >
                  <v-skeleton-loader
                    class="ma-auto"
                    height="100"
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    type="image"
                  >
                    <v-card 
                      :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                      class="box-shadow border-radius py-0">
                      <v-card-title class="subtitle-1 font-weight-bold">
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip left v-if="item.help">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#D31145"
                              v-bind="attrs"
                              v-on="on"
                              class="cursor-pointer"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div v-html="item.help"></div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text class="subtitle-2">
                        <p
                          class="text-second d-flex align-center"
                          style="font-size: 25px;">
                          <span class="mr-3 mt-3">
                            {{ item.value }}
                          </span>
                          <v-spacer />
                          <span>
                            <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                          </span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-card>

          <div v-if="itemsCommission.length > 0">

            <v-data-table
            :headers="headersCommission"
            :items="itemsCommission"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="optionsCommission"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.promo_name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.promo_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="`Nama Paket : ${item.package_name}`" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.store_name`]="{item}">
              <v-list-item class="px-0" three-line>
                <v-list-item-content>
                  <v-list-item-title v-html="item.store_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="`Owner : ${item.owner_name}`" class="caption"></v-list-item-subtitle>
                  <v-list-item-subtitle class="caption">
                    <div class="font-weight-bold" :class="item.package_subscribe_type == 'Year' ? 'primary--text' : item.package_subscribe_type == 'Month' ? 'purple--text' : 'pink--text'">
                      Aktivasi SODAPOS <span class="text-uppercase">{{ item.package_type }} </span> ({{ item.package_subscribe_label }})
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.package_discount_value`]="{item}">
              {{ item.package_discount_value | price }}
            </template>

            <template v-slot:[`item.commission_percent`]="{item}">
              {{ item.commission_percent | percent }}
            </template>
            
            <template v-slot:[`item.commission_nominal`]="{item}">
              <div class="font-weight-bold black--text">{{ item.commission_nominal }}</div>
            </template>

            <template v-slot:[`item.package_sales_promo_disc_value`]="{item}">
              {{ item.package_sales_promo_disc_value | price }}
            </template>

            <template v-slot:[`item.package_type`]="{item}">
              <div class="font-weight-bold" :class="item.package_subscribe_type == 'Year' ? 'primary--text' : item.package_subscribe_type == 'Month' ? 'purple--text' : 'pink--text'">
                Aktivasi SODAPOS <span class="text-uppercase">{{ item.package_type }} </span> <br>
                ({{ item.package_subscribe_label }})
              </div>
            </template>

            <template v-slot:[`item.commission_after_disct_bool`]="{item}">
              <div :class="item.commission_after_disct_bool ? 'teal--text' : 'red--text'" class="font-weight-bold">
                 {{ item.commission_after_disct_bool ? "Ya" : "Tidak" }}
              </div>
            </template>

          </v-data-table>

            <Pagination 
              @changeLimit="limitCommission = $event; fetchSalesCommission()" 
              @changePage="pageCommission = $event; fetchSalesCommission($event)" 
              :model="pagination.commission"
              v-show="pagination.commission.data.total_page > 0"/>
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
          </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Export,
    Empty,
  },
  data () {
    return {
      searching: "",
      feedback: "",
      is_show_summary: true,
      details: {},
      summaryCommission: {},
      process: {
        run: false
      },
      dialog: {
        form: false,
        form_type: "",
        confirmation: false,
        confirmation_type: "",
        success: false,
        success_type: "",
        commission_sales: false,
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      config: {
        province: [],
        city: [],
        subdistrict: [],
      },
      show_pass: false,
      show_confirm_pass: false,
      form: {
        id: "",
        name: "",
        email: "",
        address: "",
        mobilephone: "",
        image_url: "https://sodapos.com/media/2022/11/image/user-1669621149.png",
        password: "",
        password_confirm: "",
        province_id: "",
        province_name: "",
        city_id: "",
        city_name: "",
        is_active_bool: true
      },
      // DATA TABLES
      items: [],
      itemsCommission: [],
      limit: 10,
      page: 1,
      limitCommission: 10,
      pageCommission: 1,
      options:{},
      optionsCommission:{},
      pagination: {
        commission: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        commission: {
          pagination: 0
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        },
        {
          text: 'Nama lengkap',
          value: 'name',
          align: 'start',
          width: 200
        },
        {
          text: 'Kode Sales',
          value: 'code',
          align: 'center',
          width: 50
        },
        {
          text: 'No. Handphone',
          value: 'mobilephone',
          align: 'center',
          width: 100
        },
        {
          text: 'Domisili',
          value: 'province_name',
          align: 'center',
          width: 200
        },
        {
          text: 'Status',
          value: 'is_active_bool',
          align: 'center',
          width: 100
        },
        {
          text: 'Komisi',
          value: 'komisi',
          align: 'center',
          width: 50
        },
      ],
      headersCommission: [
       {
          text: 'Tanggal Transaksi',
          value: 'transaction_datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Kode Promo',
          value: 'promo_code',
          align: 'center',
          width: 150
        },
        {
          text: 'Nama Promo',
          value: 'promo_name',
          align: 'start',
          width: 150
        },
        {
          text: 'Nama Toko',
          value: 'store_name',
          align: 'start',
          width: 250
        },
        {
          text: 'Harga Paket',
          value: 'package_price',
          align: 'end',
          width: 150
        },
        {
          text: 'Diskon Paket',
          value: 'package_discount_value',
          align: 'end',
          width: 150
        },
        {
          text: 'Diskon Promo Sales',
          value: 'package_sales_promo_disc_value',
          align: 'end',
          width: 150
        },
        {
          text: 'Total Bayar Merchant',
          value: 'grand_total',
          align: 'end',
          width: 200
        },
        {
          text: 'Komisi Persen Sales',
          value: 'commission_percent',
          align: 'end',
          width: 150
        },
        {
          text: 'Dihitung Setelah Diskon',
          value: 'commission_after_disct_bool',
          align: 'center',
          width: 150
        },
        {
          text: 'Total Komisi Sales',
          value: 'commission_nominal',
          align: 'end',
          width: 150
        },
      ]
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },

    // SALES COMMISSION
    'selected.commission.pagination': function(val) {
      this.fetchSalesCommission(val+1)
    },
    optionsCommission:{
      handler(){
        this.fetchSalesCommission()
      },
      deep:true
    },
    'form.province_id': function() {
      let t = this
      let p = this.config.province.filter(function(v) {
        return t.form.province_id == v.id
      })

      if (p.length > 0) {
        this.form.province_name = p[0].name
      }

      this.setting().city()
    },
    'form.city_id': function() {
      let t = this

      console.log(t.form.city_id);
      let c = this.config.city.filter(function(v) {
        return t.form.city_id == v.id
      })

      if (c.length > 0) {
        this.form.city_name = c[0].name
      }
    },
  },
  mounted() {
    this.fetch()
    this.setting().province()
  },
  methods: {
    actions(item, method) {
      switch (method) {
        case "add":
          this.dialog.form = true
          this.dialog.form_type = "add"
          this.form = {
            id: "",
            name: "",
            username: "",
            email: "",
            mobilephone: "",
            password: "",
            password_confirm: "",
          }
          // this.$refs['validation-new-account'].reset();
          break;
        case "update":
          this.dialog.form = true
          this.dialog.form_type = "update"
          this.form = {
            id: item.id,
            name: item.name,
            username: item.username,
            email: item.email,
            mobilephone: item.mobilephone,
            province_id: item.province_id,
            province_name: item.province_name,
            city_id: item.city_id,
            city_name: item.city_name,
            is_active_bool: item.is_active_bool
          }
          break;
        case "update_password":
          this.dialog.form = true
          this.dialog.form_type = "update_password"
          this.form = {
            id: item.id,
            password: "",
            password_confirm: "",
          }
          break;
        case 'activation':
          // this.details = item
          this.activationSales(item)
          // this.dialog.success = true
          this.dialog.confirmation_type = "activation"
          break;
        case 'commission':
          this.dialog.commission_sales = true
          this.details = item
          this.fetchSalesCommission()
          this.fetchSalesCommissionSummary()
          break;
      }
    },
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/sales', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })

      this.process.run = false
    },
    async fetchSalesCommission(paging) {
      this.process.run = true

      await get('internal/dashboard/sales/commission', {
        params: {
          page: paging,
          limit: this.limitCommission,
          search: this.searching,
          sales_id: this.details.id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.itemsCommission = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.commission.data = res.results.pagination
          this.pagination.commission.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })

      this.process.run = false
    },
    async fetchSalesCommissionSummary() {
      await get('internal/dashboard/sales/commission/summary', {
        params: {
          sales_id: this.details.id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.summaryCommission = res.results.data
        }
      })
    },
    setting () {
      let t = this
      return {
        async province() {
          get(`internal/dashboard/area/province`, {
            params: {
              pagination_bool:false,
            }
          })
          .then(response => {
            let res = response.data
            if (res.status == 200) {
              t.config.province = res.results.data
            }
          })
        },
        async city() {
          get(`internal/dashboard/area/city`, {
            params: { 
              pagination_bool:false,
              province_id: t.form.province_id
            }
          })
          .then(response => {
            let res = response.data
            if (res.status == 200) {
              t.config.city = res.results.data
            }
          })
        },
      }
    },
    async activationSales(item) {
      await post(`internal/dashboard/sales/activate`,{
        data: {
          sales_id: item.id,
          is_active_bool: item.is_active_bool ? false : true
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.dialog.success = true
          this.fetch()
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Gagal mengubah status promo"
          }
        }
      }).catch((error) => {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Terjadi kendala pada server."
        }
      })
    },
    async save(type) {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-account"].validate();

      if (isValid) {
        this.process.run = true;

        let url = ""
        let data = {}
        if (type == "add") {
          url = `internal/dashboard/sales/register`
          data = {
            name: this.form.name,
            email: this.form.email,
            mobilephone: this.form.mobilephone,
            type: "Internal",
            password: this.form.password,
            address: this.form.address,
            province_id: this.form.province_id,
            province_name: this.form.province_name,
            city_id: this.form.city_id,
            city_name: this.form.city_name,
            is_active_bool: this.form.is_active_bool,
            image_url: "https://sodapos.com/media/2022/11/image/user-1669621149.png",
            coordinate_object: {}
          }
        }else if (type == "update") {
          url = `internal/dashboard/sales/update`
          data = {
            id: this.form.id,
            name: this.form.name,
            email: this.form.email,
            mobilephone: this.form.mobilephone,
            type: "Internal",
            password: this.form.password,
            address: this.form.address,
            province_id: this.form.province_id,
            province_name: this.form.province_name,
            city_id: this.form.city_id,
            city_name: this.form.city_name,
            is_active_bool: this.form.is_active_bool,
            image_url: "https://sodapos.com/media/2022/11/image/user-1669621149.png",
            coordinate_object: {}
          }
        }else {
          url = `internal/dashboard/sales/reset_password`
          data = {
            id: this.form.id,
            password: this.form.password,
            password_confirm: this.form.password_confirm,

          }
        }

        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-account"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = res.message;

              this.fetch();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                this.process.run = false

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
              this.process.run = false
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
            this.process.run = false;
          });
        this.process.run = false;
      }
    },
  }
}
</script>