<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR AKUN FRANCHISE/WAREHOUSE
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama Franchise, email, no telepon"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <v-btn
          @click="actions(null, 'add')"
          elevation="0"
          class="text-capitalize white--text"
          color="#D31145">
          <v-icon left>mdi-plus</v-icon>
          Tambah Akun Franchise
        </v-btn>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg table-with-actions">

            <template v-slot:[`item.name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-avatar tile class="rounded-lg">
                  <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.email`]="{item}">
              {{ item.email }}
            </template>

            
            <template v-slot:[`item.phone`]="{item}">
              {{ item.phone ? item.phone : "-" }}
            </template>

            <template v-slot:[`item.action`]="{item}">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :href="item.url_franchise"
                    target="_blank"
                    color="#d31145"
                    icon>
                    <v-icon>mdi-login-variant</v-icon>
                  </v-btn>
                </template>
                <span>Auto Login Franchise Sistem</span>
              </v-tooltip> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item, 'store')"
                    target="_blank"
                    color="teal"
                    icon>
                    <v-icon>mdi-store</v-icon>
                  </v-btn>
                </template>
                <span>Daftar Outlet</span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="500">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Akun Franchise Baru" : "Tambah Outlet" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-account-franchise'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan akun franchise" : "Tambahkan outlet ke dalam franchise" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4" v-if=" dialog.form_type == 'add'">
          <ValidationObserver ref="validation-new-account-franchise">
            <v-form @:keyup.enter="save(dialog.form_type)">

              <div style="position: relative; width: 150px; height: 150px; overflow: hidden; margin: 0 auto; display: flex;justify-content: center;align-items: center;">
                  <input type="file" id="image" @change="onImageChangeHandler" ref="ref_inputImage" accept="image/jpg, image/png, image/jpeg" style="display: none" />
                  <img :src="form.image_url" alt="image" className="avatar" referrerPolicy="no-referrer" style="width: 150px; height: 150px; border-radius: 10px; object-fit: cover; align-items: center; justify-content: center; cursor: pointer" @click="$refs.ref_inputImage.click()"/>
                  <span className="button" style="position: absolute; top: 10; right: 10; font-size: 25; cursor: pointer; background-color: rgba(0,0,0,0.4); padding: 6px 12px; border-radius: 8px" @click="$refs.ref_inputImage.click()">
                      <v-icon>mdi-camera</v-icon>
                  </span>
              </div>


              <ValidationProvider
                ref="form-name"
                name="Nama Franchise"
                rules="required|max:50"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Nama Franchise
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                </div>
                <v-text-field
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="50"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan nama franchise"
                  v-model="form.name"
                  :error-messages="errors"
                  required
                >
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider
                ref="form-email"
                name="Nama Lengkap"
                rules="required|max:50"
                v-slot="{ errors }"
              >
                  <div class="subtitle-2 font-weight-bold">
                    Email
                    <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                  </div>
                  <v-text-field
                    solo
                    flat
                    background-color="#EEEEEE"
                    counter
                    maxlength="50"
                    dense
                    color="#d31145"
                    placeholder="Tuliskan email"
                    v-model="form.email"
                    :error-messages="errors"
                    autocomplete="off">
                  </v-text-field>
              </ValidationProvider>

              <ValidationProvider
                ref="form-password"
                name="Password"
                vid="confirm"
                rules="required|min:6|max:50"
                v-slot="{ errors }">
                <div class="subtitle-2 font-weight-bold">
                  Password
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                </div>
                <v-text-field
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="50"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan password"
                  v-model="form.password"
                  :type="show_confirm_pass ? 'text' : 'password'"
                  :append-icon="show_confirm_pass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_confirm_pass = !show_pass"
                  :error-messages="errors"
                  required
                  autocomplete="off">
                </v-text-field>
              </ValidationProvider>
              
              <ValidationProvider
                ref="form-password-confirm"
                name="Konfirmasi Password"
                rules="required|min:6|max:50|confirmed:confirm"
                v-slot="{ errors }">
                <div class="subtitle-2 font-weight-bold">
                  Konfirmasi Password
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                </div>
                <v-text-field
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="50"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan konfirmasi password"
                  v-model="form.password_confirm"
                  :type="show_confirm_pass ? 'text' : 'password'"
                  :append-icon="show_confirm_pass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_confirm_pass = !show_pass"
                  :error-messages="errors"
                  required
                  autocomplete="off">
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider
                ref="form-phone"
                name="Nomor Handphone"
                rules="required|min:8|max:15"
                v-slot="{ errors }">
                <div class="subtitle-2 font-weight-bold">
                  No. Handphone
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                </div>
                <v-text-field
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="50"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan no handphone"
                  v-model="form.phone"
                  :error-messages="errors"
                  autocomplete="off">
                </v-text-field>
              </ValidationProvider>
            
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-card-text class="pa-4" v-if=" dialog.form_type == 'add_outlet'">
          <ValidationObserver ref="validation-new-account-franchise">
            <v-form @:keyup.enter="save(dialog.form_type)">

              <ValidationProvider
                ref="form-store_array"
                name="Pilih Toko"
                rules="required|max:50"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Pilih Toko
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                </div>
                <v-autocomplete
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="50"
                  dense
                  color="#d31145"
                  placeholder="Pilih Toko"
                  v-model="form.store_array"
                  :items="store_select"
                  item-text="name"
                  item-value="id"
                  return-object
                  multiple
                  chips
                  :error-messages="errors"
                  required>
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="Object.keys(data.item).length < 1">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content style="border-bottom: 1px solid #ddd">
                        <v-list-item-title v-html="data.item.name" class="font-weight-bold subtitle-2"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.owner_name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
            
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data paket
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data paket ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deletePromo()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG FRANCHISE STORE ==== -->
    <v-dialog v-model="dialog.store" persistent scrollable fullscreen>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-3 font-weight-bold text-uppercase">
          DATA OUTLET FRANCHISE ({{ form.franchise_name }})
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.store =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 800px;">

          <v-btn
            @click="actions(details, 'add_outlet')"
            elevation="0"
            class="text-capitalize white--text mt-3"
            color="#D31145">
            <v-icon left>mdi-plus</v-icon>
            Tambah Outlet
          </v-btn>

          <div v-if="items_store.length > 0">
            <v-data-table
              :headers="headers_store"
              :items="items_store"
              disable-sort
              hide-default-footer
              disable-pagination 
              :options.sync="options_store"
              :loading="process.run"
              loading-text="Sedang memuat"
              no-data-text="Data tidak tersedia"
              no-results-text="Data tidak tersedia"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              fixed-header
              flat
              class="elevation-0 rounded-lg">

              <template v-slot:[`item.name`]="{item}">
                <v-list-item class="px-0">
                  <v-list-item-avatar tile class="rounded-lg">
                    <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">
                      {{ item.name }} <strong class="red--text">{{ item.is_trial_bool == true ? '(Trial)' : '' }}</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="item.category_name" class="caption"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.user_name`]="{item}">
                <v-list-item class="pl-0">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.user_name" class="subtitle-2"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.user_email" class="caption"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.wh_active_bool`]="{item}">
                <div class="text-center font-weight-bold" >
                  <v-chip color="red" style="color: white;" v-if="!item.wh_active_bool">Tidak Aktif</v-chip>
                  <v-chip color="teal" style="color: white;" v-else-if="item.wh_active_bool && !item.wh_expired_datetime">Aktif Selamanya</v-chip>
                  <v-chip color="#e65100" style="color: white;" v-else>Aktif Sampai {{ item.wh_expired_datetime | datetime }}</v-chip>
                </div>
              </template>

              <template v-slot:[`item.join_datetime`]="{item}">
                {{ item.join_datetime | datetime }}
              </template>

            </v-data-table>

            <Pagination 
              @changeLimit="limit_store = $event; fetchDataStore()" 
              @changePage="page_activate = $event; fetchDataStore($event)" 
              :model="pagination.store"
              v-show="pagination.store.data.total_page > 0"/>
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
          </div>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import moment from 'moment'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
export default {
  components: {
    Empty,
    Pagination,
  },
  data () {
    return {
      searching: "",
      feedback: "",
      show_pass: false,
      show_confirm_pass: false,
      details: {},
      process: {
        run: false
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      dialog: {
        confirmation: false,
        confirmation_type: "",
        success: false,
        form: false,
        form_type: "",
        store: false,
      },
      form: {
        id: "",
        name: "",
        username: "",
        email: "",
        phone: "",
        image_url: "",
        password: "",
        password_confirm: "",

        store_array: [],
        franchise_id: "",
        franchise_name: "",
      },

      store_select: [],

      // DATA TABEL FRANCHISE STORE
      items_store: [],
      limit_store: 10,
      page_store: 1,
      options_store:{},
      headers_store: [
        {
          text: 'Tanggal Bergabung',
          value: 'join_datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Nama Outlet',
          value: 'name',
          align: 'start',
          width: 200
        },
         {
          text: 'Nama pemilik',
          value: 'user_name',
          align: 'start',
          width: 200
        },
        {
          text: 'No. Handphone',
          value: 'mobilephone',
          align: 'center',
          width: 150
        },
        {
          text: 'Alamat',
          value: 'address',
          align: 'start',
          width: 250
        },
        {
          text: 'Status Berlangganan Outlet',
          value: 'wh_active_bool',
          align: 'center',
          width: 250
        },
      ],
      // DATA TABEL
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        store: {
        data: {},
        page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        store: {
          menu: 0,
          pagination: 0
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 100
        },
        {
          text: 'Nama Franchise',
          value: 'name',
          align: 'start',
          width: 200
        },
        {
          text: 'Kode',
          value: 'code',
          align: 'center',
          width: 150
        },
        {
          text: 'Email',
          value: 'email',
          align: 'center',
          width: 150
        },
        {
          text: 'No. Telepon',
          value: 'phone',
          align: 'center',
          width: 200
        }
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
  },
  computed: {
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/franchise', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },

    async fetchOutletSelect() {
      console.log('ser');
      this.process.run = true

      await get('internal/dashboard/franchise/store', {
        params: {
          pagination_bool: false
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.store_select = res.results.data
          this.process.run = false
        }
      })
    },

    async fetchDataStore(paging) {
      this.process.run = true

      await get('internal/dashboard/franchise/fr-store', {
        params: {
          franchise_id: this.form.franchise_id,
          page: paging,
          limit: this.limit_store,
          // search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_store = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.store.data = res.results.pagination
          this.pagination.store.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },

    onImageChangeHandler(e) {
      var files = e.target.files || e.dataTransfer.files;

      let formData = new FormData()
      formData.append("key", "59e51e5db668184d8c4a085fb8389c3acfd76a50")
      formData.append("image", files[0])
      formData.append("name", `${this.form.name ? this.form.name : `Gambar-Promosi-${moment().format('YYYYmmdd')}`}`)
      
      fetch(`https://sodapos.com/media/image.php`, {
        method: "POST",
        body: formData
      }).then(res => res.json())
      .then(respon => {
      if (respon.status === 200) {
        this.form.image_url = respon.data.fileuri
      }
      })
    },

    remove (item) {
      const index = this.form.store_array.findIndex(
        (v) => v.id === item.id
      );
      if (index >= 0) this.form.store_array.splice(index, 1);
    },

    actions(item, method) {
      switch (method) {
        case "add":
          this.dialog.form = true
          this.dialog.form_type = "add"
          this.form = {
            id: "",
            name: "",
            email: "",
            phone: "",
            password: "",
            password_confirm: "",
            image_url: "https://sodapos.com/media/2022/11/image/user-1669621149.png",
          }
          break;
        case "add_outlet":
          this.dialog.form = true
          this.dialog.form_type = "add_outlet"
          this.form = {
            id: item.id,
            store_array: []
          }
          this.fetchOutletSelect()
          break;
        case "store":
          this.dialog.store = true;
          this.form = {
            franchise_id: item.id,
            franchise_name: item.name
          }
          this.details = item
          this.fetchDataStore()
          break;
      }
    },

    async save(type) {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-account-franchise"].validate();

      if (isValid) {
        this.process.run = true;

        let url = ""
        let data = {}
        if (type == "add") {
          url = `internal/dashboard/franchise/register`
          data = this.form
        }else {
          url = `internal/dashboard/franchise/add-store`
          data = this.form
        }

        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-account-franchise"].reset();

              if (type == 'add_outlet') {
                this.fetchDataStore()
              }

              Object.assign(this.form, {
                id: "",
                name: "",
                username: "",
                email: "",
                phone: "",
                image_url: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";

              this.fetch();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
                this.process.run = false;
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
              this.process.run = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.run = false;
      }
    },
  }
}
</script>