<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR PESAN MASUK
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama, email, nomor handphone"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg tablke-with-action">

            <template v-slot:[`item.name`]="{item}">
              <v-list-item :to="`store-detail/${item.id}`" class="px-0">
                <v-list-item-avatar tile class="rounded-lg">
                  <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/user-1669621149.png'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle class="caption">{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.mobilephone`]="{item}">
              {{ item.mobilephone ? item.mobilephone : '-' }}
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Akun Internal Baru" : "Ubah Akun Internal" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-account'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan akun internal" : "Ubah akun internal" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-account">
            <v-form @:keyup.enter="save(dialog.form_type)">

              <v-row v-if="dialog.form_type !== 'update_password'">
                <v-col>
                  <ValidationProvider
                    ref="form-name"
                    name="Nama Lengkap"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Nama Lengkap
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan nama lengkap"
                      v-model="form.name"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-username"
                    name="Username"
                    rules="required|min:4|max:50"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      Username
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan username"
                      v-model="form.username"
                      :error-messages="errors"
                      required
                      autocomplete="off">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row v-if="dialog.form_type == 'add' || dialog.form_type == 'update_password'">
                <v-col>
                  <ValidationProvider
                    ref="form-password"
                    name="Password"
                    vid="confirm"
                    rules="required|min:6|max:50"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      Password
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan password"
                      v-model="form.password"
                      :type="show_confirm_pass ? 'text' : 'password'"
                      :append-icon="show_confirm_pass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_confirm_pass = !show_pass"
                      :error-messages="errors"
                      required
                      autocomplete="off">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-password-confirm"
                    name="Konfirmasi Password"
                    rules="required|min:6|max:50|confirmed:confirm"
                    v-slot="{ errors }">
                    <div class="subtitle-2 font-weight-bold">
                      Konfirmasi Password
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan konfirmasi password"
                      v-model="form.password_confirm"
                      :type="show_confirm_pass ? 'text' : 'password'"
                      :append-icon="show_confirm_pass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_confirm_pass = !show_pass"
                      :error-messages="errors"
                      required
                      autocomplete="off">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row v-if="dialog.form_type !== 'update_password'">
                <v-col>
                  <div class="subtitle-2 font-weight-bold">
                    Email
                    <small class="opsional"><i>opsional</i></small>
                  </div>
                  <v-text-field
                    solo
                    flat
                    background-color="#EEEEEE"
                    counter
                    maxlength="50"
                    dense
                    color="#d31145"
                    placeholder="Tuliskan email"
                    v-model="form.email"
                    autocomplete="off">
                  </v-text-field>
                </v-col>
                <v-col>
                  <div class="subtitle-2 font-weight-bold">
                    No. Handphone
                    <small class="opsional"><i>opsional</i></small>
                  </div>
                  <v-text-field
                    solo
                    flat
                    background-color="#EEEEEE"
                    counter
                    maxlength="50"
                    dense
                    color="#d31145"
                    placeholder="Tuliskan no handphone"
                    v-model="form.mobilephone"
                    autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
            
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.success_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun intenal ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteAccount()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
export default {
  components: {
    Empty,
    Pagination,
  },
  data () {
    return {
      searching: "",
      feedback: "",
      process: {
        run: false
      },
      dialog: {
        form: false,
        form_type: "",
        confirmation: false,
        confirmation_type: "",
        success: false,
        success_type: "",
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      show_pass: false,
      show_confirm_pass: false,
      form: {
        id: "",
        name: "",
        username: "",
        email: "",
        mobilephone: "",
        image_url: "https://sodapos.com/media/2022/11/image/user-1669621149.png",
        password: "",
        password_confirm: "",
      },
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Nama lengkap',
          value: 'name',
          align: 'start',
          width: 200
        },
        {
          text: 'No. Handphone',
          value: 'mobilephone',
          align: 'center',
          width: 150
        },
        {
          text: 'Isi Pesan',
          value: 'message',
          align: 'start',
          width: 200
        },
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    actions(item, method) {
      switch (method) {
        case "add":
          this.dialog.form = true
          this.dialog.form_type = "add"
          this.form = {
            id: "",
            name: "",
            username: "",
            email: "",
            mobilephone: "",
            password: "",
            password_confirm: "",
          }
          // this.$refs['validation-new-account'].reset();
          break;
        case "update":
          this.dialog.form = true
          this.dialog.form_type = "update"
          this.form = {
            id: item.id,
            name: item.name,
            username: item.username,
            email: item.email,
            mobilephone: item.mobilephone,
            // password: item.password
          }
          break;
        case "update_password":
          this.dialog.form = true
          this.dialog.form_type = "update_password"
          this.form = {
            username: item.username,
            password: "",
            password_confirm: "",
          }
          break;
        case 'delete':
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "delete"
          this.form.id = item.id
          break;
      }
    },
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/landing-page/contact-us', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },
    async save(type) {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-account"].validate();

      if (isValid) {
        this.process.run = true;

        let url = ""
        let data = {}
        if (type == "add") {
          url = `internal/dashboard/register`
          data = {
            name: this.form.name,
            username: this.form.username,
            email: this.form.email,
            mobilephone: this.form.mobilephone,
            password: this.form.password,
            image_url: "https://sodapos.com/media/2022/11/image/user-1669621149.png",
          }
        }else if(type == "update") {
          url = `internal/dashboard/account/update`
          data = {
            id: this.form.id,
            name: this.form.name,
            username: this.form.username,
            email: this.form.email,
            mobilephone: this.form.mobilephone,
            image_url: "https://sodapos.com/media/2022/11/image/user-1669621149.png",
          }
        }else {
          url = `internal/dashboard/account/update_password`
          data = {
            username: this.form.username,
            password: this.form.password,
          }
        }

        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-account"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";

              this.fetch();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                this.process.run = false

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
              this.process.run = false
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
            this.process.run = false;
          });
        this.process.run = false;
      }
    },
    async deleteAccount(item) {
      await post(`internal/dashboard/account/delete`,{
        data: {
          id: this.form.id,
        }
      }).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.dialog.confirmation = false
          this.dialog.confirmation_type = 'delete'
          this.dialog.success = true
          this.dialog.success_type = 'delete'
          this.fetch()
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: ""
          }
          Object.keys(res.results.data).forEach(item => {
            this.snackbar.text += res.results.data[item] + `</br>`;
          })
        }
      }).catch((error) => {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Terjadi kendala pada server."
        }
      })
    },
  }
}
</script>