<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        <div>
          DAFTAR PENARIKAN KOMISI
          <div class="mt-1 d-flex">
            <Export
              class="mr-2"
              :disabled="process.run"
              :file_type="'xls'"
              :btnText="'Export Excel'"
              :btnIcon="'mdi-microsoft-excel'"
              :btnColor="'teal'"
              :url="'internal/dashboard/sales/commission/withdraw/export_excel'"
              :title="'Data Penarikan Komisi'"
              ref="base-export-excel"
            />
            <v-btn
              elevation="0"
              color="#d31145"
              :fab="$vuetify.breakpoint.name === 'xs' ? true : false"
              :x-small="$vuetify.breakpoint.name === 'xs' ? true : false"
              class="white--text text-capitalize rounded-lg"
              @click="actions('update_configuration', null)">
              <v-icon>mdi-cog</v-icon>
              <span v-if="$vuetify.breakpoint.name !== 'xs'">
                Ubah Konfigurasi
              </span>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-card-text>

        <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0" v-if="items.length > 0">
          <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer" >
            SUMMARY
            <v-spacer></v-spacer>
          </v-card-title>
          <v-expand-transition>
            <v-row class="mb-2" v-show="is_show_summary">
              <v-col
                cols="12"
                sm="3"
                lg="3"
                md="3"
                xs="12"
                v-for="(item, index) in summary_withdraw"
                :key="index"
              >
                <v-skeleton-loader
                  class="ma-auto"
                  height="100"
                  :loading="process.run"
                  transition="scale-transition"
                  :tile="false"
                  type="image"
                >
                  <v-card 
                    :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                    class="box-shadow border-radius py-0">
                    <v-card-title class="subtitle-1 font-weight-bold">
                      {{ item.label }}
                      <v-spacer></v-spacer>
                      <v-tooltip left v-if="item.help">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="#D31145"
                            v-bind="attrs"
                            v-on="on"
                            class="cursor-pointer"
                            size="20"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <div v-html="item.help"></div>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text class="subtitle-2">
                      <p
                        class="text-second d-flex align-center"
                        style="font-size: 23px;">
                        <span class="mr-3 mt-3">
                          {{ item.value }}
                        </span>
                        <v-spacer />
                        <span>
                          <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                        </span>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card>

        <v-row class="mt-3">
          <v-col cols="12" md="6" class="d-flex">
            <v-text-field
              outlined
              dense
              color="#D31145"
              placeholder="Cari berdasarkan nama sales, kode sales"
              style="width: 300px;"
              class="font-weight-normal"
              v-model="searching">
            </v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1 ml-3"
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="dialog.filtered = true">
                  <v-icon size="25">mdi-filter-outline</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <div v-if="items.length > 0" class="mt-4">

          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.commission`]="{item}">
              {{ item.commission }}
              <v-btn 
                @click="actions('sales_commission', item)"
                text
                small
                color="#0D47A1"
                class="subtitle-2 font-weight-normal text-capitalize">
                Lihat
              </v-btn>
            </template>

            <template v-slot:[`item.input_datetime`]="{item}">
              {{ item.input_datetime | datetime }}
            </template>

            <template v-slot:[`item.tax_nominal_label`]="{item}">
              <div :class="item.status == 'Dibatalkan' || item.status == 'Ditolak' ? 'error--text text-decoration-line-through' : ''">{{ item.tax_nominal_label }}</div>
            </template>

            <template v-slot:[`item.paid_nominal`]="{item}">
              <div :class="item.status == 'Dibatalkan' || item.status == 'Ditolak' ? 'error--text text-decoration-line-through' : ''">{{ item.paid_nominal | price }}</div>
            </template>

            <template v-slot:[`item.reject_note`]="{item}">
              <div :title="item.reject_note">{{ item.reject_note ? item.reject_note : item.sales_cancel_note ? item.sales_cancel_note : '-' }}</div>
            </template>

            <template v-slot:[`item.user`]="{item}">
              {{ item.reject_user_name ? item.reject_user_name : item.accept_user_name ? item.accept_user_name : item.status == 'Dibatalkan' ? "Pembatalan Mandiri" : "-" }}
            </template>
            
            <template v-slot:[`item.user_datetime`]="{item}">
              {{ (item.reject_datetime ? item.reject_datetime : item.sales_cancel_datetime ? item.sales_cancel_datetime : item.accept_datetime  ? item.accept_datetime : item.status_datetime) | datetime }}
            </template>
            
            <template v-slot:[`item.sales_bank_label`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.sales_bank_label" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle class="caption">{{ item.sales_bank_account_number }} <v-icon right small @click="actions('copy', item.sales_bank_account_number)">mdi-content-copy</v-icon></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.status`]="{item}">
              <v-chip
                class="font-weight-bold white--text"
                :color="item.status == 'Menunggu Diproses' ? 'warning' : item.status == 'Dibatalkan' ? 'red darken-1' : item.status == 'Ditolak' ? 'error' : 'success'">
                {{ item.status }}
              </v-chip>
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="item.status != 'Menunggu Diproses'"
                    color="green"
                    @click="actions('approve', item)"
                    icon>
                    <v-icon>mdi-check-decagram</v-icon>
                  </v-btn>
                </template>
                <span>
                  Terima Pengajuan Penarikan Komisi
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="item.status != 'Menunggu Diproses'"
                    @click="actions('reject', item)"
                    color="red"
                    icon>
                    <v-icon>mdi-close-octagon</v-icon>
                  </v-btn>
                </template>
                <span>
                  Tolak Pengajuan Penarikan Komisi
                </span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- === DIALOG FILTER === -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="550px">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #d31145;"
        >
          <span class="white--text">
            Filter
          </span>
        </v-card-title>
        <v-card-text class="py-3" style="height: 550px;">
           <v-select
            dense
            outlined
            v-model="filter.status"
            item-text="text"
            item-value="value"
            placeholder="Filter berdasarkan status penarikan komisi"
            :items="[
              {text: 'Menunggu Diproses', value: 'Menunggu Diproses'}, 
              {text: 'Sudah Diproses', value: 'Sudah Diproses'},
              {text: 'Ditolak', value: 'Ditolak'},
              {text: 'Dibatalkan', value: 'Dibatalkan'},
            ]">
          </v-select>
          <VueCtkDateTimePicker
            color="#d31145"
            onlyDate
            :range="true"
            right
            v-model="filter.filter_date"
            label="Pilih Tanggal"
            :noClearButton="true"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="new Date().toISOString().substr(0, 10)"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
            ]"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="filterData()"
          >
            Filter
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            color="orange"
            class="white--text text-capitalize"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="dialog.filtered = false;"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.success_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun intenal ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteAccount()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

    <!-- DIALOG APPROVAL AND REJECT WITHDRAW -->
    <v-dialog v-model="dialog.withdraw_approval" scrollable persistent width="500">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.withdraw_approval_type == "approve" ?  "Terima pengajuan penarikan komisi" : "Tolak pengajuan penarikan komisi" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.withdraw_approval = false;">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.withdraw_approval_type == "approve" ? "Terima pengajuan penarikan komisi" : "Tolak pengajuan penarikan komisi" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="pa-5" v-if="dialog.withdraw_approval_type == 'approve'">
          <div class="subtitle-2 font-weight-bold">
            Nominal Potongan atau Biaya Admin
          </div>
          <v-currency-field
            id="price"
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Tuliskan nominal potongan"
            persistent-hint
            hint="Nominal potongan atau biaya admin dapat diubah"
            type="number"
            color="#d31145"
            v-model="form.tax_nominal"
          />
        </v-card-text>
        <v-card-text class="pa-5" v-else>
          <div class="subtitle-2 font-weight-bold">
            Catatan penolakan
          </div>
          <v-textarea
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Tuliskan catatan penolakan pengajuan penarikan komisi"
            color="#d31145"
            v-model="form.note">
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="saveRequest(dialog.withdraw_approval_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG UPDATE CONFIGURATION WITHDRAW -->
    <v-dialog v-model="dialog.configuration_withdraw" scrollable persistent width="500">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          Ubah Konfigurasi Pengajuan Penarikan Komisi
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.configuration_withdraw = false;">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          Ubah Konfigurasi penarikan komisi
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="pa-5">

          <div>
            Judul Biaya Admin atau Potongan
          </div>
          <v-text-field
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Silahkan masukkan judul untuk biaya"
            color="#d31145"
            return-object
            v-model="form.tax_title">
          </v-text-field>

          <div>
            Nominal {{ form.tax_title ? form.tax_title : "Biaya Admin" }} (Rp)
          </div>
          <v-currency-field
            id="price"
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Tuliskan nominal potongan"
            type="number"
            color="#d31145"
            v-model="form.tax_nominal"
          />

          <div>
            Nominal minimal penarikan (Rp)
          </div>
          <v-currency-field
            id="price"
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Tuliskan nominal minimal penarikan"
            type="number"
            color="#d31145"
            v-model="form.minimal"
          />

          <div>
            Nominal maksimal penarikan (Rp)
          </div>
          <v-currency-field
            id="price"
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Tuliskan nominal maksimal penarikan"
            type="number"
            color="#d31145"
            v-model="form.maximal"
          />

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="saveRequest('configuration')">
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DATA KOMISI SALES -->
    <v-dialog v-model="dialog.commission_sales" persistent scrollable fullscreen>
      <v-card class="border-radius box-shadow">
        <v-card-title class="subtitle-1">
          Data Komisi Sales
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.commission_sales = false;">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="caption">
          Data Komisi yang didapatkan <b>{{ details.name }}</b>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pt-2">
          
          <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0" v-if="itemsCommission.length > 0">
            <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer" >
              SUMMARY
              <v-spacer></v-spacer>
              <!-- <v-btn
                text
                elevation="0"
                class="text-capitalize pink--text"
                small>
                {{ is_show_summary ? "Sembunyikan Summary" : "Lihat Summary" }}
                <v-icon right>{{ is_show_summary ? "mdi-arrow-up" : "mdi-arrow-down"}}</v-icon>
              </v-btn> -->
            </v-card-title>
            <v-expand-transition>
              <v-row class="mb-2" v-show="is_show_summary">
                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                  xs="12"
                  v-for="(item, index) in summary_withdraw"
                  :key="index"
                >
                  <v-skeleton-loader
                    class="ma-auto"
                    height="100"
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    type="image"
                  >
                    <v-card 
                      :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                      class="box-shadow border-radius py-0">
                      <v-card-title class="subtitle-1 font-weight-bold">
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip left v-if="item.help">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#D31145"
                              v-bind="attrs"
                              v-on="on"
                              class="cursor-pointer"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div v-html="item.help"></div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text class="subtitle-2">
                        <p
                          class="text-second d-flex align-center"
                          style="font-size: 25px;">
                          <span class="mr-3 mt-3">
                            {{ item.value }}
                          </span>
                          <v-spacer />
                          <span>
                            <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                          </span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-card>

          <div v-if="itemsCommission.length > 0">

            <v-data-table
            :headers="headersCommission"
            :items="itemsCommission"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="optionsCommission"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.promo_name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.promo_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="`Nama Paket : ${item.package_name}`" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.owner_name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.owner_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="`${item.owner_mobilephone}`" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.store_name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.store_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="`${item.store_category_name}`" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.package_discount_value`]="{item}">
              {{ item.package_discount_value | price }}
            </template>

            <template v-slot:[`item.commission_percent`]="{item}">
              {{ item.commission_percent | percent }}
            </template>
            
            <template v-slot:[`item.commission_nominal`]="{item}">
              <div class="font-weight-bold black--text">{{ item.commission_nominal }}</div>
            </template>

            <template v-slot:[`item.package_sales_promo_disc_value`]="{item}">
              {{ item.package_sales_promo_disc_value | price }}
            </template>

            <template v-slot:[`item.package_type`]="{item}">
              <div class="font-weight-bold" :class="item.package_subscribe_type == 'Year' ? 'primary--text' : item.package_subscribe_type == 'Month' ? 'purple--text' : 'pink--text'">
                Aktivasi SODAPOS <span class="text-uppercase">{{ item.package_type }} </span> <br>
                ({{ item.package_subscribe_label }})
              </div>
            </template>

            <template v-slot:[`item.commission_after_disct_bool`]="{item}">
              <div :class="item.commission_after_disct_bool ? 'teal--text' : 'red--text'" class="font-weight-bold">
                 {{ item.commission_after_disct_bool ? "Ya" : "Tidak" }}
              </div>
            </template>

          </v-data-table>

            <Pagination 
              @changeLimit="limitCommission = $event; fetchSalesCommission()" 
              @changePage="pageCommission = $event; fetchSalesCommission($event)" 
              :model="pagination.commission"
              v-show="pagination.commission.data.total_page > 0"/>
          </div>
          <div v-else class="text-center subtitle-2 mt-15">
            <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
          </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Export,
    Empty
  },
  data () {
    return {
      searching: "",
      feedback: "",
      is_show_summary: true,
      details: {},
      summary_withdraw: [],
      process: {
        run: false
      },
      dialog: {
        confirmation: false,
        confirmation_type: "",
        filtered: false,
        success: false,
        success_type: "",
        commission_sales: false,
        withdraw_approval:false,
        withdraw_approval_type: "",
        configuration_withdraw: false,
      },
      filter: {
        status: "",
        filter_date: {}
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      form : {
        id: "",
        tax_nominal: 0,
        note: "",

        // config
        tax_title: "",
        tax_nominal: 0,
        minimal: 0,
        maximal: 0
      },
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options:{},

      itemsCommission: [],
      limitCommission: 10,
      pageCommission: 1,
      optionsCommission:{},

      pagination: {
        commission: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        commission: {
          pagination: 0
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 130
        },
        {
          text: 'Tanggal Pengajuan',
          value: 'input_datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Kode Sales',
          value: 'sales_code',
          align: 'center',
          width: 100
        },
        {
          text: 'Nama Sales',
          value: 'sales_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nominal Pengajuan',
          value: 'nominal_label',
          align: 'end',
          width: 200
        },
        {
          text: 'Biaya Potongan',
          value: 'tax_nominal_label',
          align: 'end',
          width: 200
        },
        {
          text: 'Nominal Transfer',
          value: 'paid_nominal',
          align: 'end',
          width: 200
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: 200
        },
        {
          text: 'Tujuan Transfer',
          value: 'sales_bank_label',
          align: 'start',
          width: 250
        },
        {
          text: 'Catatan Penolakan',
          value: 'reject_note',
          align: 'start',
          width: 200
        },
         {
          text: 'User Pelaksana',
          value: 'user',
          align: 'start',
          width: 200
        },
        {
          text: 'Tanggal Pelaksana',
          value: 'user_datetime',
          align: 'start',
          width: 200
        }
      ],
      headersCommission: [
        {
          text: 'Tanggal Transaksi',
          value: 'transaction_datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Kode Promo',
          value: 'promo_code',
          align: 'center',
          width: 150
        },
        {
          text: 'Harga Paket',
          value: 'package_price',
          align: 'end',
          width: 150
        },
        {
          text: 'Diskon Paket',
          value: 'package_discount_value',
          align: 'end',
          width: 150
        },
        {
          text: 'Diskon Promo Sales',
          value: 'package_sales_promo_disc_value',
          align: 'end',
          width: 150
        },
        {
          text: 'Total Bayar Merchant',
          value: 'grand_total',
          align: 'end',
          width: 200
        },
        {
          text: 'Komisi Persen Sales',
          value: 'commission_percent',
          align: 'end',
          width: 150
        },
        {
          text: 'Dihitung Setelah Diskon',
          value: 'commission_after_disct_bool',
          align: 'center',
          width: 150
        },
        {
          text: 'Total Komisi Sales',
          value: 'commission_nominal',
          align: 'end',
          width: 150
        },
        {
          text: 'Nama Promo',
          value: 'promo_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Sales',
          value: 'sales_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Merchant',
          value: 'owner_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Toko',
          value: 'store_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Aktivasi',
          value: 'package_type',
          align: 'center',
          width: 200
        }
      ]
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },

     // SALES COMMISSION
    'selected.commission.pagination': function(val) {
      this.fetchSalesCommission(val+1)
    },
    optionsCommission:{
      handler(){
        this.fetchSalesCommission()
      },
      deep:true
    },
  },
  mounted() {
    this.fetch()
    this.fetchSalesCommissionSummary()
  },
  methods: {
    actions(type, item) {
      switch (type) {
        case "sales_commission":
          this.fetchSalesCommission()
          this.dialog.commission_sales = true
          break;
        case "approve":
          this.dialog.withdraw_approval = true
          this.dialog.withdraw_approval_type = "approve"
          this.form = {
            id: item.id,
            tax_nominal: item.tax_nominal,
            note: ""
          }
          break;
        case "reject":
          this.dialog.withdraw_approval = true
          this.dialog.withdraw_approval_type = "reject"
          this.form = {
            id: item.id,
            tax_nominal: 0,
            note: ""
          }
          break;
        case "copy":
          navigator.clipboard.writeText(item);
          this.snackbar = {
            show: true,
            text: "Nomor Rekening berhasil di copy",
            color: "success",
          }
          break;
        case "update_configuration":
          this.fetchConfiguration()
          break;
      }  
    },

    resetFilter() {
      this.filter = {
        status: "",
        filter_date: {}
      };
      this.fetch()
      return
    },

    filterData() {
      this.dialog.filtered = false;
      this.fetch()
    },

    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/sales/commission/withdraw', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          status: this.filter.status,
          start_date: this.filter.filter_date.start,
          end_date: this.filter.filter_date.end
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })

      this.process.run = false
    },

    async fetchSalesCommission(paging) {
      this.process.run = true

      await get('internal/dashboard/sales/commission', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
          sales_id: this.details.id
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.itemsCommission = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.commission.data = res.results.pagination
          this.pagination.commission.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
          this.fetchSalesCommissionSummaryBySales()
        }
      })

      this.process.run = false
    },
    
    async fetchSalesCommissionSummary() {
      await get('internal/dashboard/sales/commission/withdraw/summary')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.summary_withdraw = res.results.data
        }
      })
    },
    
    async fetchConfiguration() {
      await get('internal/dashboard/sales/commission/withdraw/config')
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.dialog.configuration_withdraw = true
          this.form = res.results.data
        }
      })
    },

    async saveRequest(type) {
      this.process.run = true
      let url = ""

      if (type == 'approve') {
        url = `internal/dashboard/sales/commission/withdraw/approve`
      }else if (type == 'reject'){
        url = `internal/dashboard/sales/commission/withdraw/reject`
      }else if (type == 'configuration') {
        url = `internal/dashboard/sales/commission/withdraw/config/update`
      }

      await post(url, {
        data: this.form
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.fetch()
          this.dialog.withdraw_approval = false
          this.dialog.withdraw_approval_type = ""
          this.dialog.configuration_withdraw = false
          this.snackbar = {
            show: true,
            text: res.message,
            color: "success",
          }
        }else {
          this.snackbar = {
            show: true,
            text: res.message,
            color: "error",
          }
        }
      })
    }
  }
}
</script>