<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold flex flex justify-space-between align-start">
        <div>
          DAFTAR PEMASUKAN DANA DARI PESAN DITEMPAT
          <div class="mt-1">
            <Export
              class="mr-2"
              :disabled="process.run"
              :file_type="'xls'"
              :btnText="'Export Excel'"
              :btnIcon="'mdi-microsoft-excel'"
              :btnColor="'teal'"
              :url="'internal/income/self-order/fee-payment/dikasir/export_excel'"
              :title="'Data Pemasukan Dana Dari Pesan Ditempat'"
              ref="base-export-excel"
            />
          </div>
        </div>
        <div style="width: 500px">
          <VueCtkDateTimePicker
            color="#d31145"
            onlyDate
            :range="true"
            right
            v-model="filter_date"
            label="Pilih Tanggal"
            :noClearButton="true"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="new Date().toISOString().substr(0, 10)"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
            ]"
          />
        </div>
      </v-card-title>
      <v-card-text>

        <v-card outlined class="box-shadow border-radius px-2 pt-2 pb-0" v-if="items.length > 0">
          <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer" >
            SUMMARY
            <v-spacer></v-spacer>
            <!-- <v-btn
              text
              elevation="0"
              class="text-capitalize pink--text"
              small>
              {{ is_show_summary ? "Sembunyikan Summary" : "Lihat Summary" }}
              <v-icon right>{{ is_show_summary ? "mdi-arrow-up" : "mdi-arrow-down"}}</v-icon>
            </v-btn> -->
          </v-card-title>
          <v-expand-transition>
            <v-row class="mb-2" v-show="is_show_summary">
              <v-col
                cols="12"
                sm="4"
                lg="4"
                md="4"
                xs="12"
                v-for="(item, index) in summaryCommissionSales"
                :key="index"
              >
                <v-skeleton-loader
                  class="ma-auto"
                  height="100"
                  :loading="process.run"
                  transition="scale-transition"
                  :tile="false"
                  type="image"
                >
                  <v-card 
                    :min-height="$vuetify.breakpoint.name === 'sm' ? 150 : 100"
                    class="box-shadow border-radius py-0">
                    <v-card-title class="subtitle-1 font-weight-bold">
                      {{ item.label }}
                      <v-spacer></v-spacer>
                      <v-tooltip left v-if="item.help">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="#D31145"
                            v-bind="attrs"
                            v-on="on"
                            class="cursor-pointer"
                            size="20"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <div v-html="item.help"></div>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text class="subtitle-2">
                      <p
                        class="text-second d-flex align-center"
                        style="font-size: 25px;">
                        <span class="mr-3 mt-3">
                          {{ item.value }}
                        </span>
                        <v-spacer />
                        <span>
                          <v-icon size="40" :color="'primary'">mdi-cash</v-icon>
                        </span>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            fixed-header
            flat
            class="elevation-0 rounded-lg">

            <template v-slot:[`item.nominal`]="{item}">
              {{ item.nominal | price }}
            </template>

            <template v-slot:[`item.datetime`]="{item}">
              {{ item.datetime | datetime }}
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetchIncome()" 
            @changePage="page = $event; fetchIncome($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.success_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} akun intenal ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deleteAccount()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Empty,
    Export,
  },
  data () {
    return {
      searching: "",
      filter_date: {},
      is_show_summary: true,
      details: {},
      summaryCommission: [],
      summaryCommissionSales: [],
      process: {
        run: false
      },
      dialog: {
        confirmation: false,
        confirmation_type: "",
        success: false,
        success_type: "",
        commission_sales: false,
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      // DATA TABLES
      sales_id: "",
      items: [],
      limit: 10,
      page: 1,
      options:{},

      itemsCommission: [],
      limitCommission: 10,
      pageCommission: 1,
      optionsCommission:{},

      pagination: {
        commission: {
          data: {},
          page: ""
        },
        data: {},
        page: ""
      },
      selected: {
        commission: {
          pagination: 0
        },
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Tanggal',
          value: 'datetime',
          align: 'center',
          width: 100
        },
        {
          text: 'Total Pendapatan',
          value: 'nominal',
          align: 'end',
          width: 150
        },
        {
          text: 'Keterangan',
          value: 'note',
          align: 'start',
          width: 200
        },
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetchIncome()
    },
    'filter_date': function(val) {
      this.fetchIncome();
      this.fetchIncomeSummary()
    },
    'selected.pagination': function(val) {
      this.fetchIncome(val+1)
    },
    options:{
      handler(){
        this.fetchIncome()
      },
      deep:true
    },

     // SALES COMMISSION
    'selected.commission.pagination': function(val) {
      this.fetchIncome(val+1)
    },
    optionsCommission:{
      handler(){
        this.fetchIncome()
      },
      deep:true
    },
  },
  mounted() {
    this.fetchIncome()
    this.fetchIncomeSummary()
  },
  methods: {
    actions(type, item) {
      switch (type) {
        case "sales_commission":
          this.sales_id = item.id
          this.dialog.commission_sales = true
          this.fetchIncome()
          break;
      }  
    },

    async fetchIncome(paging) {
      this.process.run = true

      await get('internal/income/self-order/fee-payment/dikasir', {
        params: {
          page: paging,
          limit: this.limit,
          'datetime[gte]': this.filter_date.start,
          'datetime[lte]': this.filter_date.end,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })

      this.process.run = false
    },

    async fetchIncomeSummary() {
      await get('internal/income/self-order/fee-payment/dikasir/summary', {
        params: {
          'datetime[gte]': this.filter_date.start,
          'datetime[lte]': this.filter_date.end,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.summaryCommissionSales = res.results.data
        }
      })
    },
  }
}
</script>