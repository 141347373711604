<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold cursor-pointer" >
        SUMMARY
        <v-spacer></v-spacer>
        <!-- <v-btn
          text
          elevation="0"
          class="text-capitalize pink--text"
          small>
          {{ is_show_summary ? "Sembunyikan Summary" : "Lihat Summary" }}
          <v-icon right>{{ is_show_summary ? "mdi-arrow-up" : "mdi-arrow-down"}}</v-icon>
        </v-btn> -->
      </v-card-title>
      <v-expand-transition>
      <v-row class="mb-6" v-show="is_show_summary">
        <v-col
          cols="12"
          sm="4"
          lg="4"
          md="4"
          xs="12"
          v-for="(item, index) in process.run ? 3 : summary"
          :key="index"
        >
          <v-skeleton-loader
            class="ma-auto"
            height="200"
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            type="image"
          >
            <v-card 
              :min-height="$vuetify.breakpoint.name === 'sm' ? 220 : ''"
              class="box-shadow border-radius py-2">
              <v-card-title class="subtitle-1 font-weight-bold">
                <span v-html="item.title"></span>
                <v-spacer></v-spacer>
                <v-tooltip top v-if="item.help">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#D31145"
                      v-bind="attrs"
                      v-on="on"
                      class="cursor-pointer"
                      size="20"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <div v-html="item.help"></div>
                </v-tooltip>
                <v-tooltip left v-if="item.slug">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      class="text-capitalize pink--text"
                      small
                      :to="`/store?store_type=${item.slug}`">
                      <v-icon size="20">mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Selengkapnya</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="subtitle-2">
                <p
                  class="text-second d-flex align-center"
                  style="font-size: 36px;"
                >
                  <span class="mr-3 mt-3" v-html="item.value"></span>
                  <v-spacer />
                  <span>
                    <v-icon size="40" :color="item.color">{{item.icon}}</v-icon>
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
      </v-row>
      </v-expand-transition>
    </v-card>

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        <div>
          DAFTAR INVOICE
          <!-- internal/dashboard/report/langganan/export_excel -->
          <Export
            class=" mr-2"
            :disabled="process.run"
            :file_type="'xls'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="'internal/dashboard/report/langganan/export_excel'"
            :title="'Data Langganan SODA'"
            ref="base-export-excel"
          />
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama, email, nomor handphone"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.store_name`]="{item}">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.store_name" class="subtitle-2"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>


            <template v-slot:[`item.owner_name`]="{item}">
              <v-list-item>
                <v-list-item-content class="pl-0 ml-0">
                  <v-list-item-title v-html="item.owner_name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.owner_email" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.package_name`]="{item}">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.package_name }} - <span :class="item.package_subscribe_type == 'Tahun' ? 'primary--text font-weight-bold' : item.package_subscribe_type == 'Bulan' ? 'purple--text font-weight-bold' : 'pink--text font-weight-bold'">{{ item.package_subscribe_value }} {{ item.package_subscribe_type == "Tahun" ? "Tahun" : item.package_subscribe_type == "Bulan" ? "Bulan" : "Hari" }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle v-html="item.desc" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.package_type`]="{item}">
              <v-chip 
                v-if="item.package_type == 'Lite'"
                outlined
                label
                class="font-weight-bold"
                color="green">
                SODAPOS LITE
              </v-chip>
              <v-chip 
                v-else-if="item.package_type == 'Pro'"
                outlined
                label
                class="font-weight-bold"
                color="red">
                SODAPOS PRO
              </v-chip>
              <v-chip 
                v-else-if="item.package_type == 'Personal'"
                outlined
                label
                class="font-weight-bold"
                color="teal">
                SODAKU
              </v-chip>
              <v-chip 
                v-else
                outlined
                label
                class="font-weight-bold"
                color="red">
                {{ item.package_type }}
              </v-chip>
            </template>

            <template v-slot:[`item.end_datetime`]="{ item }">
              <span v-html="displayDifference(item.end_datetime,)"></span>
            </template>

            <template v-slot:[`item.payment_channel`]="{item}">
              <b class="text-uppercase">{{ item.payment_channel }}</b>
            </template>

            <template v-slot:[`item.package_sales_promo_disc_value`]="{item}">
              {{ item.package_sales_promo_disc_value | price }}
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item,'followup')"
                    color="teal"
                    icon>
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                </template>
                <span>Whatsapp Follow up</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item,'detail_sales')"
                    color="primary"
                    icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Detail Sales</span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- WHATSAPP DIALOG -->
    <v-dialog v-model="dialog.follow_up" persistent width="800px" scrollable>
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          KIRIM PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.follow_up =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3" style="height: 500px">

          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-2 mt-1"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>

          <div>No Handphone <i class="font-weight-bold" v-if="store_mobilephone != ''">(readonly)</i></div>
          <v-text-field
            outlined
            color="#d21145"
            :readonly="store_mobilephone == '' ? false : true"
            :placeholder="store_mobilephone == '' ? 'Masukkan No Handphone dikarenakan tidak terdapat nomor yang tersedia' : 'Masukkan No Handphone'"
            dense
            v-model="store_mobilephone_new"
            :value="store_mobilephone">
          </v-text-field>

          <div class="subtitle-2 mb-2">
            Masukkan text pesan disini
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  size="20"
                  color="orange"
                  @click="dialog.format_whatsapp = true; dialog.follow_up =false;">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Informasi format pesan whatsapp</span>
            </v-tooltip>
          </div>

          <div class="mb-3">
            <v-slide-group
              v-model="filter.template_message"
              show-arrows>
              <v-slide-item
                v-for="(item, i) in items_template" :key="i" 
                v-slot="{ active, toggle }"
                :value="item.slug">
                <v-btn
                  class="mx-2"
                  small
                  :input-value="active"
                  active-class="red--text"
                  depressed
                  rounded
                  @click="toggle"
                  :class="item.slug == filter.template_message ? 'white--text text-uppercase font-weight-bold' : 'text-uppercase'">
                  {{ item.title }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </div>

          <v-textarea
            outlined
            rows="10"
            dense
            color="#d31145"
            v-model="whatsapp_text">
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            elevation="0"
            @click="dialog.follow_up = false">
            Batal
          </v-btn>
          <v-btn
            class="text-capitalize white--text"
            color="#D31145"
            elevation="0"
            @click="sendWhatsappMessage">
            Kirim Pesan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.format_whatsapp" persistent width="1000px">
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          INFORMASI FORMAT PESAN WHATSAPP
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.format_whatsapp =false; dialog.follow_up = true;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3">
          
          <ol>
            <li v-for="(format, i) in whatsapp_format" :key="i">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ format.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{format.subtitle}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle v-html="format.hasil"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </li>
          </ol>

          <p>Sesuai pada FAQ Whatsapp di halaman <a href="https://faq.whatsapp.com/539178204879377/?helpref=uf_share" target="_blank">Whatsapp FAQ</a></p>

        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- WHATSAPP DIALOG -->

    <!-- DETAIL SALES -->
    <v-dialog v-model="dialog.detail_sales" persistent width="650">
      <v-card outlined class="box-shadow border-radius pa-2 pb-1">
        <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold text-uppercase">
          INFORMASI PROMO SALES
          <v-spacer></v-spacer>
          <v-icon color="red" @click="dialog.detail_sales =false;">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-3">
          <v-simple-table>
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0" width="30%">Nama Sales</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ detail_sales.sales_name }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Nama Promo</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ detail_sales.promo_name }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Kode Promo</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second font-weight-bold">
                    {{ detail_sales.promo_code }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { get } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Export from '@/components/Export'
import Empty from '@/components/Empty'
export default {
  components: {
    Pagination,
    Export,
    Empty,
  },
  data () {
    return {
      is_show_summary: true,
      searching: "",
      whatsapp_text: "",
      detail_outlet: {},
      detail_sales: {},
      feedback: "",
      summary: [],
      filter: {
        storeType: "",
      },
      process: {
        run: false
      },
      filter: {
        template_message: "",
      },
      // WA DATA
      dialog: {
        history_activation: false,
        detail_sales: false,
        follow_up: false,
        format_whatsapp: false,
        filtered: false,
      },
      whatsapp_format: [
        {
          title: "Tulisan Miring",
          subtitle: "_text_",
          hasil: "<i>text</i>",
        },
        {
          title: "Tulisan Tebal",
          subtitle: "*text*",
          hasil: "<b>text</b>",
        },
        {
          title: "Tulisan Dicoret",
          subtitle: "~text~",
          hasil: "<s>text</s>",
        },
        {
          title: "Nama Pemilik",
          subtitle: "{owner_name}",
          hasil: "memunculkan nama Pemilik pada pesan Anda",
        },
        {
          title: "Nama Outlet",
          subtitle: "{outlet_name}",
          hasil: "memunculkan nama Outlet pada pesan Anda",
        },
        {
          title: "Alamat Outlet",
          subtitle: "{outlet_address}",
          hasil: "memunculkan alamat outlet pada pesan Anda",
        },
        {
          title: "Tanggal Expired Outlet",
          subtitle: "{expired_date}",
          hasil: "memunculkan text tanggal expired sesuai outlet pada pesan Anda",
        },
      ],
      store_id: "",
      store_name: "",
      store_mobilephone: "",
      store_mobilephone_new: "",
      items_template: [],
      // WA DATA END
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150,
          sortable: false
        },
        {
          text: 'Tanggal Berlangganan',
          value: 'transaction_datetime',
          align: 'center',
          width: 200
        },
        {
          text: 'Nama Outlet',
          value: 'store_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Nama Pemilik',
          value: 'owner_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Tipe Berlangganan',
          value: 'package_type',
          align: 'center',
          width: 200
        },
        {
          text: 'No. Handphone',
          value: 'owner_mobilephone',
          align: 'center',
          width: 150
        },
        {
          text: 'Masa Langganan',
          value: 'end_datetime',
          align: 'center',
          width: 300,
        },
        {
          text: 'Metode Pembayaran',
          value: 'payment_channel',
          align: 'center',
          width: 200
        },
        {
          text: 'Tipe Langganan',
          value: 'package_name',
          align: 'start',
          width: 200
        },
        {
          text: 'Harga Langganan',
          value: 'package_price',
          align: 'end',
          width: 200
        },
        {
          text: 'Diskon Paket',
          value: 'package_discount',
          align: 'end',
          width: 200
        },
        {
          text: 'Diskon Promo',
          value: 'package_sales_promo_disc_value',
          align: 'end',
          width: 200
        },
        {
          text: 'Total Pembayaran',
          value: 'package_grand_total',
          align: 'end',
          width: 200
        }
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
    'filter.template_message': function(val) {
      this.setMessageWhatsApp(val)
    },
  },
  computed: {
    
  },
  mounted() {
    this.fetch()
    this.fetchSummaryInvoicNew()
  },
  methods: {
    displayDifference(end_datetime) {
      return this.$options.filters.diff_date(end_datetime)
    },
    differenceDate(date_expired) {
      let diffDate = ""
      let date1 = new Date()
      let date2 = new Date(date_expired)

      if (date1 < date2) {
        var diff = (date2 - date1)/1000;
        diff = Math.abs(Math.floor(diff));
  
        var days = Math.floor(diff/(24*60*60));
        var leftSec = diff - days * 24*60*60;
  
        var hrs = Math.floor(leftSec/(60*60));
        var leftSec = leftSec - hrs * 60*60;
  
        var min = Math.floor(leftSec/(60));
        var leftSec = leftSec - min * 60;
  
        diffDate = `Tersisa ${days} hari, ${hrs} jam, ${min} menit.<br/> Berakhir pada <br/> <b>${this.$options.filters.datetime(date_expired)}</b>`
  
      }else {
        diffDate = `<div class="red--text font-weight-bold">Kadaluarsa</div>`
      }

      return diffDate
      
    },

    // WA SEND
    actions(item, type) {
      switch (type) {
        case 'history':
          this.store_id = item.id
          this.store_name = item.name
          this.dialog.history_activation = true
          this.fetchHistoryActivation()
          this.fetchSummaryActivation()
          break;
        case 'followup':
          this.store_mobilephone = this.$options.filters.wa_number(item.owner_mobilephone)
          this.store_mobilephone_new = ""
          this.detail_outlet = item
          this.dialog.follow_up = true
          this.fetchTemplate()
          break;
        case 'detail_sales':
          this.detail_sales = item
          this.dialog.detail_sales = true
          break;
      }
    },
    setMessageWhatsApp(slug) {
      this.items_template.forEach((template) => {
        if (slug == template.slug) {
          return this.whatsapp_text = template.messages
        }
      })
    },
    format_text(text){
      return text.replace(`{expired_date}`,`${this.$options.filters.datetimeFull(this.detail_outlet.end_datetime)}`)
      .replace(`{owner_name}`,`${this.detail_outlet.owner_name}`)
      .replace(`{outlet_name}`,`${this.detail_outlet.store_name}`)
      .replace(`{outlet_address}`,`${this.detail_outlet.store_address}`)
      
      // .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
      // .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
      // .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
      // .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>');
    },
    sendWhatsappMessage() {
      if (this.store_mobilephone == '' && this.store_mobilephone_new == '') {
        this.feedback = "Silahkan isikan nomor handphone terlebih dahulu"
        setTimeout(() => {
          this.feedback = ""
        }, 3000)
        return;
      }
      let message = this.format_text(this.whatsapp_text)
      let link = encodeURI(`https://api.whatsapp.com/send?phone=${this.store_mobilephone == '' ? this.store_mobilephone_new : this.store_mobilephone}&text=${message}`)
      // let link = encodeURI(`https://api.whatsapp.com/send?phone=62895392685163&text=${message}`)
      window.open(link)
    },
    async fetchTemplate() {
      // this.process.run = true

      await get('internal/dashboard/wa_template', {})
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items_template = res.results.data
          this.filter.template_message = res.results.data[0].slug
          // this.setMessageWhatsApp(res.results.data[0].slug)
        }
      })
      this.process.run = false
    },
    // WA SEND END
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/report/langganan', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },
    async fetchSummaryInvoice() {
      this.process.run = true
      await get(`internal/dashboard/report/langganan/summary`, {})
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          let detail = res.results.data[0]
          this.summary = [
            {
              title: "Total Transaksi Berlangganan",
              value: detail.total_transaction,
              help: "",
              slug: "",
              color: "#3498db",
              icon: "mdi-finance"
            },
            {
              title: "Total Harga Asli Paket Langganan",
              value: detail.package_price,
              help: "",
              slug: "",
              color: "primary",
              icon: "mdi-cash"
            },
            {
              title: "Total Diskon Paket Langganan",
              value: detail.package_discount,
              help: "",
              slug: "",
              color: "red",
              icon: "mdi-tag"
            },
            {
              title: "Total Pendapatan Langganan",
              value: detail.package_grand_total,
              help: "",
              slug: "",
              color: "teal",
              icon: "mdi-cash-100"
            }
          ]
        }
      })
      this.process.run =false
    },
    async fetchSummaryInvoicNew() {
      this.process.run = true
      await get(`internal/dashboard/report/langganan/summary_new`, {})
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          let detail = res.results.data
          for (let index = 0; index < detail.length; index++) {
            const data = detail[index];
          }
          this.summary = [
            {
              title: detail[0].label,
              value: detail[0].value,
              help: "",
              slug: "",
              color: "#3498db",
              icon: "mdi-finance"
            },
            {
              title: detail[1].label,
              value: detail[1].value,
              help: "",
              slug: "",
              color: "primary",
              icon: "mdi-cash"
            },
            {
              title: detail[2].label,
              value: detail[2].value,
              help: "",
              slug: "",
              color: "red",
              icon: "mdi-tag"
            },
            {
              title: detail[3].label,
              value: detail[3].value,
              help: "",
              slug: "",
              color: "teal",
              icon: "mdi-tag-outline"
            },
            {
              title: detail[4].label,
              value: detail[4].value,
              help: "",
              slug: "",
              color: "teal",
              icon: "mdi-cash-100"
            }
          ]
        }
      })
      this.process.run =false
    },
  }
}
</script>