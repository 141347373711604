<template>
  <div>
    <v-btn
      depressed
      :width="$vuetify.breakpoint.name === 'xs' ? '' : 150"
      :color="btnColor"
      :btnColor="btnColor"
      :fab="$vuetify.breakpoint.name === 'xs' ? true : false"
      :x-small="$vuetify.breakpoint.name === 'xs' ? true : false"
      @click="exportExcel()"
      :url="url"
      :loading="$store.state.loading"
      :disabled="
        disabled || (start_date == '' && end_date == '')
          ? true
          : $store.state.loading
      "
      :title="title"
      :file_type="file_type"
      :start_date="start_date"
      :end_date="end_date"
      class="white--text text-capitalize rounded-lg"
    >
      <v-icon>
        {{ btnIcon }}
      </v-icon>
      <span v-if="$vuetify.breakpoint.name !== 'xs'">
        {{ btnText }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import { get, post, put, destroy, exportFile } from "@/service/Axios";
import moment from "moment";
export default {
  data() {
    return {
      run: false,
      params: {},
    };
  },
  props: {
    url: "",
    title: "",
    btnText: "",
    btnIcon: "",
    btnColor: "",
    file_type: "",
    process: false,
    submit: false,
    disabled: false,
    options: {},
    type_product: "",
    product_id: "",
    start_date: "",
    end_date: "",
  },
  methods: {
    changedisabled() {
      this.$emit("changedisabled", this.run);
    },
    async exportExcel() {
      this.$emit("onClickExport");
      this.$store.state.loading = true;
      this.changedisabled();
      let params = {
        type: this.type_product,
        product_id: this.product_id,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      if (Object.keys(this.params).length > 0) {
        delete this.params.limit;
        params = {
          ...params,
          ...this.params,
        };
      }
      // console.log(this.params, "new");
      // console.log(params, "local");
      await exportFile("GET", `${this.url}`, {
        params: params,
      })
        .then((response) => {
          let res = response;
          if (res.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${this.title}-${moment().format("DMYYYYHHmmss")}.${this.file_type}`
            );
            document.body.appendChild(link);
            link.click();
            this.$store.state.loading = false;
          } else {
            this.$store.state.loading = false;
            this.error.message = "Gagal mengeksport excel.";
          }
          this.$store.state.loading = false;
        })
        .catch((error) => {
          this.$store.state.loading = false;
        });
        this.$store.state.loading = false;
    },
  },
};
</script>

<style></style>
