<template>
  <div
    class="my-5"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'"
  >
    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR INFORMASI PAKET
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama paket, tipe paket"
          class="font-weight-normal"
          v-model="searching"
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-btn
          @click="actions(null, 'add')"
          elevation="0"
          class="text-capitalize white--text"
          color="#D31145"
        >
          <v-icon left>mdi-plus</v-icon>
          Tambah Paket
        </v-btn>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ item.name }} -
                    <span
                      :class="
                        item.is_active_bool
                          ? 'teal--text font-weight-bold'
                          : 'red--text font-weight-bold'
                      "
                      >(
                      {{ item.is_active_bool ? "Aktif" : "Tidak aktif" }}
                      )</span
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.desc"
                    class="caption"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                v-if="item.type == 'Lite'"
                outlined
                label
                class="font-weight-bold"
                color="green"
              >
                SODAPOS LITE
              </v-chip>
              <v-chip
                v-else-if="item.type == 'Pro'"
                outlined
                label
                class="font-weight-bold"
                color="red"
              >
                SODAPOS PRO
              </v-chip>
              <v-chip
                v-else-if="item.type == 'Personal'"
                outlined
                label
                class="font-weight-bold"
                color="teal"
              >
                SODAKU
              </v-chip>
              <v-chip
                v-else-if="item.type == '' && item.is_trial_bool"
                outlined
                label
                class="font-weight-bold"
                color="green"
              >
                SODAPOS LITE
              </v-chip>
              <v-chip v-else outlined label class="font-weight-bold" color="">
                {{ item.type ? item.type : "-" }}
              </v-chip>
            </template>

            <template v-slot:[`item.price`]="{ item }">
              {{ item.price | price }}
            </template>

            <template v-slot:[`item.hpp`]="{ item }">
              {{ item.hpp | price }}
            </template>

            <template v-slot:[`item.subscribe_type`]="{ item }">
              <span
                :class="
                  item.subscribe_type == 'Year'
                    ? 'primary--text font-weight-bold'
                    : item.subscribe_type == 'Month'
                    ? 'purple--text font-weight-bold'
                    : 'pink--text font-weight-bold'
                "
                >{{ item.subscribe_value }}
                {{
                  item.subscribe_type == "Year"
                    ? "Tahun"
                    : item.subscribe_type == "Month"
                    ? "Bulan"
                    : "Hari"
                }}</span
              >
            </template>

            <template v-slot:[`item.discount`]="{ item }">
              <span v-if="item.discount_value > 0">{{
                item.discount_value | price
              }}</span>
              <span v-else>{{ item.discount_percent | percent }}</span>
            </template>

            <template v-slot:[`item.grand_total`]="{ item }">
              <span v-if="item.discount_value > 0">{{
                (item.price - item.discount_value) | price
              }}</span>
              <span v-if="item.discount_percent > 0">{{
                (item.price - (item.price * item.discount_percent) / 100)
                  | price
              }}</span>
              <span
                v-if="item.discount_percent == 0 && item.discount_value == 0"
                >{{ item.price | price }}</span
              >
            </template>

            <template v-slot:[`item.discount_datetime`]="{ item }">
              {{ item.discount_start_datetime | dateFull }} -
              {{ item.discount_end_datetime | dateFull }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :color="item.is_active_bool ? 'teal' : 'pink'"
                    @click="actions(item, 'activation')"
                    icon
                  >
                    <v-icon>mdi-switch</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    item.is_active_bool
                      ? "Non-aktifkan paket"
                      : "Aktifkan paket"
                  }}
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item, 'update')"
                    color="primary"
                    icon
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Ubah Paket</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                    @click="actions(item, 'delete')"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span> Hapus Paket </span>
              </v-tooltip>
            </template>
          </v-data-table>

          <Pagination
            @changeLimit="
              limit = $event;
              fetch();
            "
            @changePage="
              page = $event;
              fetch($event);
            "
            :model="pagination"
            v-show="pagination.data.total_page > 0"
          />
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty
            value="Data belum tersedia atau data yang Anda cari tidak tersedia"
          />
        </div>
      </v-card-text>
    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog v-model="dialog.form" persistent scrollable width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Paket Baru" : "Ubah Paket" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-promo'].reset();
            "
          >
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan paket" : "Ubah paket" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-promo">
            <v-form @:keyup.enter="save(dialog.form_type)">
              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-name"
                    name="Nama Paket"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Nama Paket
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan nama paket"
                      v-model="form.name"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-type"
                    name="Tipe Paket"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Tipe Paket
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-select
                      solo
                      flat
                      dense
                      color="#d31145"
                      v-model="form.type"
                      background-color="#EEEEEE"
                      placeholder="Pilih tipe paket"
                      :items="[
                        { text: 'SODAPOS PRO', value: 'Pro' },
                        { text: 'SODAPOS LITE', value: 'Lite' },
                        { text: 'SODAKU', value: 'Personal' },
                      ]"
                      :error-messages="errors"
                      required
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <ValidationProvider
                ref="form-desc"
                name="Deskripsi paket"
                rules="required|max:500"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Deskripsi Paket
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                </div>
                <v-textarea
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="250"
                  rows="3"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan deskripsi paket"
                  v-model="form.desc"
                  :error-messages="errors"
                  required
                  autocomplete="off"
                >
                </v-textarea>
              </ValidationProvider>

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-subscribe_value"
                    name="Jumlah lama langganan"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Jumlah lama langganan
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-currency-field
                      id="price"
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="5"
                      type="number"
                      dense
                      color="#d31145"
                      placeholder="Ex: 1"
                      v-model="form.subscribe_value"
                      :error-messages="errors"
                      required
                    >
                    </v-currency-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-subscribe_type"
                    name="Lama Langganan"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Lama Langganan
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-select
                      solo
                      flat
                      dense
                      color="#d31145"
                      v-model="form.subscribe_type"
                      background-color="#EEEEEE"
                      placeholder="Pilih lama langganan"
                      :items="[
                        { text: 'Hari', value: 'Day' },
                        { text: 'Bulan', value: 'Month' },
                        { text: 'Tahun', value: 'Year' },
                      ]"
                      :error-messages="errors"
                      required
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-price"
                    name="Harga Paket"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Harga Paket
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-currency-field
                      id="price"
                      solo
                      flat
                      dense
                      background-color="#EEEEEE"
                      placeholder="Tuliskan harga paket"
                      type="number"
                      color="#d31145"
                      v-model="form.price"
                      :error-messages="errors"
                      required
                    />
                    <!-- <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      type="number"
                      color="#d31145"
                      placeholder="Tuliskan harga paket"
                      v-model="form.price"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field> -->
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col>
                      <div class="subtitle-2 font-weight-bold">
                        Diskon Nominal
                        <small style="color: #1abc9c"><i>opsional</i></small>
                      </div>
                      <v-currency-field
                        id="price"
                        solo
                        flat
                        dense
                        background-color="#EEEEEE"
                        placeholder="Tuliskan diskon"
                        type="number"
                        color="#d31145"
                        :disabled="form.discount_percent > 0"
                        v-model="form.discount_value"
                        required
                      />
                      <!-- <v-text-field
                        solo
                        flat
                        background-color="#EEEEEE"
                        counter
                        maxlength="50"
                        dense
                        color="#d31145"
                        type="number"
                        placeholder="Tuliskan diskon"
                        :disabled="form.discount_percent > 0"
                        v-model="form.discount_value"
                        :error-messages="errors"
                        required
                      >
                      </v-text-field> -->
                    </v-col>
                    <v-col>
                      <div class="subtitle-2 font-weight-bold">
                        Diskon Persen
                        <small style="color: #1abc9c"><i>opsional</i></small>
                      </div>
                      <v-currency-field
                        id="price"
                        solo
                        flat
                        background-color="#EEEEEE"
                        dense
                        type="number"
                        :disabled="form.discount_value > 0"
                        color="#d31145"
                        placeholder="Tuliskan diskon"
                        v-model="form.discount_percent"
                        required
                      >
                      </v-currency-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- HPP Paket & Status Paket -->
              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-price"
                    name="HPP Paket"
                    rules="required|max:50|min:1"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      HPP Paket
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-currency-field
                      id="price"
                      solo
                      flat
                      dense
                      background-color="#EEEEEE"
                      placeholder="Tuliskan hpp paket"
                      type="number"
                      color="#d31145"
                      v-model="form.hpp"
                      :error-messages="errors"
                      required
                    /> </ValidationProvider
                ></v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-is_active_bool"
                    name="Tipe Paket"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Status Paket
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-select
                      solo
                      flat
                      dense
                      color="#d31145"
                      v-model="form.is_active_bool"
                      background-color="#EEEEEE"
                      placeholder="Pilih status paket"
                      :items="[
                        { text: 'Aktif', value: true },
                        { text: 'Tidak aktif', value: false },
                      ]"
                      :error-messages="errors"
                      required
                    >
                    </v-select> </ValidationProvider
                ></v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-discount_start_datetime"
                    name="Tanggal Mulai Paket"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Tanggal Mulai Paket
                      <small
                        style="color: #e74c3c"
                        v-if="
                          form.discount_value > 0 || form.discount_percent > 0
                        "
                        ><i>*wajib diisi</i></small
                      >
                      <small style="color: #1abc9c" v-else
                        ><i>opsional</i></small
                      >
                    </div>

                    <v-menu
                      v-model="start_date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="FormatStartDate"
                          placeholder="Pilih tanggal mulai paket"
                          solo
                          flat
                          background-color="#EEEEEE"
                          dense
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        color="#d31145"
                        v-model="form.discount_start_datetime"
                        @input="start_date = false"
                      ></v-date-picker>
                    </v-menu>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-discount_end_datetime"
                    name="Tanggal Selesai Paket"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Tanggal Selesai Paket
                      <small
                        style="color: #e74c3c"
                        v-if="
                          form.discount_value > 0 || form.discount_percent > 0
                        "
                        ><i>*wajib diisi</i></small
                      >
                      <small style="color: #1abc9c" v-else
                        ><i>opsional</i></small
                      >
                    </div>

                    <v-menu
                      v-model="end_date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="FormatEndDate"
                          placeholder="Pilih tanggal selesai paket"
                          solo
                          flat
                          background-color="#EEEEEE"
                          dense
                          readonly
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        color="#d31145"
                        v-model="form.discount_end_datetime"
                        @input="end_date = false"
                      ></v-date-picker>
                    </v-menu>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Simpan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important"
            >
              Anda telah berhasil
              {{
                dialog.confirmation_type == "delete"
                  ? "menghapus"
                  : "mengubah status"
              }}
              data paket
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false"
            >
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important"
            >
              Apakah Anda ingin
              {{
                dialog.confirmation_type == "delete"
                  ? "menghapus"
                  : "mengubah status"
              }}
              data paket ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="
              dialog.confirmation = false;
              dialog.confirmation_type = '';
              form.id = '';
            "
          >
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deletePromo()"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>
  </div>
</template>

<script>
import { get, post } from "@/service/Axios";
import moment from "moment";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
export default {
  components: {
    Empty,
    Pagination,
  },
  data() {
    return {
      start_date: false,
      end_date: false,
      searching: "",
      feedback: "",
      details: {},
      process: {
        run: false,
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      dialog: {
        confirmation: false,
        confirmation_type: "",
        success: false,
        form: false,
        form_type: "",
      },
      form: {
        id: "",
        name: "",
        desc: "",
        type: "",
        subscribe_type: "",
        subscribe_value: "",
        price: 0,
        discount_value: 0,
        discount_percent: 0,
        discount_start_datetime: "",
        discount_end_datetime: "",
        hpp: 0,
        is_active_bool: true,
      },
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options: {},
      pagination: {
        data: {},
        page: "",
      },
      selected: {
        menu: 0,
        pagination: 0,
      },
      headers: [
        {
          text: "Aksi",
          value: "action",
          align: "center",
          width: 150,
        },
        {
          text: "Nama paket",
          value: "name",
          align: "start",
          width: 200,
        },
        {
          text: "Tipe Paket",
          value: "type",
          align: "center",
          width: 150,
        },
        {
          text: "Tipe Berlangganan",
          value: "subscribe_type",
          align: "center",
          width: 200,
        },
        {
          text: "Harga",
          value: "price",
          align: "end",
          width: 200,
        },
        {
          text: "Diskon",
          value: "discount",
          align: "end",
          width: 200,
        },
        {
          text: "HPP",
          value: "hpp",
          align: "end",
          width: 200,
        },
        {
          text: "Grand Total",
          value: "grand_total",
          align: "end",
          width: 200,
        },
        {
          text: "Tanggal Diskon Promo",
          value: "discount_datetime",
          align: "center",
          width: 350,
        },
      ],
    };
  },
  watch: {
    searching: function (val) {
      this.searching = val;
      this.fetch();
    },
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  computed: {
    FormatStartDate() {
      return this.form.discount_start_datetime
        ? moment(this.form.discount_start_datetime).format("DD MMMM YYYY")
        : "";
    },
    FormatEndDate() {
      return this.form.discount_end_datetime
        ? moment(this.form.discount_end_datetime).format("DD MMMM YYYY")
        : "";
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch(paging) {
      this.process.run = true;

      await get("internal/dashboard/promo", {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          this.items = res.results.data;
          this.totalData = res.results.pagination.total_data;
          this.pagination.data = res.results.pagination;
          this.pagination.page =
            res.results.pagination.total_page < this.page ? 1 : "";
          this.process.run = false;
        }
      });
    },

    actions(item, method) {
      switch (method) {
        case "add":
          this.dialog.form = true;
          this.dialog.form_type = "add";
          this.form = {
            id: "",
            name: "",
            desc: "",
            type: "Pro",
            subscribe_type: "Day",
            subscribe_value: "",
            price: 0,
            discount_value: 0,
            discount_percent: 0,
            discount_start_datetime: "",
            discount_end_datetime: "",
            hpp: 0,
            is_active_bool: true,
          };
          // this.$refs['validation-new-promo'].reset();
          break;
        case "update":
          this.dialog.form = true;
          this.dialog.form_type = "update";
          this.form = {
            id: item.id,
            name: item.name,
            desc: item.desc,
            type: item.type,
            subscribe_type: item.subscribe_type,
            subscribe_value: item.subscribe_value,
            price: item.price,
            discount_value: item.discount_value,
            discount_percent: item.discount_percent,
            discount_start_datetime: item.discount_start_datetime,
            discount_end_datetime: item.discount_end_datetime,
            hpp: item.hpp,
            is_active_bool: item.is_active_bool,
          };
          // this.$refs['validation-new-promo'].reset();
          break;
        case "activation":
          // this.details = item
          this.ActivationPromo(item);
          // this.dialog.success = true
          this.dialog.confirmation_type = "activation";
          break;
        case "delete":
          this.details = item;
          this.dialog.confirmation = true;
          this.dialog.confirmation_type = "delete";
          break;
      }
    },
    async ActivationPromo(item) {
      await post(`internal/dashboard/promo/activate`, {
        data: {
          id: item.id,
          is_active_bool: item.is_active_bool ? false : true,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.dialog.success = true;
            this.fetch();
            // this.snackbar = {
            //   show: true,
            //   color: "success",
            //   text: "Berhasil mengubah status promo"
            // }
          } else {
            this.snackbar = {
              show: true,
              color: "red",
              text: "Gagal mengubah status promo",
            };
          }
        })
        .catch((error) => {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Terjadi kendala pada server.",
          };
        });
    },
    async deletePromo() {
      await post(`internal/dashboard/promo/delete`, {
        data: {
          id: this.details.id,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.dialog.confirmation = false;
            this.dialog.success = true;
            // this.snackbar = {
            //   show: true,
            //   color: "success",
            //   text: "Berhasil menghapus status promo"
            // }
            this.fetch();
          } else {
            this.snackbar = {
              show: true,
              color: "red",
              text: "Gagal menghapus status promo",
            };
          }
        })
        .catch((error) => {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Terjadi kendala pada server.",
          };
        });
    },

    async save(type) {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-promo"].validate();

      if (isValid) {
        this.process.run = true;

        let url = "";
        let data = {};
        if (type == "add") {
          url = `internal/dashboard/promo`;
          data = this.form;
        } else {
          url = `internal/dashboard/promo/update`;
          data = this.form;
        }

        await post(url, {
          data,
        })
          .then((response) => {
            let res = response.data;
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-promo"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";

              this.fetch();
            } else {
              if (res.error_code && res.error_code == "error_validation") {
                this.feedback = "Cek kembali form Anda!";

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.run = false;
      }
    },
  },
};
</script>