<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR MIGRASI DATABASE
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan nama, email, nomor handphone"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>

        <v-btn
          @click="actions(null, 'add')"
          elevation="0"
          class="text-capitalize white--text"
          color="#D31145">
          <v-icon left>mdi-plus</v-icon>
          Tambah Migrasi Database
        </v-btn>

        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.title`]="{item}">
              <div class="font-weight-bold black--text">{{ item.title }}</div>
            </template>

            <template v-slot:[`item.db_type`]="{item}">
              <div class="font-weight-bold text-uppercase">{{ item.db_type }}</div>
            </template>

            <template v-slot:[`item.action`]="{item}">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      @click="actions(item, 'update')"
                      color="primary"
                      icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Ubah Data Migrasi</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      @click="actions(item, 'execute_migration')"
                      color="teal"
                      icon>
                      <v-icon>mdi-cog-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>Jalankan Migasi Database</span>
                </v-tooltip>
              </div>

              <div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      @click="actions(item, 'detail')"
                      color="orange"
                      icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Detail</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      color="red"
                      @click="actions(item, 'delete')"
                      icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Hapus Data Migrasi
                  </span>
                </v-tooltip>
              </div>


            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetch()" 
            @changePage="page = $event; fetch($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>
      </v-card-text>

    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Migrasi Database Baru" : "Ubah Migrasi Database" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-migration'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan migrasi database" : "Ubah migrasi database" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0">
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-migration">
            <v-form @:keyup.enter="save(dialog.form_type)">

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-name"
                    name="Judul Migrasi"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Judul Migrasi
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan judul migrasi"
                      v-model="form.title"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider
                    ref="form-name"
                    name="SQL Migrasi"
                    rules="required|max:1000"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      SQL Migrasi
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-textarea
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="1000"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan judul migrasi"
                      v-model="form.query"
                      :error-messages="errors"
                      required
                    >
                    </v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.success_type == 'delete' ? 'menghapus data' : dialog.success_type == 'execute_migration' ? 'menjalankan migrasi' : 'mengubah status' }}
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" scrollable persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              v-if="dialog.confirmation_type != 'execute_migration'"
              class="font-weight-bold mb-1"
              style="font-size: 16px !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data migrasi ini ?
            </p>
            <div v-else>
              <p
                class="font-weight-bold mb-3"
                style="font-size: 14px !important;">
                Apakah Anda ingin menjalankan query migrasi ini ?
              </p>
              <p>{{ form.query }}</p>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            :disabled="process.run"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            v-if="dialog.confirmation_type == 'execute_migration'"
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            :loading="process.run"
            @click="save('execute_migration')">
            Jalankan Migrasi
          </v-btn>
          <v-btn
            v-else
            large
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            :disabled="process.run"
            @click="deleteAccount()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DETAIL -->
    <v-dialog
      v-model="dialog.detail"
      persistent
      scrollable
      width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          Detail Migrasi Database
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.detail = false">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          Detail Data Migrasi database development ke database production
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">

          <table class="detail-migration">
            <tr>
              <th class="text-start" width="30%">Judul Migrasi</th>
              <td class="text-start black--text">
                {{ details.title }}
              </td>
            </tr>
            <tr>
              <th class="text-start" width="30%">Query Migrasi</th>
              <td class="text-start black--text">
                {{ details.query }}
              </td>
            </tr>
            <tr>
              <th class="text-start" width="30%">Tipe Database</th>
              <td class="text-start black--text text-uppercase">
                {{ details.db_type }}
              </td>
            </tr>
            <tr>
              <th class="text-start" width="30%">Total Eksekusi</th>
              <td class="text-start black--text">
                {{ details.run_count }}
              </td>
            </tr>
          </table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="actions(details.id, 'execute_migration')"
            color="red"
            outlined
            class="white--text">
            Eksekusi
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
export default {
  components: {
    Empty,
    Pagination,
  },
  data () {
    return {
      searching: "",
      feedback: "",
      process: {
        run: false
      },
      details: {},
      dialog: {
        detail: false,
        form: false,
        form_type: "",
        confirmation: false,
        confirmation_type: "",
        success: false,
        success_type: "",
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      show_pass: false,
      show_confirm_pass: false,
      form: {
        id: "",
        title: "",
        query: ""
      },
      // DATA TABLES
      items: [],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        },
        {
          text: 'Judul',
          value: 'title',
          align: 'start',
          width: 200
        },
        {
          text: 'Query',
          value: 'query',
          align: 'start',
          width: 200
        },
        {
          text: 'Tipe Database',
          value: 'db_type',
          align: 'center',
          width: 150
        },
        {
          text: 'Total Eksekusi',
          value: 'run_count',
          align: 'center',
          width: 150
        },
      ],
    }
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetch()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    actions(item, method) {
      switch (method) {
        case "add":
          this.dialog.form = true
          this.dialog.form_type = "add"
          this.form = {
            id: "",
            title: "",
            query: ""
          }
          // this.$refs['validation-new-migration'].reset();
          break;
        case "update":
          this.dialog.form = true
          this.dialog.form_type = "update"
          this.form = {
            id: item.id,
            title: item.title,
            query: item.query,
          }
          break;
        case 'execute_migration':
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "execute_migration"
          this.form.id = item.id
          this.form.query = item.query
          break;
        case 'detail':
          this.dialog.detail = true
          this.details = item
          break;
        case 'delete':
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "delete"
          this.form.id = item.id
          break;
      }
    },
    async fetch(paging) {
      this.process.run = true

      await get('internal/migrate', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })
    },
    async save(type) {
      let _self = this;

      this.feedback = "";
      const isValid = true
      if (type !== 'execute_migration') {
        isValid = await this.$refs["validation-new-migration"].validate();
      }

      if (isValid) {
        this.process.run = true;

        let url = ""
        let data = {}
        if (type == "add") {
          url = `internal/migrate`
          data = {
            title: this.form.title,
            query: this.form.query,
          }
        }else if(type == "update") {
          url = `internal/migrate/update`
          data = {
            id: this.form.id,
            title: this.form.title,
            query: this.form.query,
          }
        }else if(type === 'execute_migration') {
          url = `internal/migrate/run`
          data = {
            id: this.form.id,
          }
        }
        this.process.run = true

        await post(url, {
          data
        })
          .then((response) => {
            console.log(response);
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              if (type !== 'execute_migration') {
                this.$refs["validation-new-migration"].reset();
              }

              Object.assign(this.form, {
                id: "",
                title: "",
                query: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = res.message;
              this.dialog.confirmation = false

              this.fetch();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                this.process.run = false

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
              this.process.run = false
            }
          })
          .catch(function (error) {
            console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
            this.process.run = false;
          });
        this.process.run = false;
      }
    },
    async deleteAccount(item) {
      await post(`internal/migrate/delete`,{
        data: {
          id: this.form.id,
        }
      }).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.dialog.confirmation = false
          this.dialog.confirmation_type = 'delete'
          this.dialog.success = true
          this.dialog.success_type = 'delete'
          this.fetch()
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: ""
          }
          Object.keys(res.results.data).forEach(item => {
            this.snackbar.text += res.results.data[item] + `</br>`;
          })
        }
      }).catch((error) => {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Terjadi kendala pada server."
        }
      })
    },
  }
}
</script>

<style scoped>
.detail-migration {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.detail-migration td, .detail-migration th {
  border: 1px solid #ddd;
  font-weight: 600;
  padding: 8px;
}

/* .detail-migration tr:nth-child(even){background-color: #f2f2f2;} */

/* .detail-migration tr:hover {background-color: #ddd;} */

.detail-migration th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #d31145;
  color: white;
}
</style>