<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-9 pl-9' : 'px-5'">

    <v-card outlined class="box-shadow border-radius pa-2 pb-1 mt-5">
      <v-card-title class="body-1 px-5 text-first pt-0 font-weight-bold">
        DAFTAR PROMOSI SODAPOS
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          color="#D31145"
          placeholder="Cari berdasarkan judul promosi"
          class="font-weight-normal"
          v-model="searching">
        </v-text-field>
      </v-card-title>
      <v-card-text>


        <v-btn
          @click="actions(null, 'add')"
          elevation="0"
          class="text-capitalize white--text mb-3"
          color="#D31145">
          <v-icon left>mdi-plus</v-icon>
          Tambah Template
        </v-btn>

        <!-- <v-tabs
          color="#d31145"
          v-model="tab">
          <v-tab>
            Template promosi
          </v-tab>
          <v-tab>
            List Promosi Terkirim
          </v-tab>
        </v-tabs> -->
        <div v-if="itemsTemplate.length > 0">
          <v-data-table
            :headers="headersTemplate"
            :items="itemsTemplate"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="optionsTemplate"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data tidak tersedia"
            no-results-text="Data tidak tersedia"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            flat
            class="elevation-0 rounded-lg table-with-action">

            <template v-slot:[`item.title`]="{item}">
              <v-list-item two-line class="px-0">
                <v-list-item-avatar tile class="rounded-lg">
                  <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="item.title" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.body" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <template v-slot:[`item.datetime`]="{item}">
              <div>
                {{ item.start_datetime | datetime }}
              </div>
              <div class="font-weight-bold">
                s/d
              </div>
              <div>
                {{ item.end_datetime | datetime }}
              </div>
            </template>

            <template v-slot:[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item, 'send_promotion')"
                    color="orange"
                    icon>
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>Kirim Promosi</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="actions(item, 'update')"
                    color="primary"
                    icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Ubah Template</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                    @click="actions(item, 'delete')"
                    icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>
                  Hapus Paket
                </span>
              </v-tooltip>
            </template>

          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; fetchTemplate()" 
            @changePage="page = $event; fetchTemplate($event)" 
            :model="paginationTemplate"
            v-show="paginationTemplate.data.total_page > 0"/>
        </div>
        <div v-else class="text-center subtitle-2 mt-15">
          <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
        </div>

        <!-- <v-tabs-items v-model="tab">
          <v-tab-item>
          </v-tab-item>
          <v-tab-item>
            <div v-if="items.length > 0">
              <v-data-table
                :headers="headers"
                :items="items"
                disable-sort
                hide-default-footer
                disable-pagination 
                :options.sync="options"
                :loading="process.run"
                loading-text="Sedang memuat"
                no-data-text="Data tidak tersedia"
                no-results-text="Data tidak tersedia"
                sort-by-text="Urutkan berdasarkan"
                :items-per-page="10"
                fixed-header
                flat
                class="elevation-0 rounded-lg">

                <template v-slot:[`item.title`]="{item}">
                  <v-list-item two-line>
                    <v-list-item-avatar tile class="rounded-lg">
                      <v-img :src="item.image_url ? item.image_url : 'https://sodapos.com/media/2022/11/image/shop-icon-1669016747.png'"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-html="item.title" class="subtitle-2"></v-list-item-title>
                      <v-list-item-subtitle v-html="item.body" class="caption"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                
                <template v-slot:[`item.datetime`]="{item}">
                  <div>
                    {{ item.start_datetime | datetime }}
                  </div>
                  <div class="font-weight-bold">
                    s/d
                  </div>
                  <div>
                    {{ item.end_datetime | datetime }}
                  </div>
                </template>

                <template v-slot:[`item.action`]="{item}">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        @click="actions(item, 'send_promotion')"
                        color="orange"
                        icon>
                        <v-icon>mdi-send</v-icon>
                      </v-btn>
                    </template>
                    <span>Kirim Promosi</span>
                  </v-tooltip>


                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        @click="actions(item, 'update')"
                        color="primary"
                        icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Ubah Template</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                        @click="actions(item, 'delete')"
                        icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Hapus Paket
                    </span>
                  </v-tooltip>
                </template>

              </v-data-table>

              <Pagination 
                @changeLimit="limit = $event; fetch()" 
                @changePage="page = $event; fetch($event)" 
                :model="pagination"
                v-show="pagination.data.total_page > 0"/>
            </div>
            <div v-else class="text-center subtitle-2 mt-15">
              <Empty value="Data belum tersedia atau data yang Anda cari tidak tersedia"/>
            </div>
          </v-tab-item>
        </v-tabs-items> -->
      </v-card-text>

    </v-card>

    <!-- DIALOG FORM -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Template Promosi SODAPOS" : "Ubah Template Promosi SODAPOS" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-promotion'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan template promosi sodapos" : "Ubah template promosi sodapos" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-promotion">
            <v-form @:keyup.enter="save(dialog.form_type)">

              <div style="position: relative; width: 300px; height: 300px; overflow: hidden; margin: 0 auto; display: flex;justify-content: center;align-items: center;">
                  <input type="file" id="image" @change="onImageChangeHandler" ref="ref_inputImage" accept="image/jpg, image/png, image/jpeg" style="display: none" />
                  <img :src="form.image_url" alt="image" className="avatar" referrerPolicy="no-referrer" style="width: 300px; height: 300px; border-radius: 10px; object-fit: cover; align-items: center; justify-content: center; cursor: pointer" @click="$refs.ref_inputImage.click()"/>
                  <span className="button" style="position: absolute; top: 10; right: 10; font-size: 25; cursor: pointer; background-color: rgba(0,0,0,0.4); padding: 6px 12px; border-radius: 8px" @click="$refs.ref_inputImage.click()">
                      <v-icon>mdi-camera</v-icon>
                  </span>
              </div>

              <v-row class="mt-3">
                <v-col>
                  <ValidationProvider
                    ref="form-title"
                    name="Judul Promosi"
                    rules="required|max:50|min:4"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Judul Promosi
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan judul promosi"
                      v-model="form.title"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-body"
                    name="Sub Deskripsi Notifikasi Promosi"
                    rules="required|max:50|min:4"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Sub Deskripsi Notifikasi Promosi
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>
                    <v-text-field
                      solo
                      flat
                      background-color="#EEEEEE"
                      counter
                      maxlength="50"
                      dense
                      color="#d31145"
                      placeholder="Tuliskan sub-deskripsi notifikasi promosi"
                      v-model="form.body"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <ValidationProvider
                ref="form-description"
                name="Deskripsi Promosi"
                rules="required|max:500"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Deskripsi Promosi
                  <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                </div>
                <v-textarea
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="500"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan deskripsi promo disini"
                  v-model="form.description"
                  :error-messages="errors"
                  required
                  autocomplete="off"
                >
                </v-textarea>
              </ValidationProvider>

              <!-- TANGGAL MULAI -->
              <v-row>
                <v-col>
                  <ValidationProvider
                  ref="form-start_datetime"
                  name="Tanggal Mulai Paket"
                  rules="required|max:50"
                  v-slot="{ errors }"
                >
                  <div class="subtitle-2 font-weight-bold">
                    Tanggal Mulai Tayang Promosi
                    <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                  </div>

                  <v-menu
                    v-model="start_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="FormatStartDate"
                        placeholder="Pilih tanggal mulai promosi"
                        solo
                        flat
                        background-color="#EEEEEE"
                        dense
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker
                      color="#d31145"
                      v-model="form.start_datetime"
                      @input="start_date = false"
                    ></v-date-picker>
                  </v-menu>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-end_datetime"
                    name="Tanggal Selesai Paket"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Waktu Mulai Tayang Promosi
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>

                    <v-menu
                      ref="start_time"
                      v-model="start_time"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.start_time"
                          readonly
                          placeholder="Pilih waktu mulai promosi"
                          solo
                          flat
                          background-color="#EEEEEE"
                          dense
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        color="#d31145"
                        format="24hr"
                        v-if="start_time"
                        v-model="form.start_time"
                        full-width
                        @click:minute="$refs.start_time.save(time)"
                      ></v-time-picker>
                    </v-menu>
                   </ValidationProvider>
                </v-col>
              </v-row>

              <!-- TANGGAL SELESAI -->
              <v-row>
                <v-col>
                  <ValidationProvider
                  ref="form-start_datetime"
                  name="Tanggal Mulai Paket"
                  rules="required|max:50"
                  v-slot="{ errors }"
                >
                  <div class="subtitle-2 font-weight-bold">
                    Tanggal Selesai Tayang Promosi
                    <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                  </div>

                  <v-menu
                    v-model="end_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="FormatEndDate"
                        placeholder="Pilih tanggal selesai promosi"
                        solo
                        flat
                        background-color="#EEEEEE"
                        dense
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker
                      color="#d31145"
                      v-model="form.end_datetime"
                      @input="end_date = false"
                    ></v-date-picker>
                  </v-menu>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    ref="form-end_datetime"
                    name="Tanggal Selesai Paket"
                    rules="required|max:50"
                    v-slot="{ errors }"
                  >
                    <div class="subtitle-2 font-weight-bold">
                      Waktu Selesai Tayang Promosi
                      <small style="color: #e74c3c"><i>*wajib diisi</i></small>
                    </div>

                    <v-menu
                      ref="end_time"
                      v-model="end_time"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.end_time"
                          readonly
                          placeholder="Pilih waktu selesai promosi"
                          solo
                          flat
                          background-color="#EEEEEE"
                          dense
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        color="#d31145"
                        format="24hr"
                        v-if="end_time"
                        v-model="form.end_time"
                        full-width
                        @click:minute="$refs.end_time.save(time)"
                      ></v-time-picker>
                    </v-menu>
                   </ValidationProvider>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" class="py-0">
                  <v-checkbox
                    color="#d31145"
                    hide-details
                    v-model="form.is_all_merchant_bool"
                    label="Kirimkan kesemua Merchant SODAPOS">
                  </v-checkbox>
                </v-col>
                <v-col cols="12" class="">
                  <v-autocomplete
                    v-model="form.merchant"
                    :items="merchants"
                    :disabled="form.is_all_merchant_bool"
                    solo
                    flat
                    background-color="#EEEEEE"
                    chips
                    dense
                    color="#d31145"
                    label="Cari berdasarkan nama"
                    item-text="owner_name"
                    item-value="id"
                    multiple
                    return-object
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)">
                        {{ data.item.owner_name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="Object.keys(data.item).length < 1">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.owner_name"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.owner_email"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="
            form.is_send_bool = true;
            save('add')"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data dan Kirim Promosi
          </v-btn>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="
            form.is_send_bool = false;
            save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Tambahkan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.send_promotion"
      persistent
      scrollable
      width="800">
      <v-card>
        <v-card-title class="subtitle-1">
          Kirim Promosi ke Merchant
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.send_promotion = false;
              $refs['validation-new-send-promotion'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="caption">
          Kirimkan promosi ke Merchant SODAPOS
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>
        <v-card-text class="pa-4">

          <div class="d-flex">
            <img :src="details.image_url" :alt="details.title" width="150" class="mx-auto rounded-lg">
          </div>

          <v-simple-table class="px-3">
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0" width="30%">Judul Promosi</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ details.title }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Sub Deskripsi Notifikasi Promosi</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ details.body }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Deskripsi Promosi</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ details.description }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Tanggal Promosi</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ details.start_datetime | datetime }} - {{ details.end_datetime | datetime }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>


          <ValidationObserver ref="validation-new-send-promotion">
            <v-form @:keyup.enter="sendPromotion()">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-checkbox
                    color="#d31145"
                    hide-details
                    v-model="form.is_all_merchant_bool"
                    label="Kirimkan kesemua Merchant SODAPOS">
                  </v-checkbox>
                </v-col>
                <v-col cols="12" class="">
                  <v-autocomplete
                    v-model="form.merchant"
                    :items="merchants"
                    :disabled="form.is_all_merchant_bool"
                    solo
                    flat
                    background-color="#EEEEEE"
                    chips
                    dense
                    color="#d31145"
                    label="Cari berdasarkan nama"
                    item-text="owner_name"
                    item-value="id"
                    multiple
                    return-object
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)">
                        {{ data.item.owner_name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="Object.keys(data.item).length < 1">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.owner_name"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.owner_email"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="
            form.is_send_bool = true;
            sendPromotion()"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Kirimkan Notifikasi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data promosi
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG CONFIRMATION ==== -->
    <v-dialog v-model="dialog.confirmation" persistent width="500">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5" style="height: 100px">
          <div class="text-center">
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Apakah Anda ingin {{ dialog.confirmation_type == 'delete' ? 'menghapus' : 'mengubah status' }} data promosi ini ?
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            width="150"
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.confirmation = false; dialog.confirmation_type = ''; form.id = ''">
            Batal
          </v-btn>
          <v-btn
            width="150"
            color="#d31145"
            class="text-capitalize white--text"
            @click="deletePromo()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import moment from 'moment'
export default {
  components: {
    Empty,
    Pagination,
  },
  data () {
    return {
      tab: null,
      start_date: false,
      end_date: false,
      start_time: false,
      end_time: false,
      time: null,
      searching: "",
      feedback: "",
      details: {},
      process: {
        run: false
      },
      dialog: {
        send_promotion: false,
        confirmation: false,
        confirmation_type: "",
        success: false,
        form: false,
        form_type: "",
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      form: {
        id: "",
        title: "",
        body: "",
        description: "",
        start_datetime: "",
        end_datetime: "",
        merchant_array: [],
        is_all_merchant_bool: false,
        is_send_bool: false,
        image_url: "https://sodapos.com/media/2022/12/image/icon-def-no-image-1670315486.png",
      },
      // DATA TABLES
      merchants: [],
      items: [],
      options:{},
      limit: 10,
      page: 1,
      itemsTemplate: [],
      optionsTemplate:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      headers: [
        {
          text: 'Judul Promosi',
          value: 'title',
          align: 'start',
          width: 200
        },
        {
          text: 'Deskripsi Promosi',
          value: 'description',
          align: 'start',
          width: 400
        },
        {
          text: 'Waktu Promosi',
          value: 'datetime',
          align: 'center',
          width: 250
        }
      ],

      limitTemplate: 10,
      pageTemplate: 1,
      itemsTemplate: [],
      optionsTemplate:{},
      paginationTemplate: {
        data: {},
        page: ""
      },
      selectedTemplate: {
        menu: 0,
        pagination: 0
      },
      headersTemplate: [
        {
          text: 'Aksi',
          value: 'action',
          align: 'center',
          width: 150
        },
        {
          text: 'Judul Promosi',
          value: 'title',
          align: 'start',
          width: 200
        },
        {
          text: 'Deskripsi Promosi',
          value: 'description',
          align: 'start',
          width: 400
        },
        {
          text: 'Waktu Promosi',
          value: 'datetime',
          align: 'center',
          width: 250
        },
      ],
    }
  },
  computed: {
    FormatStartDate() {
      return this.form.start_datetime ? moment(this.form.start_datetime).format("DD MMMM YYYY") : ''
    },
    FormatEndDate() {
      return this.form.end_datetime ? moment(this.form.end_datetime).format("DD MMMM YYYY") : ''
    },
  },
  watch: {
    searching: function(val) {
      this.searching = val
      this.fetchTemplate()
    },
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    options:{
      handler(){
        this.fetch()
      },
      deep:true
    },
    'selectedTemplate.pagination': function(val) {
      this.fetchTemplate(val+1)
    },
    optionsTemplate:{
      handler(){
        this.fetchTemplate()
      },
      deep:true
    },
  },
  mounted() {
    // this.fetch()
    this.fetchTemplate()
    this.fetchMerchant()
  },
  methods: {
    actions(item, method) {
      switch (method) {
        case "add":
          this.dialog.form = true
          this.dialog.form_type = "add"
          this.form = {
            id: "",
            title: "",
            body: "",
            description: "",
            start_datetime: "",
            end_datetime: "",
            merchant: [],
            merchant_array: [],
            is_all_merchant_bool: false,
            is_send_bool: false,
            image_url: "https://sodapos.com/media/2022/12/image/banner-promo-sodapos-1670843370.png",
          }
          // this.$refs['validation-new-promotion'].reset();
          break;
        case "update":
          this.dialog.form = true
          this.dialog.form_type = "update"
          this.form = {
            id: item.id,
            title: item.title,
            body: item.body,
            description: item.description,
            start_datetime: moment(item.start_datetime).format("YYYY-MM-DD"),
            end_datetime: moment(item.end_datetime).format("YYYY-MM-DD"),
            start_time: moment(item.start_datetime).format("HH:mm"),
            end_time: moment(item.end_datetime).format("HH:mm"),
            merchant_array: [],
            merchant: [],
            is_all_merchant_bool: item.is_all_merchant_bool,
            is_send_bool: item.is_send_bool,
            image_url: item.image_url,
          }
          break;
        case 'delete':
          this.details = item
          this.dialog.confirmation = true
          this.dialog.confirmation_type = "delete"
          break;
        case 'send_promotion':
          this.form = {
            id: item.id,
            title: item.title,
            body: item.body,
            description: item.description,
            start_datetime: moment(item.start_datetime).format("YYYY-MM-DD"),
            end_datetime: moment(item.end_datetime).format("YYYY-MM-DD"),
            start_time: moment(item.start_datetime).format("HH:mm"),
            end_time: moment(item.end_datetime).format("HH:mm"),
            merchant_array: [],
            merchant: [],
            is_all_merchant_bool: item.is_all_merchant_bool,
            is_send_bool: item.is_send_bool,
            image_url: item.image_url,
          }
          this.details = item
          this.dialog.send_promotion = true
          break;
      }
    },
    remove (item) {
      const index = this.merchants.indexOf(item.owner_name)
      if (index >= 0) this.merchants.splice(index, 1)
    },
    onImageChangeHandler(e) {
      var files = e.target.files || e.dataTransfer.files;

      let formData = new FormData()
      formData.append("key", "59e51e5db668184d8c4a085fb8389c3acfd76a50")
      formData.append("image", files[0])
      formData.append("name", `${this.form.title ? this.form.title : `Gambar-Promosi-${moment().format('YYYYmmdd')}`}`)
      
      fetch(`https://sodapos.com/media/image.php`, {
        method: "POST",
        body: formData
      }).then(res => res.json())
      .then(respon => {
      if (respon.status === 200) {
        this.form.image_url = respon.data.fileuri
      }
    })
    },
    async deletePromo() {
      await post(`internal/dashboard/notif/template/delete`,{
        data: {
          id: this.details.id,
        }
      }).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.dialog.confirmation = false
          this.dialog.success = true
          // this.snackbar = {
          //   show: true,
          //   color: "success",
          //   text: "Berhasil menghapus status promo"
          // }

          this.fetchTemplate()
        }else {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Gagal menghapus status promo"
          }
        }
      }).catch((error) => {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Terjadi kendala pada server."
        }
      })
    },
    async fetchMerchant() {
      this.process.run = true

      await get('internal/dashboard', {
        params: {
          pagination_bool: false,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.merchants = res.results.data
          this.process.run = false
        }
      })
    },
    async fetch(paging) {
      this.process.run = true

      await get('internal/dashboard/notif/promo', {
        params: {
          page: paging,
          limit: this.limit,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }else {
          this.process.run = false;
        }
      })

      this.process.run = false
    },
    async fetchTemplate(paging) {
      this.process.run = true

      await get('internal/dashboard/notif/template', {
        params: {
          page: paging,
          limit: this.limitTemplate,
          search: this.searching,
        }
      })
      .then((response) => {
        let res = response.data
        if(res.status == 200) {
          this.itemsTemplate = res.results.data
          this.totalData = res.results.pagination.total_data
          this.paginationTemplate.data = res.results.pagination
          this.paginationTemplate.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }else {
          this.process.run = false;
        }
      })

      this.process.run = false
    },
    async save(type) {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-promotion"].validate();

      if (isValid) {
        this.process.run = true;

        let url = ""
        let data = {}
        if (this.form.merchant.length > 0) {
          this.form.merchant.forEach(item => {
            this.form.merchant_array.push({
              merchant_id: item.id
            })
          })
        }

        this.form.start_datetime = this.form.start_datetime + " " + this.form.start_time
        this.form.end_datetime = this.form.end_datetime + " " + this.form.end_time

        if (type == "add") {
          url = `internal/dashboard/notif`
          data = this.form
        }else {
          url = `internal/dashboard/notif/`
          data = this.form
        }

        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-promotion"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";

              this.fetchTemplate();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.run = false;
      }
    },
    async sendPromotion() {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-send-promotion"].validate();

      if (isValid) {
        this.process.run = true;

        let data = {}
        if (this.form.merchant.length > 0) {
          this.form.merchant.forEach(item => {
            this.form.merchant_array.push({
              merchant_id: item.id
            })
          })
        }

        this.form.start_datetime = this.form.start_datetime + " " + this.form.start_time
        this.form.end_datetime = this.form.end_datetime + " " + this.form.end_time

        let url = `internal/dashboard/notif`
        data = this.form

        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-send-promotion"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";

              this.fetchTemplate();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.run = false;

      }
    }
  },
}
</script>